.form-item-menu-pane {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: var(--color-extra-dark);
    border-right: 1.5px solid var(--color-dark-brand);
    position: relative;
}

.form-item-menu-header {
    display: flex;
    flex-shrink: 0;
}

.form-item-menu-tab {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-extra-dark);
    cursor: pointer;
    padding: 16px 0;
    transition: background-color 0.2s ease;
}

.form-item-menu-tab svg {
    width: 18px;
    height: 18px;
    color: var(--color-grey);
    transition: color 0.2s ease;
}

.form-item-menu-tab:hover svg {
    color: var(--color-white);
}

.form-item-menu-tab.active {
    background-color: var(--color-dark);
}

.form-item-menu-tab.active svg {
    color: var(--color-white);
}

.form-item-menu-resize-handle {
    position: absolute;
    top: 0;
    right: -3px;
    width: 6px;
    height: 100%;
    cursor: col-resize;
    background: transparent;
    transition: background-color 0.2s ease;
}

.form-item-menu-resize-handle:hover,
.form-item-menu-resize-handle.active {
    background-color: var(--color-brand);
} 