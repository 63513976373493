html {
  font-size: 16px;
}

body {
  font-family: "Open Sans", serif;
  margin: 0;
  padding: 0;
  background-color: #191919;
  color: #D6D6D6;
  --color-brand: #309C83;
  --color-brand-opacity: #309c8329;
  --color-links: #3F51A2;
  /* --color-white: #D6D6D6; */
  --color-white: #dbdbdb;
  --color-white-pure: #f7f7f7;
  --color-dark: #191919;
  --color-grey: #7d7d7d;
  --color-light-grey: #bfbfbf;
  --color-extra-dark: rgb(16, 16, 16);
  --color-dark-brand: #2b3030;
  --color-dark-brand-opacity: #2b3030be;
  --color-brand-complement: #80475e;
  --sidebar-container-width: 300px;
  --sidebar-width: 280px;
  --color-red: #9C3030;
  --header-height: 80px;
}

input,
button,
textarea {
  font-family: "Open Sans", serif;
}

.authenticated-layout {
  display: flex;
  min-height: 100vh;
}

.authenticated-layout-content {
  flex: 1;
  padding: 20px;
  background-color: var(--color-extra-dark);
}

.unauthenticated-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.unauthenticated-layout-content {
  flex: 1;
}