.availability-selector {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 200px);
  background-color: var(--color-dark);
  position: relative;
}

/* Left Panel Styles */
.availability-selector-left {
  background-color: var(--color-extra-dark);
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: calc(100vh - 240px);
  overflow: hidden;
  position: relative;
}

.availability-date-picker {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
}

.availability-date-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: var(--color-white);
  cursor: pointer;
  padding: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  background-color: var(--color-extra-dark);
  border-radius: 48px;
}

.availability-date-nav.prev {
  left: 0px;
}

.availability-date-nav.next {
  right: 0px;
}

.availability-date-display {
  flex: 1;
  text-align: center;
  font-weight: 600;
}

.availability-reset {
  background: none;
  border: 1px solid var(--color-grey);
  color: var(--color-white);
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.availability-slots-list {
  padding: 12px;
  border-bottom: 1px solid var(--color-grey);
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  background-color: var(--color-dark);
}

.availability-slot-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  background-color: var(--color-extra-dark);
  border-radius: 4px;
  font-size: 13px;
}

.availability-slot-info {
  display: flex;
  align-items: center;
  gap: 8px;
}

.availability-slot-date {
  color: var(--color-grey);
}

.availability-slot-time {
  font-weight: 500;
}

.availability-slot-remove {
  background: none;
  border: none;
  color: var(--color-grey);
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
}

.availability-slot-remove:hover {
  color: #ff4d4f;
}

/* Calendar View Styles */
.availability-calendar {
  background-color: var(--color-extra-dark);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  height: calc(100% - 80px);
}

.availability-calendar-header {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  background-color: var(--color-dark);
  border-bottom: 1px solid var(--color-grey);
  position: relative;
}

.availability-calendar-day {
  padding: 16px;
  text-align: center;
  border-right: none;
}

.availability-calendar-day:last-child {
  border-right: none;
}

.availability-day-name {
  font-weight: 600;
  margin-bottom: 4px;
}

.availability-day-date {
  font-size: 14px;
  color: var(--color-grey);
}

.availability-calendar-grid {
  flex: 1;
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  position: relative;
  border-left: 1px solid var(--color-grey);
}

.availability-time-column {
  min-height: 100%;
  border-right: 1px solid var(--color-grey);
}

.availability-time-column:last-child {
  border-right: 1px solid var(--color-grey);
}

.availability-time-slot {
  height: 32px;
  padding: 4px 8px;
  border-bottom: 1px solid var(--color-grey);
  border-right: 1px solid var(--color-grey);
  cursor: pointer;
  transition: background-color 0.2s ease;
  font-size: 13px;
  display: flex;
  align-items: center;
  user-select: none;
}

.availability-time-slot.selected {
  background-color: var(--color-brand);
  color: var(--color-white);
}

@media (max-width: 768px) {
  .availability-calendar-header {
    grid-template-columns: 1fr; /* Single column takes full width */
  }

  .availability-calendar-grid {
    grid-template-columns: 1fr; /* Single column takes full width */
  }

  .availability-calendar-day {
    width: 100%; /* Ensure day header takes full width */
  }

  .availability-time-column {
    width: 100%; /* Ensure time column takes full width */
  }

  /* Adjust navigation buttons for single column */
  .availability-date-nav.prev {
    left: 8px;
  }

  .availability-date-nav.next {
    right: 8px;
  }
}

@media (max-width: 480px) {

}

/* New styles for the buttons container */
.availability-buttons {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background: linear-gradient(
    to bottom,
    transparent,
    var(--color-extra-dark) 20%
  );
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 40px;
}

.availability-buttons button {
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  transition: opacity 0.2s ease;
}

.availability-buttons .meeting-finder-button-primary {
  background-color: var(--color-brand);
  color: var(--color-white);
  border: none;
}

.availability-buttons .meeting-finder-button-secondary {
  background-color: transparent;
  color: var(--color-white);
  border: 1px solid var(--color-grey);
}

.availability-buttons button:hover {
  opacity: 0.9;
}

.availability-buttons button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Update form buttons to match BasicInfoForm */
.meeting-finder-form-buttons {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: var(--color-extra-dark);
  border-top: 1px solid var(--color-grey);
  margin-top: auto;
}

@media (min-width: 769px) {
  .availability-calendar.dragging {
    cursor: pointer;
  }
}