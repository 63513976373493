.import-details {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.import-details-outer {
    position: relative;
    height: 100%;
    overflow-y: auto;
    --color-import-details-border: rgba(255, 255, 255, 0.1);
}


/* Webkit browsers (Chrome, Safari, newer versions of Edge) */
.import-details-outer::-webkit-scrollbar {
    width: 8px;
  }
  
  .import-details-outer::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .import-details-outer::-webkit-scrollbar-thumb {
    background-color: var(--color-grey);
    border-radius: 4px;
  }
  
  .import-details-outer::-webkit-scrollbar-thumb:hover {
    background-color: var(--color-light-grey);
  }
  
  /* Firefox */
  .import-details-outer {
    scrollbar-width: thin;
    scrollbar-color: var(--color-grey) transparent;
  }

.import-details-outer-backdrop {
    position: fixed;
    background-color: var(--color-extra-dark);
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: -1;
}

.details-header {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;
    padding: 1.5rem;
    background-color: var(--color-dark);
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.back-button {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: transparent;
    border: 1px solid var(--color-grey);
    border-radius: 4px;
    color: var(--color-white);
    cursor: pointer;
    transition: all 0.2s ease;
}

.back-button:hover {
    background-color: var(--color-grey);
}

.details-title {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.details-title h1 {
    margin: 0;
    font-size: 1.5rem;
    color: var(--color-white);
}

.status-badge {
    padding: 0.25rem 0.75rem;
    border-radius: 12px;
    font-size: 0.875rem;
    font-weight: 500;
}

.status-pending { background-color: var(--color-brand-opacity); color: var(--color-brand); }
.status-analyzing { background-color: var(--color-brand-opacity); color: var(--color-brand); }
.status-mapping { background-color: var(--color-brand-opacity); color: var(--color-brand); }
.status-processing { background-color: var(--color-brand-opacity); color: var(--color-brand); }
.status-completed { background-color: var(--color-brand-opacity); color: var(--color-brand); }
.status-failed { background-color: var(--color-red); color: var(--color-white); }

.details-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.5rem;
    margin-top: 1.5rem;
}

.details-card {
    background-color: var(--color-dark);
    border-radius: 4px;
    padding: 1.5rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.details-card h2 {
    margin: 0 0 1rem 0;
    font-size: 1.25rem;
    color: var(--color-white);
}

.info-grid {
    display: grid;
    gap: 1rem;
}

.info-item {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.info-item label {
    font-size: 0.875rem;
    color: var(--color-grey);
}

.info-item span {
    font-size: 1rem;
    color: var(--color-white);
}

.error-card {
    border-color: var(--color-red);
    background-color: rgba(156, 48, 48, 0.1);
}

.error-card h2 {
    color: var(--color-red);
}

.findings-list,
.recommendations-list {
    margin: 0;
    padding-left: 1.25rem;
    color: var(--color-white);
}

.findings-list li,
.recommendations-list li {
    margin-bottom: 0.5rem;
}

.insights-grid {
    display: grid;
    gap: 1rem;
}

.insight-item {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.loading,
.error-message {
    text-align: center;
    padding: 2rem;
    background-color: var(--color-dark);
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: var(--color-white);
    margin-bottom: 1rem;
}

.import-details-loading-state {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-top: 80px;
}

.import-details-summary-container {
    width: 100%;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 40px;
    flex-wrap: wrap;
    margin-top: 20px;
}

.import-details-summary-container-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.import-details-summary-container-inner:first-of-type {
    flex-grow: 1;
}

.import-details-summary-item {
    background-color: var(--color-dark);
    border: 1px solid var(--color-import-details-border);
    width: 100%;
    max-width: 700px;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 20px;
    margin-bottom: 40px;
}

.import-details-summary-file-symbol {
    display: flex;
    gap: 2rem;
    margin-bottom: 8px;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
}

.import-details-summary-log {
    padding: 20px;
    max-width: 700px;
    width: 100%;
    min-width: 400px;
    background-color: var(--color-dark);
    border: 1px solid var(--color-import-details-border);
    font-size: 14px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-sizing: border-box;
    border-radius: 8px;
}

.import-details-summary-log-item {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    gap: 20px;
    padding-bottom: 4px;
    border-bottom: 1px dashed rgb(67, 67, 67);
}

.import-details-summary-log-item div:last-of-type {
    font-weight: 600;
}

.import-details-summary-file-symbol-filename {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
}

.import-details-summary-file-symbol-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    background-color: var(--color-brand-opacity);
}

.import-details-summary-section-title-row {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.import-details-summary-section-title {
    font-size: 14px;
    color: var(--color-grey);
    font-weight: 600;
}

.import-details-summary-executive-classification-badge {
    font-size: 12px;
    padding: 4px 12px;
    border-radius: 20px;
    background-color: var(--color-brand-opacity);
}

.import-details-summary-file-symbol svg {
    color: var(--color-brand);
    font-size: 20px;;
}

.import-details-summary-executive-text {
    font-size: 14px;
    color: var(--color-white);
    line-height: 1.8;
}

.import-details-summary-executive {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background-color: var(--color-dark);
    border-radius: 8px;
    padding: 20px;
    max-width: 700px;
    box-sizing: border-box;
    margin-bottom: 40px;
}

.import-details-summary-actions {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    gap: 12px;
    margin-top: 20px;
}

.import-details-summary-actions span {
    font-size: 12px;
    padding: 4px 12px;
    border: 1px solid var(--color-grey);
    border-radius: 4px;
    cursor: pointer;
}

/* HTML: <div class="loader"></div> */
.import-details-loader {
    width: 50px;
    aspect-ratio: 1.154;
    display: grid;
    color: var(--color-brand);
    background:
      linear-gradient(to bottom left ,#0000 calc(50% - 1px),currentColor 0 calc(50% + 1px),#0000 0) right/50% 100%,
      linear-gradient(to bottom right,#0000 calc(50% - 1px),currentColor 0 calc(50% + 1px),#0000 0) left /50% 100%,
      linear-gradient(currentColor 0 0) bottom/100% 2px;
    background-repeat: no-repeat;
    transform-origin: 50% 66%;
    animation: l5 4s infinite linear;
  }
.import-details-loader::before,
.import-details-loader::after {
    content: "";
    grid-area: 1/1;
    background: inherit;
    transform-origin: inherit;
    animation: inherit;
}
.import-details-loader::after {
    animation-duration: 2s;
}
@keyframes l5{
    100% {transform:rotate(1turn)}
}