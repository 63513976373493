.weekly-heatmap-container {
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
}

.weekly-heatmap-grid {
    display: flex;
    gap: 1rem;
}

.weekly-heatmap-time-labels {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 2rem; /* Space for day labels */
}

.weekly-heatmap-time-label {
    height: 2rem;
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.75rem;
    white-space: nowrap;
}

.weekly-heatmap-main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.weekly-heatmap-day-labels {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.5rem;
}

.weekly-heatmap-day-label {
    width: calc(100% / 7);
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.75rem;
}

.weekly-heatmap-cells {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.weekly-heatmap-row {
    display: flex;
    gap: 4px;
    height: 2rem;
}

.weekly-heatmap-cell {
    flex: 1;
    border-radius: 4px;
    transition: all 0.2s ease;
    background-color: rgba(255, 255, 255, 0.1);
}

.weekly-heatmap-cell:hover {
    transform: scale(1.05);
    z-index: 1;
}
