.question-canvas-container {
    position: relative;
    flex-grow: 1;
    overflow-y: auto;
}

.question-canvas-layout {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    height: 100%;
}

.question-canvas-container-preview-buttons {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 8px 14px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 14px;
    color: var(--color-grey);
}

.question-canvas-container-preview-buttons:hover {
    text-decoration: underline;
}

.question-canvas-container-preview-buttons svg {
    font-size: 16px;
}

.question-canvas-scroll-container {
    height: 100%;
    overflow-y: auto;
    background-color: rgb(19, 19, 19);
    /* Firefox scrollbar */
    scrollbar-width: thin;
    scrollbar-color: var(--color-grey) transparent;
}

/* Webkit scrollbar styles */
.question-canvas-scroll-container::-webkit-scrollbar {
    width: 8px;
}

.question-canvas-scroll-container::-webkit-scrollbar-track {
    background: transparent;
}

.question-canvas-scroll-container::-webkit-scrollbar-thumb {
    background-color: var(--color-grey);
    border-radius: 4px;
    border: 2px solid transparent;
    background-clip: content-box;
}

.question-canvas-scroll-container::-webkit-scrollbar-thumb:hover {
    background-color: var(--color-brand);
    border: 2px solid transparent;
    background-clip: content-box;
}

.question-canvas-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    min-height: 100%;
    box-sizing: border-box;
}

.question-canvas-question-item,
.question-canvas-add-question {
    display: flex;
    align-items: center;
    width: 450px;
    border-radius: 8px;
    background-color: var(--color-white);
    color: var(--color-dark);
    cursor: grab;
    overflow: hidden;
    margin-bottom: 10px;
}

.question-canvas-add-text {
    margin-left: 12px;
}

.question-canvas-question-item {
    position: relative;
    width: 94%;
    max-width: 800px;
    background-color: var(--color-dark);
    border: 1px solid var(--color-grey);
    border-radius: 4px;
    color: var(--color-white);
    cursor: default !important;
    margin-bottom: 0px;
    overflow: visible;
    transition: background-color 0.2s ease, box-shadow 0.2s ease;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.question-canvas-question-item.is-dragging {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.question-canvas-question-content {
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.question-canvas-question-type {
    color: var(--color-grey);
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.5px;
    margin-bottom: 1rem;
}

.question-canvas-question-text {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.4;
    text-align: center;
    word-wrap: break-word;
}

.question-canvas-question-description {
    font-size: 12px;
    color: var(--color-grey);
    line-height: 1.4;
    word-wrap: break-word;
    white-space: normal;
    overflow: visible;
    text-align: center;
    margin-top: 0.5rem;
}

.question-canvas-delete-icon {
    position: absolute;
    top: -8px;
    right: -18px;
    width: 24px;
    height: 24px;
    background-color: var(--color-grey);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: var(--color-white);
    font-size: 14px;
    transition: all 0.2s ease;
    opacity: 0;
}

.question-canvas-question-item:hover .question-canvas-delete-icon {
    opacity: 1;
}

.question-canvas-delete-icon:hover {
    background-color: var(--color-red);
}

.question-canvas-question-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid var(--color-grey);
}

.question-canvas-question-icon {
    color: var(--color-brand);
    font-size: 24px;
    display: flex;
    align-items: center;
    background-color: #80475e;
    width: 28px;
    height: 28px;
    min-width: 28px;
    border-radius: 6px;
    justify-content: center;
}

.question-canvas-question-icon svg {
    color: var(--color-white);
}

.question-canvas-question-actions {
    display: flex;
    gap: 8px;
}

.question-canvas-action-button {
    background: none;
    border: 1px solid var(--color-grey);
    color: var(--color-white);
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
    transition: all 0.2s ease;
}

.question-canvas-action-button:hover {
    background-color: var(--color-brand);
    border-color: var(--color-brand);
}

.question-canvas-question-icon-container,
.question-canvas-add-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    background-color: var(--color-brand);
    flex-shrink: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    cursor: inherit;
}

.question-canvas-question-icon-container:active {
    cursor: grabbing;
}

.question-canvas-question-icon,
.question-canvas-add-icon {
    font-size: 14px;
    color: var(--color-white);
}

.question-canvas-question-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    flex-grow: 1;
    overflow: hidden;
    cursor: default;
}

.question-canvas-question-number {
    font-size: 12px;
    color: var(--color-grey);
    margin-bottom: 4px;
    cursor: default !important;
}

.question-canvas-end {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px; /* Match the width of question items */
    height: 60px;
    border-radius: 30px;
    background-color: var(--color-dark);
    color: var(--color-white);
    border: 1px solid var(--color-grey);
    font-weight: bold;
    font-size: 14px;
}

.question-canvas-connector {
    width: 1px;
    height: 50px; /* Increased from 40px to 80px */
    background-color: var(--color-white);
    margin: 0; /* Removed margin */
    position: relative;
}

.question-canvas-add-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 28px;
    height: 28px;
    background-color: rgba(128, 128, 128, 0.572);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: var(--color-white);
    font-size: 12px;
    transition: background-color 0.2s ease;
    opacity: 0; /* Hide by default */
    pointer-events: none; /* Ignore mouse events by default */
}

.question-canvas-connector:hover .question-canvas-add-button {
    opacity: 1; /* Show on hover */
    pointer-events: auto; /* Allow mouse events on hover */
}

.question-canvas-connector::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px; /* Increased sensitivity area */
    height: 100%;
    background-color: transparent;
}

.question-canvas-floating-menu {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    background-color: var(--color-dark);
    padding: 24px 15px;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
}

.question-canvas-floating-menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: transform 0.2s ease, background-color 0.2s ease;
    padding: 10px;
    border-radius: 8px;
}

.question-canvas-floating-menu-item:hover {
    transform: scale(1.05);
    background-color: rgba(255, 255, 255, 0.1);
}

.question-canvas-floating-menu-icon-container {
    width: 40px;
    height: 40px;
    background-color: var(--color-brand);
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
}

.question-canvas-floating-menu-icon {
    font-size: 20px;
    color: white;
}

.question-canvas-floating-menu-label {
    font-size: 12px;
    color: white;
    text-align: center;
    max-width: 70px;
    word-wrap: break-word;
}

.question-canvas-zoom-controls {
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    background-color: var(--color-white);
    border-radius: 20px;
    padding: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.question-canvas-zoom-button {
    background-color: var(--color-brand);
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.2s ease;
    margin: 0 2px;
}

.question-canvas-zoom-button:hover:not(:disabled) {
    background-color: var(--color-brand-dark);
}

.question-canvas-zoom-button:disabled {
    background-color: var(--color-background-dark);
    cursor: not-allowed;
}

.question-canvas-zoom-level {
    margin: 0 10px;
    font-weight: bold;
    color: var(--color-dark);
}

.no-select {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.question-canvas-duplicate-icon {
    position: absolute;
    bottom: -12px;
    right: -12px;
    width: 24px;
    height: 24px;
    background-color: var(--color-grey);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: var(--color-white);
    font-size: 14px;
    transition: background-color 0.2s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 10;
    opacity: 0;
    transition: opacity 0.2s ease, background-color 0.2s ease;
}

.question-canvas-question-item:hover .question-canvas-duplicate-icon {
    opacity: 1;
}

.question-canvas-duplicate-icon:hover {
    background-color: var(--color-brand);
}

@keyframes highlightNew {
    0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 var(--color-brand);
        background-color: var(--color-white);
    }
    20% {
        transform: scale(1.02);
        box-shadow: 0 0 0 3px var(--color-brand);
        background-color: var(--color-brand-opacity);
    }
    40% {
        transform: scale(1);
        box-shadow: 0 0 0 0 var(--color-brand);
        background-color: var(--color-white);
    }
    60% {
        transform: scale(1.01);
        box-shadow: 0 0 0 2px var(--color-brand);
        background-color: var(--color-brand-opacity);
    }
    80% {
        transform: scale(1);
        box-shadow: 0 0 0 0 var(--color-brand);
        background-color: var(--color-white);
    }
    100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 var(--color-brand);
        background-color: var(--color-white);
    }
}

.question-canvas-question-item.highlight-new {
    animation: highlightNew 6s ease-out;
    z-index: 1;
}

/* Section heading specific styles */
.question-canvas-question-item.section-heading {
    background-color: var(--color-extra-light);
    border-left: 3px solid var(--color-brand);
}

.question-canvas-question-item.section-heading:hover {
    background-color: var(--color-light);
}

.question-canvas-question-item.section-heading .question-canvas-question-icon {
    color: var(--color-brand);
}

.question-canvas-question-item.section-heading .question-canvas-question-number {
    color: var(--color-brand);
    font-weight: 500;
}

/* Action icons */
.question-canvas-delete-icon,
.question-canvas-duplicate-icon {
    opacity: 0;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    transition: all 0.2s ease;
    margin-right: 8px;
    cursor: pointer;
}

.question-canvas-question-item:hover .question-canvas-delete-icon,
.question-canvas-question-item:hover .question-canvas-duplicate-icon {
    opacity: 1;
}

.question-canvas-delete-icon svg,
.question-canvas-duplicate-icon svg {
    font-size: 14px;
    color: var(--color-text-secondary);
}

/* Animation for new items */
@keyframes highlightNew {
    0% {
        background-color: var(--color-brand-light);
    }
    100% {
        background-color: white;
    }
}

.question-canvas-question-item.highlight-new {
    animation: highlightNew 0.3s ease;
}

.question-canvas-question-item.section-heading .question-canvas-question-description {
    border-left: 2px solid var(--color-brand);
    padding-left: 8px;
    margin-left: 2px;
}

.question-canvas-drag-handle {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: grab;
    color: var(--color-grey);
    font-size: 20px;
    opacity: 0;
    transition: opacity 0.2s ease;
}

.question-canvas-question-item:hover .question-canvas-drag-handle {
    opacity: 1;
}

.question-canvas-drag-handle:active {
    cursor: grabbing;
}

