.new-form-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
}

.new-form-design-header {
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    padding-bottom: 0;
    padding-top: 20px;
    background-color: var(--color-dark);
    border-bottom: 1px solid var(--color-border);
    z-index: 10;
    width: 100%;
    box-sizing: border-box;
}

.new-form-design-header-left {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: flex-start;
}

.new-form-design-header-right {
    display: flex;
    align-items: center;
    gap: 4px;
    position: relative;
}

.new-form-design-back {
    flex-shrink: 0;
    margin-right: 20px;
}

.new-form-design-back-icon {
    font-size: 16px;
    color: var(--color-text-primary);
    cursor: pointer;
    transition: color 0.2s ease;
}

.new-form-design-back-icon:hover {
    color: var(--color-brand);
}

.new-form-design-title-container {
    flex-shrink: 0;
    padding: 10px 20px;
    background-color: var(--color-dark);
    z-index: 9;
    box-shadow: 0 8px 2px -2px rgba(0, 0, 0, 0.3);
}

.new-form-content-wrapper {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
}

.form-builder-navigation-bar {
    margin-top: 1rem;
    margin-bottom: 0rem;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    gap: 1.5rem;
}

.form-builder-navigation-bar span {
    font-size: 14px;
    font-weight: 500;
    color: var(--color-grey);
    padding-bottom: 4px;
    cursor: pointer;
    border-bottom: 2px solid transparent;
}

.form-builder-navigation-bar span.selected {
    border-bottom: 2px solid var(--color-brand);
    color: var(--color-white);
}

.new-form-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.new-form-design-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
}

.new-form-design-save-state {
    display: flex;
    align-items: center;
    position: absolute;
    top: calc(100% + 12px);
    right: 4px;
}

.new-form-design-status {
    display: flex;
    align-items: center;
}

.new-form-design-status-value {
    padding: 4px 8px;
    color: var(--color-white);
    text-transform: uppercase;
    border-radius: 4px;
    font-size: 0.8em;
    font-weight: 700;
}

.new-form-design-status-value.draft {
    background-color: #ffa6002d; /* Warning yellow for draft status */
    color: #ffa500;
}

.new-form-design-status-value.published {
    background-color: var(--color-brand); /* Existing color for published status */
}

.new-form-design-save-state-value {
    color: var(--color-grey);
    font-style: italic;
    font-size: 14px;
}

.new-form-design-content {
    flex-grow: 1;
    position: relative;
    overflow: hidden;
    padding: 0;
}

.new-form-design-title {
    font-size: 24px;
    font-weight: 500;
    color: var(--color-text-primary);
    margin: 0;
    padding: 5px 0;
    cursor: text;
    display: inline-block;
}

.new-form-design-title-input {
    font-size: 24px;
    font-weight: 500;
    color: var(--color-text-primary);
    background-color: transparent;
    border: none;
    outline: none;
    padding: 5px 0;
    width: auto;
    min-width: 600px;
    max-width: 100%;
}

.new-form-design-question-count {
    margin-left: 14px;
    color: var(--color-grey);
    font-size: 0.9em;
}

.new-form-design-title-input:focus {
    border-bottom: 2px solid var(--color-brand);
}

.new-form-floating-menu {
    position: fixed;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgb(19, 19, 19);
    padding: 15px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.new-form-floating-menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: transform 0.2s ease;
}

.new-form-floating-menu-item:hover {
    transform: scale(1.05);
}

.new-form-floating-menu-icon-container {
    width: 40px;
    height: 40px;
    background-color: var(--color-brand);
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
}

.new-form-floating-menu-icon {
    font-size: 20px;
    color: white;
}

.new-form-floating-menu-label {
    font-size: 12px;
    color: white;
    text-align: center;
    max-width: 70px;
    word-wrap: break-word;
}

.new-form-design-preview-button,
.new-form-design-publish-button {
    padding: 12px 18px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 10px;
}

.new-form-design-preview-button {
    color: var(--color-grey);
    background-color: var(--color-dark);
    border: 1px solid var(--color-grey);
}

.new-form-design-publish-button {
    color: var(--color-white);
    background-color: var(--color-brand);
    border: 1px solid var(--color-brand);
}

.button-icon {
    font-size: 14px;
}

.icon-button-container {
    position: relative;
}

.icon-button {
    background: none;
    border: none;
    color: var(--color-text-primary);
    font-size: 24px;
    cursor: pointer;
    padding: 5px;
    margin-left: 8px;
}
  
  @keyframes pulsed {
    0% {
      background-color: #00ff0036;
      box-shadow: 0 0 5px #00ff0036;
    }
    50% {
      background-color: #00dd00;
      box-shadow: 0 0 3px #00dd00;
    }
    100% {
      background-color: #00ff0036;
      box-shadow: 0 0 5px #00ff0036;
    }
  }

.settings-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: var(--color-white);
    border: 1px solid var(--color-border);
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    min-width: 150px;
}

.settings-menu button {
    display: block;
    width: 100%;
    padding: 10px 15px;
    text-align: left;
    background: none;
    border: none;
    color: var(--color-dark);
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.settings-menu button:hover {
    background-color: var(--color-background-light);
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: var(--color-background-dark);
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    width: 100%;
}

.modal-content h2 {
    margin-top: 0;
}

.modal-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.modal-actions button {
    margin-left: 10px;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.modal-actions button:first-child {
    background-color: var(--color-background-light);
    color: var(--color-text-primary);
}

.modal-actions button:last-child {
    background-color: var(--color-danger);
    color: white;
}

.response-count {
    display: flex;
    align-items: center;
    margin-left: 14px;
    color: var(--color-grey);
    font-size: 0.9em;
}

.response-icon {
    margin-right: 5px;
}

@media screen and (max-width: 768px) {
    .new-form-design-question-count,
    .response-count {
        display: none;
    }
}