.comparison-page-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.comparison-page-hero {
    width: 100%;
    background-color: var(--color-dark-brand);
    padding: 120px 20px 80px;
    box-sizing: border-box;
}

.comparison-page-hero-wrapper {
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 60px;
}

.comparison-page-hero-content {
    flex: 1;
    max-width: 600px;
}

.comparison-page-hero .comparison-page-hero-content p {
    color: var(--color-white);
}

.comparison-page-hero h1 {
    font-size: 2.7rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
    line-height: 1.2;
}

.comparison-page-hero p {
    font-size: 1.2rem;
    color: var(--color-grey);
    line-height: 1.5;
    margin: 0;
    margin-top: 0.5rem;
    text-align: left;
    max-width: 600px;
}

.comparison-page-hero-image-container {
    flex: 0 1 600px;
    min-width: 400px;
}

.comparison-page-hero-image {
    width: 100%;
    height: auto;
    border-radius: 12px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

.comparison-page-cta-button {
    background-color: var(--color-brand);
    color: white;
    border: none;
    padding: 1rem 3rem;
    font-size: 14px;
    font-weight: 600;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 3rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
}

.comparison-page-content {
    width: 100%;
}

.comparison-page-feature-header,
.comparison-page-platform-header {
    color: var(--color-white);
}

.comparison-page-intro {
    text-align: center;
    background-color: var(--color-white);
    color: var(--color-dark);
    padding-top: 60px;
    padding-bottom: 60px;
    width: 100%;
    box-sizing: border-box;
}

.comparison-page-intro h2 {
    font-size: 1.8rem;
    margin-bottom: 60px;
    margin-top: 0;
    font-weight: 800;
}

.comparison-page-intro p {
    font-size: 1.1rem;
    color: var(--color-dark);
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.6;
}

.comparison-page-benefits {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    margin-top: 3rem;
}

p.comparison-page-verdict {
    text-align: center;
    margin-top: 40px;
    background-color: var(--color-brand-opacity);
    padding: 20px;
    border-radius: 12px;
}

.comparison-page-benefit {
    text-align: center;
}

.comparison-page-benefit-icon {
    font-size: 2rem;
    color: var(--color-brand);
    margin-bottom: 1rem;
}

.comparison-page-benefit h3 {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
}

.comparison-page-benefit p {
    font-size: 0.9rem;
    color: var(--color-grey);
}

.comparison-page-section {
    box-sizing: border-box;
}

.comparison-page-section-content {
    max-width: 1200px;
    margin: 0 auto;
}

.comparison-page-section h2 {
    font-size: 1.8rem;
    margin-bottom: 2rem;
    text-align: center;
}

.comparison-page-features-grid {
    width: 100%;
}

.comparison-page-column-headers {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    gap: 1rem;
    padding: 1rem;
    background-color: var(--color-dark-brand);
    border-radius: 8px;
    margin-bottom: 1rem;
    font-weight: 600;
}

.comparison-page-feature-row {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    gap: 1rem;
    padding: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.comparison-page-feature-name h3 {
    font-size: 1rem;
    margin-bottom: 0.5rem;
}

.comparison-page-feature-name p {
    font-size: 0.9rem;
    color: var(--color-grey);
    margin: 0;
}

.comparison-page-feature-value {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.comparison-page-check {
    color: var(--color-brand);
    font-size: 1.2rem;
}

.comparison-page-times {
    color: var(--color-red);
    font-size: 1.2rem;
}

.comparison-page-feature-value span {
    font-size: 0.9rem;
    color: var(--color-grey);
}

@media screen and (max-width: 1150px) {
    .comparison-page-hero h1 {
        font-size: 2.5rem;
        font-weight: 500;
        margin-bottom: 1.5rem;
        line-height: 1.2;
    }
    
    .comparison-page-hero p {
        font-size: 1rem;
        color: var(--color-grey);
        line-height: 1.5;
        margin: 0;
        margin-top: 0.5rem;
        text-align: left;
        max-width: 600px;
    }
}

@media screen and (max-width: 1000px) {
    .comparison-page-hero {
        padding-top: 60px;
    }

    .comparison-page-hero-wrapper {
        flex-direction: column;
        gap: 40px;
    }

    .comparison-page-hero-content {
        text-align: center;
        max-width: 100%;
    }

    .comparison-page-hero-image-container {
        min-width: 200px;
        max-width: 800px;
        width: 100%;
    }

    .comparison-page-hero h1 {
        font-size: 2.5rem;
    }

    .comparison-page-hero p {
        font-size: 1.1rem;
    }

    .comparison-page-benefits {
        grid-template-columns: repeat(2, 1fr);
    }

    .comparison-page-section {
        padding: 40px 20px;
    }

    .comparison-page-process {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .comparison-page-hero {
        padding: 100px 20px 60px;
    }

    .comparison-page-hero h1 {
        font-size: 1.5rem;
    }

    .comparison-page-hero p {
        font-size: 1rem;
    }

    .comparison-page-section h2 {
        font-size: 1.4rem;
    }

    .comparison-page-feature-value {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .comparison-page-feature-name h3,
    .comparison-page-feature-name p,
    .comparison-page-column-headers,
    .comparison-page-feature-value span {
        font-size: 12px;
    }
}

.comparison-page-ratings {
    background-color: var(--color-extra-dark);
    border-radius: 12px;
    padding: 40px;
    margin: 40px 0;
}

.comparison-page-ratings-grid {
    width: 100%;
}

.comparison-page-ratings-header {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    gap: 1rem;
    padding: 1rem;
    background-color: var(--color-dark);
    border-radius: 8px;
    margin-bottom: 1rem;
    font-weight: 600;
}

.comparison-page-ratings-row {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    gap: 1rem;
    padding: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.comparison-page-ratings-category {
    font-weight: 500;
}

.comparison-page-ratings-score {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.comparison-page-ratings-stars {
    display: flex;
    gap: 4px;
    font-size: 1.2rem;
}

.comparison-page-ratings-stars.tetherform {
    color: var(--color-brand);
}

.comparison-page-ratings-stars.competitor {
    color: var(--color-grey);
}

.comparison-page-ratings-score span {
    font-size: 0.9rem;
    color: var(--color-grey);
}

.comparison-page-ratings-source {
    text-align: center;
    font-size: 0.8rem;
    color: var(--color-grey);
    margin-top: 20px;
}

@media screen and (max-width: 768px) {
    .comparison-page-ratings {
        padding: 20px;
    }

    .comparison-page-ratings-row {
        grid-template-columns: 1fr;
        gap: 0.5rem;
    }

    .comparison-page-ratings-header {
        display: none;
    }

    .comparison-page-ratings-category {
        font-weight: 600;
        margin-bottom: 0.5rem;
    }
}

.comparison-page-pricing {
    background-color: var(--color-extra-dark);
    border-radius: 12px;
    padding: 40px;
    margin: 60px 0;
}

.comparison-page-pricing h2 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 1rem;
}

.comparison-page-pricing p {
    text-align: center;
    color: var(--color-grey);
    max-width: 800px;
    margin: 0 auto 40px;
}

.comparison-page-pricing-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
}

.comparison-page-pricing-tetherform,
.comparison-page-pricing-competitor {
    background-color: var(--color-dark);
    border-radius: 8px;
    padding: 20px;
}

.comparison-page-pricing h3 {
    text-align: center;
    font-size: 1.4rem;
    margin-bottom: 30px;
}

.comparison-page-pricing-plans {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.comparison-page-pricing-plan {
    background-color: var(--color-extra-dark);
    border-radius: 8px;
    padding: 20px;
}

.comparison-page-pricing-plan-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.comparison-page-pricing-plan-header h4 {
    font-size: 1.1rem;
    margin: 0;
}

.comparison-page-pricing-amount {
    font-size: 1.2rem;
    font-weight: 600;
}

.comparison-page-pricing-amount span {
    font-size: 0.9rem;
    color: var(--color-grey);
    font-weight: normal;
}

.comparison-page-pricing-features {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.comparison-page-pricing-feature {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--color-grey);
    font-size: 0.9rem;
}

.comparison-page-pricing-feature svg {
    color: var(--color-brand);
}

.comparison-page-pricing-feature.warning svg {
    color: var(--color-grey);
}

.comparison-page-pricing-restriction {
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    color: var(--color-grey);
    font-size: 0.8rem;
    font-style: italic;
}

@media screen and (max-width: 1000px) {
    .comparison-page-pricing {
        padding: 20px;
    }

    .comparison-page-pricing-grid {
        grid-template-columns: 1fr;
    }

    .comparison-page-pricing h2 {
        font-size: 1.6rem;
    }
}

@media screen and (max-width: 500px) {
    .comparison-page-pricing h2 {
        font-size: 1.4rem;
    }

    .comparison-page-pricing-plan-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }
}

.comparison-page-pricing-calculator {
    background-color: var(--color-extra-dark);
    border-radius: 12px;
    padding: 40px;
    margin: 60px 0;
}

.comparison-page-pricing-calculator h3 {
    text-align: center;
    font-size: 1.8rem;
    margin-bottom: 1rem;
}

.comparison-page-pricing-calculator p {
    text-align: center;
    color: var(--color-grey);
    max-width: 600px;
    margin: 0 auto 40px;
}

.comparison-page-pricing-sliders {
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: 800px;
    margin: 0 auto 40px;
}

.comparison-page-pricing-slider {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.comparison-page-pricing-slider label {
    font-size: 1rem;
    font-weight: 600;
}

.comparison-page-pricing-slider input[type="range"] {
    width: 100%;
    height: 4px;
    background: var(--color-dark);
    border-radius: 2px;
    -webkit-appearance: none;
}

.comparison-page-pricing-slider input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    background: var(--color-brand);
    border-radius: 50%;
    cursor: pointer;
    transition: transform 0.2s ease;
}

.comparison-page-pricing-slider input[type="range"]::-webkit-slider-thumb:hover {
    transform: scale(1.1);
}

.comparison-page-pricing-slider-value {
    font-size: 0.9rem;
    color: var(--color-grey);
}

.comparison-page-pricing-comparison {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    margin-bottom: 30px;
}

.comparison-page-pricing-option {
    background-color: var(--color-dark);
    border-radius: 8px;
    padding: 24px;
}

.comparison-page-pricing-option h4 {
    font-size: 1.2rem;
    margin: 0 0 16px 0;
}

.comparison-page-pricing-amount {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 20px;
}

.comparison-page-pricing-amount span {
    font-size: 1rem;
    color: var(--color-grey);
    font-weight: normal;
}

.comparison-page-pricing-features {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.comparison-page-pricing-features span {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--color-grey);
    font-size: 0.9rem;
}

.comparison-page-pricing-savings {
    text-align: center;
    font-size: 1.2rem;
    color: var(--color-brand);
    font-weight: 600;
    padding: 16px;
    background-color: rgba(48, 156, 131, 0.1);
    border-radius: 8px;
}

@media screen and (max-width: 900px) {
    .comparison-page-pricing-calculator {
        padding: 20px;
    }

    .comparison-page-pricing-comparison {
        grid-template-columns: 1fr;
    }

    .comparison-page-pricing-calculator h3 {
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 500px) {
    .comparison-page-pricing-amount {
        font-size: 1.6rem;
    }

    .comparison-page-pricing-calculator h3 {
        font-size: 1.2rem;
    }

    .comparison-page-pricing-savings {
        font-size: 1rem;
    }
}

.comparison-page-process {
    background-color: var(--color-extra-dark);
    border-radius: 12px;
    padding: 60px 40px;
    margin: 60px 0;
}

.comparison-page-process-steps {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1000px;
    margin: 0 auto;
    position: relative;
}

.comparison-page-process-step {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    padding: 0 20px;
}

.comparison-page-process-icon {
    width: 64px;
    height: 64px;
    background-color: rgba(48, 156, 131, 0.1);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: var(--color-brand);
    margin-bottom: 20px;
    position: relative;
    z-index: 2;
}

.comparison-page-process-content {
    position: relative;
    z-index: 2;
}

.comparison-page-process-content h3 {
    font-size: 1.2rem;
    margin: 0 0 12px 0;
}

.comparison-page-process-content p {
    font-size: 0.9rem;
    color: var(--color-grey);
    margin: 0;
    line-height: 1.5;
}

.comparison-page-process-connector {
    position: absolute;
    top: 32px;
    left: 60%;
    width: 80%;
    height: 2px;
}

.comparison-page-process-line {
    position: absolute;
    top: 0;
    left: 0;
}

.comparison-page-process-line line {
    stroke: var(--color-brand);
    stroke-width: 2;
    stroke-dasharray: 6;
    animation: flowLine 1s linear infinite;
}

@keyframes flowLine {
    from {
        stroke-dashoffset: 24;
    }
    to {
        stroke-dashoffset: 0;
    }
}

@media screen and (max-width: 900px) {
    .comparison-page-process {
        padding: 40px 20px;
    }

    .comparison-page-process-steps {
        flex-direction: column;
        gap: 40px;
    }

    .comparison-page-process-connector {
        left: 50%;
        top: auto;
        bottom: -40px;
        width: 2px;
        height: 40px;
        transform: translateX(-50%);
    }

    .comparison-page-process-line {
        height: 100%;
        width: 2px;
    }
}

.comparison-page-section-subtitle {
    text-align: center;
    font-size: 1.1rem;
    max-width: 800px;
    margin: 1rem auto 3rem;
    line-height: 1.6;
    opacity: 0.8;
}

@media screen and (max-width: 1000px) {
    .comparison-page-section {
        padding: 80px 20px;
    }
}

@media screen and (max-width: 500px) {
    .comparison-page-section-subtitle {
        font-size: 1rem;
        margin: 1rem auto 2rem;
    }
}
