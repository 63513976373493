.qr-code-g-outer-container {
    background-color: var(--color-white-pure);
    width: 100%;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.qr-code-g-container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 2rem;
    background-color: var(--color-white-pure);
    color: var(--color-dark);
    padding-bottom: 200px;
    padding-top: 60px;
    box-sizing: border-box;
}

.qr-code-g-header {
    text-align: center;
    margin-bottom: 3rem;
}

.qr-code-g-tag {
    background-color: var(--color-brand);
    color: white;
    padding: 0.25rem 0.75rem;
    border-radius: 1rem;
    font-size: 0.875rem;
    display: inline-block;
}

.qr-code-g-header h1 {
    font-size: 2.5rem;
    margin: 1rem 0;
    color: var(--color-dark);
}

.qr-code-g-header p {
    color: var(--color-grey);
    font-size: 1.125rem;
}

.qr-code-g-view-1 {
    max-width: 600px;
    margin: 0 auto;
}

.qr-code-g-view-1 form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.qr-code-g-view-1 input {
    padding: 1rem;
    border: 2px solid #eee;
    border-radius: 8px;
    font-size: 1rem;
}

.qr-code-g-view-1 button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 1rem;
    background-color: var(--color-brand);
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s;
}

.qr-code-g-disclaimer {
    margin-top: 2rem;
    color: var(--color-grey);
    font-size: 0.875rem;
    text-align: center;
}

.qr-code-g-link-row {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2rem;
}

.qr-code-g-back {
    cursor: pointer;
    font-size: 1.25rem;
    color: var(--color-grey);
}

.qr-code-g-link-row input {
    flex: 1;
    padding: 0.75rem;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    font-size: 1rem;
    background-color: #f1f5f9;
    color: #64748b;
    box-sizing: border-box;
    cursor: default;
    user-select: all;  /* Allows user to easily copy the link if needed */
}

.qr-code-g-link-row input:focus {
    outline: none;
    border-color: #e2e8f0;
}

.qr-code-g-codes {
    display: flex;
    gap: 2rem;
    justify-content: center;
}

.qr-code-g-code-container {
    flex: 1;
    max-width: 500px;
    min-width: 400px;
}

.qr-code-g-code {
    aspect-ratio: 1;
    background-color: #f0f0f0;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.qr-code-g-code svg {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
}

.qr-code-g-code.qr-code-g-white {
    background-color: #333;
}

.qr-code-g-download {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--color-brand);
    cursor: pointer;
    font-weight: 500;
}

.qr-code-g-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(4px);
}

.qr-code-g-modal {
    background: white;
    padding: 2rem;
    border-radius: 12px;
    width: 90%;
    max-width: 500px;
    position: relative;
}

.qr-code-g-modal-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
    color: var(--color-grey);
}

.qr-code-g-modal form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1.5rem;
}

.qr-code-g-modal input {
    padding: 0.75rem;
    border: 2px solid #eee;
    border-radius: 8px;
    font-size: 1rem;
}

.qr-code-g-modal button {
    padding: 0.75rem;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
}

.qr-code-g-modal button[type="submit"] {
    background-color: var(--color-brand);
    color: white;
}

.qr-code-g-modal-cancel {
    background-color: #eee;
    color: var(--color-dark);
}

.qr-code-g-modal h2 {
    color: var(--color-dark);
}

.qr-code-g-modal p {
    color: var(--color-grey);
}

@media screen and (max-width: 800px) {
    .qr-code-g-codes {
        flex-direction: column;
        align-items: center;
    }

    .qr-code-g-code-container {
        width: 100%;
        min-width: unset;
    }
}

.qr-code-g-editor {
    display: flex;
    flex-direction: row-reverse;
    gap: 3rem;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(235, 243, 248);
    padding: 1rem;
    border: 2px solid rgb(187, 208, 222);
    border-radius: 8px;
}

.qr-code-g-options {
    flex-grow: 1;
    padding: 12px;
    max-width: 400px;
}

.qr-code-g-option-section {
    margin-bottom: 2rem;
    width: 100%;
}

.qr-code-g-option-section h3 {
    font-size: 1rem;
    margin-bottom: 1rem;
    color: var(--color-dark);
}

.qr-code-g-option-section-button {
    background-color: var(--color-brand);
    color: var(--color-white-pure);
    padding: 1rem;
    box-sizing: border-box;
    text-align: center;
    border-radius: 20px;
    cursor: pointer;
}

.qr-code-g-colors {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.qr-code-g-color-option {
    width: 32px;
    height: 32px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: transform 0.2s;
}

.qr-code-g-color-option:hover {
    transform: scale(1.1);
}

.qr-code-g-color-option.selected {
    outline: 2px solid var(--color-brand);
    outline-offset: 2px;
}

.qr-code-g-checkbox {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    color: var(--color-dark);
}

.qr-code-g-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.qr-code-g-code {
    background-color: #f0f0f0;
    padding: 1rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
}

.qr-code-g-buttons {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    max-width: 200px;
}

.qr-code-g-download-button,
.qr-code-g-remove-logo-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s;
    width: 100%;
}

.qr-code-g-download-button {
    background-color: var(--color-brand);
    color: white;
}

.qr-code-g-remove-logo-button {
    background-color: transparent;
    color: var(--color-brand);
    border: 1px solid var(--color-brand);
}

@media screen and (max-width: 800px) {
    .qr-code-g-editor {
        flex-direction: column-reverse;
        align-items: stretch;
        gap: 2rem;
    }

    .qr-code-g-options {
        max-width: 100%;
    }

    .qr-code-g-preview {
        width: 100%;
    }
}
