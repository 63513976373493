.form-builder-tool-set {
    width: 100%;
    height: 100%;
    background-color: var(--color-dark);
    border-right: 1px solid var(--color-border-dark);
    display: flex;
    flex-direction: column;
}

.form-builder-tool-set-sections {
    padding: 1rem;
    flex: 1;
    overflow-y: auto;
    /* Firefox scrollbar */
    scrollbar-width: thin;
    scrollbar-color: var(--color-grey) transparent;
}

/* Webkit scrollbar styles */
.form-builder-tool-set-sections::-webkit-scrollbar {
    width: 8px;
}

.form-builder-tool-set-sections::-webkit-scrollbar-track {
    background: transparent;
}

.form-builder-tool-set-sections::-webkit-scrollbar-thumb {
    background-color: var(--color-grey);
    border-radius: 4px;
    border: 2px solid transparent;
    background-clip: content-box;
}

.form-builder-tool-set-sections::-webkit-scrollbar-thumb:hover {
    background-color: var(--color-brand);
    border: 2px solid transparent;
    background-clip: content-box;
}

.form-builder-tool-set-section {
    margin-bottom: 1.5rem;
    border-bottom: 1px solid var(--color-border-dark);
    padding-bottom: 1.5rem;
}

.form-builder-tool-set-section:last-child {
    margin-bottom: 0;
    border-bottom: none;
    padding-bottom: 0;
}

.form-builder-tool-set-section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    cursor: pointer;
    user-select: none;
    border-radius: 4px;
    transition: background-color 0.2s ease;
}

.form-builder-tool-set-section-header:hover {
    background-color: var(--color-darker);
}

.form-builder-tool-set-section-header h3 {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    color: var(--color-white);
}

.form-builder-tool-set-section-header svg {
    font-size: 18px;
    color: var(--color-grey);
}

.form-builder-tool-set-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 0.75rem;
    margin-top: 0.75rem;
    padding: 0 0.5rem;
}

.form-builder-tool-set-item {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    padding: 1rem 0.5rem;
    background-color: var(--color-dark-brand);
    border: 1px solid var(--color-extra-dark);
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
    min-width: 0; /* Ensures text truncation works */
}

.form-builder-tool-set-item:hover {
    background-color: var(--color-brand);
    border-color: var(--color-brand);
    transform: translateY(-1px);
}

.form-builder-tool-set-item-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    margin-bottom: 0.5rem;
    color: var(--color-white);
}

.form-builder-tool-set-item-icon svg {
    font-size: 18px;
}

.form-builder-tool-set-item-label {
    font-size: 12px;
    color: var(--color-white);
    text-align: center;
    line-height: 1.2;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
