.organization-settings {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.organization-settings-main {
    flex-grow: 1;
    padding: 1rem;
    box-sizing: border-box;
}

.organization-settings-main h1 {
    font-size: 1.5rem;
    margin: 0;
}

.organization-settings-sections {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 2rem;
    gap: 2rem;
}

.organization-settings-manage-plan {
    background-color: var(--color-brand);
    padding: 8px 20px;
    border-radius: 8px;
    font-weight: 600;
    font-size: 0.8rem;
    cursor: pointer;
    text-align: center;
}

.organization-settings-manage-logo {
    background-color: var(--color-brand);
    padding: 8px 20px;
    border-radius: 8px;
    font-weight: 600;
    font-size: 0.8rem;
    cursor: pointer;
    text-align: center;
    width: 100px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
}

.organization-settings-section {
    background-color: var(--color-extra-dark);
    padding: 1.5rem;
    color: var(--color-white);
    flex-basis: 20%;
    border-radius: 12px;
    min-width: 240px;
}

.organization-settings-section h3 {
    margin: 0;
    margin-bottom: 2rem;
    font-size: 1.2rem;
}

.organization-setting-row {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    position: relative;
}

.organization-settings-section-key,
.organization-settings-section-value {
    font-size: 0.9rem;
    color: var(--color-white);
}

.organization-settings-section-key {
    font-weight: 400;
    margin-bottom: 0.5rem;
    font-size: 1rem;
}

@media screen and (max-width: 800px) {
    .organization-settings-sections {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: stretch;
        justify-content: flex-start;
        flex-wrap: nowrap;
        margin-top: 2rem;
        gap: 1rem;
    }

    .organization-settings-section {
        flex-basis: 100%;
    }
}

.organization-settings-copy-email {
    position: relative;
    cursor: pointer;
    border-radius: 6px;
}

.organization-settings-copy-email-content {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--color-white);
}

.organization-settings-copy-icon {
    font-size: 1rem;
    color: var(--color-grey);
    transition: all 0.2s ease;
}

.organization-settings-copy-icon.copied {
    color: var(--color-brand);
    animation: scaleUp 0.2s ease;
}

@keyframes scaleUp {
    0% {
        transform: scale(0.8);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

.organization-settings-copy-tooltip {
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--color-dark);
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 0.8rem;
    color: var(--color-white);
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease;
    white-space: nowrap;
    pointer-events: none;
}

.organization-settings-copy-tooltip::before {
    content: '';
    position: absolute;
    top: -4px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: 8px;
    height: 8px;
    background-color: var(--color-dark);
}

.organization-settings-copy-tooltip.show {
    opacity: 1;
    visibility: visible;
    bottom: -35px;
}