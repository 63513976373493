.plans-and-pricing-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
}

.plans-and-pricing-header {
    text-align: center;
    margin-bottom: 60px;
}

.plans-and-pricing-header h1 {
    font-size: 2.5rem;
    margin-bottom: 16px;
    color: var(--color-white-pure);
}

.plans-and-pricing-header p {
    font-size: 1.2rem;
    color: var(--color-white);
}

.plans-and-pricing-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 24px;
    margin-bottom: 40px;
}

.plans-and-pricing-card {
    background: var(--color-dark);
    border: 2px solid rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    padding: 32px;
    position: relative;
    display: flex;
    flex-direction: column;
}

.plans-and-pricing-card-popular {
    border: 2px solid var(--color-brand);
}

.plans-and-pricing-popular-tag {
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    background: var(--color-brand);
    color: var(--color-white-pure);
    padding: 4px 12px;
    border-radius: 12px;
    font-size: 0.9rem;
    font-weight: 600;
}

.plans-and-pricing-card-header {
    text-align: left;
    margin-bottom: 24px;
}

.plans-and-pricing-card-header h3 {
    color: var(--color-white-pure);
    margin-bottom: 12px;
    font-size: 1.5rem;
}

.plans-and-pricing-card-description {
    color: var(--color-grey);
    font-size: 0.95rem;
    line-height: 1.6;
    margin-bottom: 24px;
    height: 100px;
}

.plans-and-pricing-price {
    margin-bottom: 24px;
}

.plans-and-pricing-amount {
    font-size: 2.1rem;
    font-weight: 700;
    color: var(--color-white);
    margin-right: 12px;
}

.plans-and-pricing-period {
    color: var(--color-white);
    font-size: 1rem;
}

.plans-and-pricing-responses {
    color: var(--color-white);
    font-size: 0.9rem;
}

.plans-and-pricing-card-features {
    margin-bottom: 24px;
}

.plans-and-pricing-feature {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 12px;
    color: var(--color-white);
}

.plans-and-pricing-feature svg {
    color: var(--color-brand);
    font-size: 1rem;
    min-width: 1rem;
}

.plans-and-pricing-cta-button {
    width: 100%;
    padding: 12px;
    background: var(--color-brand);
    color: var(--color-white-pure);
    border: none;
    border-radius: 6px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 24px;
}

.plans-and-pricing-guarantee {
    text-align: center;
    margin: 40px 0;
    color: var(--color-white);
}

.plans-and-pricing-comparison {
    margin-top: 80px;
}

.plans-and-pricing-comparison h2 {
    text-align: center;
    margin-bottom: 40px;
    color: var(--color-white-pure);
}

.plans-and-pricing-table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 12px;
    overflow: hidden;
}

.plans-and-pricing-table-header {
    display: grid;
    grid-template-columns: 2fr repeat(4, 1fr);
    background: var(--color-dark);
    padding: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.plans-and-pricing-table-row {
    display: grid;
    grid-template-columns: 2fr repeat(4, 1fr);
    padding: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.plans-and-pricing-table-cell {
    padding: 16px;
    text-align: center;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.plans-and-pricing-table-cell-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.plans-and-pricing-table-cell svg {
    font-size: 1.5rem;
}

.plans-and-pricing-table-cell:last-child {
    border-right: none;
}

.plans-and-pricing-table-cell:first-child {
    text-align: left;
    background: var(--color-dark);
}

.plans-and-pricing-feature-name {
    color: var(--color-white-pure);
    margin-bottom: 4px;
}

.plans-and-pricing-feature-description {
    font-size: 0.9rem;
    color: var(--color-white);
}

.plans-and-pricing-card-benefits {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 24px;
}

.plans-and-pricing-card-benefit {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 16px;
    color: var(--color-white);
    font-size: 0.95rem;
}

.plans-and-pricing-card-benefit svg {
    color: var(--color-brand);
    font-size: 1.25rem;
    min-width: 1.25rem;
}

@media (max-width: 800px) {
    .plans-and-pricing-cards {
        grid-template-columns: 1fr;
    }

    .plans-and-pricing-table {
        font-size: 0.85rem;
    }

    .plans-and-pricing-table-header,
    .plans-and-pricing-table-row {
        grid-template-columns: 2fr repeat(4, 1fr);
    }

    .plans-and-pricing-table-cell {
        padding: 8px 4px;
    }

    .plans-and-pricing-feature-description {
        display: none;
    }

    .plans-and-pricing-table-header .plans-and-pricing-table-cell {
        font-size: 0.8rem;
        font-weight: 600;
    }

    .plans-and-pricing-feature-name {
        font-size: 0.85rem;
    }

    .plans-and-pricing-table-cell svg {
        font-size: 0.85rem;
    }

    .plans-and-pricing-card-description {
        font-size: 0.9rem;
        height: unset;
    }

    .plans-and-pricing-card-benefit {
        font-size: 0.9rem;
    }
}