.registration-container {
    max-width: 400px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .registration-container h2 {
    text-align: center;
    margin-bottom: 1.5rem;
    color: #395059;
    font-size: 1.5rem;
  }
  
  .registration-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 1.5rem;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: #395059;
    font-weight: 500;
  }
  
  .form-group input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
    box-sizing: border-box;
  }
  
  .form-group input:focus {
    outline: none;
    border-color: #395059;
  }
  
  .error-input {
    border-color: #ff0000;
  }
  
  .error {
    color: #ff0000;
    font-size: 0.875rem;
    margin-top: 0.25rem;
  }
  
  .password-input-wrapper {
    position: relative;
  }
  
  .toggle-password {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    color: #395059;
  }
  
  .submit-button {
    background-color: #395059;
    color: white;
    padding: 0.75rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 1rem;
  }
  
  .submit-button:hover:not(:disabled) {
    background-color: #2c3e44;
  }
  
  .submit-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .success-message {
    color: #4caf50;
    text-align: center;
    margin-top: 1rem;
    padding: 0.5rem;
    background-color: #e8f5e9;
    border-radius: 4px;
  }
  
  .error-message {
    color: #ff0000;
    text-align: center;
    margin-top: 1rem;
    padding: 0.5rem;
    background-color: #ffebee;
    border-radius: 4px;
  }
  
  .password-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .password-check {
    color: #ccc;
  }
  
  .password-check.valid {
    color: #4caf50;
  }
  
  .login-link {
    text-align: center;
    margin-top: 1rem;
    font-size: 0.9rem;
  }
  
  .login-link a {
    color: #395059;
    text-decoration: none;
    font-weight: 500;
  }
  
  .login-link a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 480px) {
    .registration-container {
      margin: 1rem;
      padding: 1.5rem;
    }
  }

  .login-container {
    max-width: 400px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #f8f8f8;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .login-container h2 {
    text-align: center;
    margin-bottom: 1.5rem;
    color: #395059;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: #395059;
  }
  
  .form-group input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .error {
    color: #ff0000;
    font-size: 0.875rem;
    margin-top: 0.25rem;
  }
  
  button[type="submit"] {
    background-color: #395059;
    color: white;
    padding: 0.75rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 1rem;
  }
  
  button[type="submit"]:hover {
    background-color: #2c3e44;
  }
  
  button[type="submit"]:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .login-container p {
    text-align: center;
    margin-top: 1rem;
  }
  
  .login-container a {
    color: #395059;
    text-decoration: none;
  }
  
  .login-container a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 480px) {
    .login-container {
      margin: 1rem;
      padding: 1rem;
    }
  }