.home {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home-hero-section {
    width: 100%;
    background: linear-gradient(to bottom, var(--color-dark-brand), var(--color-white));
    min-height: 600px;
    padding: 0 20px;
    padding-top: 60px;
    padding-bottom: 80px;
    box-sizing: border-box;
}

.home-responsive-hero-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    gap: 3rem;
}

.home-responsive-hero-primary h1 {
    font-weight: 600;
    max-width: 800px;
    box-sizing: border-box;
    padding: 0;
    line-height: 1.1;
    text-align: center;
    font-size: 70px;
    margin: 0;
    padding-top: 20px;
}

.home-hero-badge {
    background-color: var(--color-brand-opacity);
    color: var(--color-white);
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 0.3rem 1.5rem;
    border-radius: 6px;
}

.home p {
    font-size: 20px;
    text-align: center;
    font-weight: 400;
    max-width: 800px;
    line-height: 1.6;
    color: rgb(190, 190, 190);
}

.home-responsive-hero-primary {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    min-width: 400px;
}

.home-hero-video-container {
    width: 100%;
    max-width: 1300px;
    box-sizing: border-box;
    background-color: var(--color-dark);
    padding: 20px;
    border-radius: 12px;
}

.home-hero-video,
.home-hero-video video {
    width: 100%;
    box-sizing: border-box;
}

.home-social-proof-container {
    width: 100%;
    background-color: var(--color-dark-brand);
    padding: 20px 20px;
    box-sizing: border-box;
    display: none;
}

.home-social-proof-container h2 {
    text-align: center;
    font-size: 1.8rem;
    font-weight: 700;
    margin: 0 0 40px 0;
    color: var(--color-white);
}

.home-social-proof-names {
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
}

.home-social-proof-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
}

.home-social-proof-logo img {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
    /* opacity: 0.7; */
    transition: opacity 0.2s ease, filter 0.2s ease;
    /* filter: grayscale(100%); */
}

.home-social-proof-logo img:hover {
    opacity: 1;
    filter: grayscale(0%);
}

.home-section-landing {
    margin-top: 100px;
}

.home-section-landing h2 {
    text-align: center;
    font-size: 1.8rem;
    margin: 0;
    margin-bottom: 8px;
    margin-top: 60px;
    font-weight: 500;
}

.home-section-landing h3 {
    text-align: left;
    font-size: 1.2rem;
    margin: 0;
    margin-bottom: 8px;
    margin-top: 60px;
    font-weight: 800;
}

.home-section-landing-subtitle {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 24px;
    color: var(--color-grey);
    font-weight: 400;
    box-sizing: border-box;
}

.home-section-collect,
.home-section-understand {
    width: 100%;
    max-width: 2000px;
    margin-left: auto;
    margin-right: auto;
}

.home-section-collect-surveys {
    margin-top: 80px;
    height: 600px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    gap: 100px;
}

.home-section-collect-surveys img {
    height: 100%;
}

.home-section-understand-images {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: column;
    width: 100%;
    max-width: 1100px;
    gap: 40px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 80px;
}

.home-section-landing-informational-text {
    font-size: 1.4rem !important;
    font-weight: 400;
    margin-left: auto;
    margin-right: auto;
}

.home-section-understand-image {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
}

.home-section-understand-image img {
    width: 100%;
    border-radius: 12px;
    flex: 1;
    max-width: calc(100% - 440px);
}

.home-section-understand-image-commentary {
    min-width: 400px;
    width: 400px;
    flex-shrink: 0;
}

.home-section-understand-image-commentary h4 {
    font-size: 1.6rem;
    margin: 0;
    margin-bottom: 20px;
}

.home-section-understand-image-commentary div {
    font-size: 1.4rem;
    line-height: 1.5;
    color: var(--color-grey);
}

.home-section-landing-split {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 4rem;
    padding: 80px 20px;
    box-sizing: border-box;
    background-color: var(--color-dark-brand);
    margin-left: 200px;
    margin-right: 20px;
    border-radius: 20px;
    margin-top: 80px;
    max-height: 360px;
    overflow: hidden;
    border: 1px solid var(--color-brand-opacity);
    opacity: 0;
    animation: slideInFromRight 0.8s ease-out forwards;
}

.home-section-landing-split.reverse {
    margin-right: 200px;
    margin-left: 20px;
    flex-direction: row-reverse;
    animation: slideInFromLeft 0.8s ease-out forwards;
}

.home-section-landing-split-texts {
    flex-basis: 50%;
    max-width: 440px;
}

.home-section-landing-split-visuals {
    flex-basis: 50%;
    max-width: 400px;
    min-height: 200px;
    position: relative;
    min-width: 400px;
}

.home-section-landing-split-visual-container {
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
}

.home-section-landing-split-visuals img {
    max-width: 700px;
    position: absolute;
    top: 0;
    left: 0;
}

.home-section-landing-split.reverse .home-section-landing-split-visuals img {
    left: unset;
    right: 0;
}

.home-section-landing-split-texts h3,
.home-section-landing-split-texts p {
    text-align: left;
}

.home-section-landing-split-texts h3 {
    font-size: 1.3rem;
    margin: 0;
}

.home-section-landing-split-texts p {
    font-size: 1rem;
    padding: 0 !important;
}

.home-section-landing-split-text-decoration {
    height: 2px;
    width: 80px;
    background-color: var(--color-brand);
    margin-bottom: 20px;
}

@media screen and (max-width: 1300px) {
    .home-responsive-hero-primary {
        min-width: 400px;
    }

    .home-responsive-hero-primary h1 {
        font-size: 60px;
    }

    .home-section-collect-surveys {
        margin-top: 80px;
        height: 400px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;
        gap: 40px;
    }
}

@media screen and (max-width: 1000px) {
    .home-hero-section {
        margin-top: 0px;
        padding-top: 80px;
    }

    .home-responsive-hero-container {
        flex-direction: column;
        gap: 2rem;
    }

    .home h1 {
        font-size: 40px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        max-width: 900px;
    }

    .home p {
        font-size: 18px;
        max-width: 700px;
        padding: 0px 20px;
    }

    .home-section-landing-informational-text {
        font-size: 1.2rem !important;
    }

    .home-responsive-hero-primary {
        width: 100%;
        flex-direction: column;
        align-items: center;
        min-width: unset;
        margin-left: auto;
        margin-right: auto;
    }

    .home-section-understand-image-commentary {
        min-width: unset;
        width: 100%;
    }

    .home-hero-video-container {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }

    .home-responsive-hero-primary h1,
    .home-responsive-hero-primary p {
        text-align: center;
        padding-top: 0;
    }

    .home-responsive-hero-primary h1 {
        font-size: 60px;
    }

    .home-primary-cta {
        justify-content: center;
        width: 100%;
    }
    
    .home-primary-cta-register {
        font-size: 1rem;
        margin-left: auto;
        margin-right: auto;
    }

    .home-cta-doublewide {
        flex-direction: column;
        align-items: stretch;
        gap: 1rem;
    }

    .home-section-landing p {
        margin-left: auto;
        margin-right: auto;
    }

    .home-section-landing {
        padding: 20px;
        box-sizing: border-box;
    }

    .home-social-proof-names {
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 2rem;
        height: unset;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
    }

    .home-social-proof-names img {
        height: 32px;
    }

    .home-section-understand-image {
        flex-direction: column-reverse;
        gap: 40px;
    }

    .home-section-understand-image-commentary {
        padding: 0 20px;
    }

    .home-section-understand-image-commentary h4,
    .home-section-understand-image-commentary div {
        text-align: center;
    }

    .home-section-understand-image-commentary h4 {
        font-size: 1.2rem;
    }

    .home-section-understand-image-commentary div {
        font-size: 1rem;
    }

    .home-section-understand-image img {
        max-width: 100%;
    }

    .home-section-understand-image-commentary {
        min-width: unset;
        width: 100%;
    }

    .home-section-landing-split,
    .home-section-landing-split.reverse {
        width: 95%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        padding: 40px 20px;
        gap: 1rem;
        margin-top: 40px;
        max-height: unset;
        padding-bottom: 0;
    }

    .home-section-landing-split-texts {
        max-width: unset;
    }

    .home-section-landing-split-visuals {
        width: 100%;
        flex-basis: unset;
        max-width: unset;
        overflow: hidden;
        max-height: 200px;
    }
        
    .home-section-landing-split-visuals img {
        max-width: 700px;
        position: static;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }

    .home-section-landing-split-visual-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    .home-section-landing-split-texts h3 {
        font-size: 1.5rem;
        font-weight: 400;
    }

}

@media screen and (max-width: 768px) {
    .home-hero-section {
        margin-top: 0px;
        padding-top: 40px;
    }

    .home h1 {
        font-size: 48px;
        margin-top: 20px;
    }

    .home-hero-badge {
        font-size: 10px;
        padding: 4px 12px;
    }

    .home-section-collect-surveys {
        margin-top: 80px;
        height: unset;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;
    }

    .home-section-collect-surveys img {
        height: 600px;
    }

    .home-section-landing h2 {
        font-size: 1.7rem;
    }

    .home-section-understand-image-commentary h4 {
        font-size: 1rem;
    }

    .home-section-understand-image-commentary div {
        font-size: 14px;
    }

    .home-section-landing-informational-text {
        font-size: 1rem !important;
    }

    .home-section-landing-subtitle {
        font-size: 1.2rem;
    }

    .home-section-collect-surveys img:nth-of-type(2),
    .home-section-collect-surveys img:nth-of-type(3) {
        display: none;
    }

    .home-social-proof-names {
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
    }

    .home-social-proof-container h2 {
        font-size: 1.4rem;
        margin-bottom: 30px;
    }

    .home-social-proof-logo {
        height: 32px;
    }
}

@media screen and (max-width: 500px) {
    .home h1 {
        font-size: 26px;
    }

    .home-section-landing h2 {
        font-size: 20px;
    }

    .home p,
    .home-section-landing-subtitle {
        font-size: 15px;
    }

    .home-primary-cta-register {
        font-size: 14px;
    }

    .home-section-landing-informational-text {
        font-size: 14px !important;
    }

    .home-social-proof-container p {
        font-size: 10px !important;
    }

    .home-social-proof-container {
        padding: 40px 20px;
    }

    .home-social-proof-container h2 {
        font-size: 1.2rem;
        margin-bottom: 20px;
    }

    .home-social-proof-names {
        gap: 20px;
    }

    .home-social-proof-logo {
        height: 28px;
    }
}

.home-faq-section {
    max-width: 800px;
    margin: 60px auto;
    padding: 0 20px;
    margin-top: 40px;
}

.home-faq-section h2 {
    text-align: center;
    margin-bottom: 30px;
}

.faq-item {
    margin-bottom: 15px;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 15px;
}

.faq-item:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
}

.faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    cursor: pointer;
    font-weight: bold;
}

.faq-icon {
    transition: transform 0.3s ease;
}

.faq-icon.open {
    transform: rotate(180deg);
}

.faq-answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease, padding 0.3s ease;
    line-height: 1.6;
}

.faq-answer.open {
    max-height: 500px; /* Adjust this value based on your content */
    padding-bottom: 15px;
}

.home-pricing-section {
    max-width: 1000px;
    margin: 60px auto;
    padding: 0 20px;
    text-align: center;
}

.home-pricing-section h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.home-pricing-section > p {
    font-size: 1.1rem;
    color: var(--color-grey);
    margin-bottom: 2rem;
}

.billing-toggle-container {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
}

.billing-toggle {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    padding: 0.25rem;
    display: flex;
    align-items: center;
}

.toggle-button {
    padding: 0.5rem 1rem;
    border-radius: 4px;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s, color 0.3s;
    color: var(--color-dark);
}

.toggle-button span {
    color: var(--color-brand);
    margin-left: 4px;
    font-size: 12px;
}

.toggle-button.active {
    background-color: var(--color-brand);
    color: var(--color-white);
}

.home-pricing-plans {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
}

.home-pricing-plan {
    width: 280px;
    border: 1px solid var(--color-grey);
    padding: 1.5rem 1.2rem;
    box-sizing: border-box;
    border-radius: 6px;
    text-align: left;
}

.home-pricing-plan h4 {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 800;
    display: flex;
    align-items: flex-end;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid var(--color-grey);
}

.home-pricing-plan h4 span {
    font-size: 0.8rem;
    font-weight: 400;
    margin-left: 6px;
}

.home-pricing-plan-selector {
    width: 28px;
    min-width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 0.2rem;
}

.home-pricing-plan-selector svg {
    font-size: 20px;
    color: var(--color-grey);
}

.home-pricing-plan-info-costs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    margin-top: 0.8rem;
}

.home-pricing-plan-info-costs span {
    font-size: 1rem;
}

.home-pricing-plan-info-costs span:last-of-type {
    font-size: 0.8rem;
    color: var(--color-grey);
}

.home-pricing-cta {
    background-color: var(--color-brand);
    color: var(--color-white);
    border: none;
    padding: 0.75rem 1rem;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    width: 100%;
    margin-top: 1.5rem;
    transition: background-color 0.3s;
}

.home-pricing-cta:hover {
    background-color: var(--color-brand-dark);
}

.home-lazy-video-container {
    width: 100%;
    max-width: 1000px;
    box-sizing: border-box;
    padding: 32px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 120px;
}

@media screen and (max-width: 900px) {
    .home-pricing-plans {
        flex-direction: column;
        align-items: center;
    }

    .home-pricing-plan {
        width: 100%;
        max-width: 400px;
    }
}

.pricing-page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.pricing-page-content {
    flex: 1 0 auto;
    padding: 40px 0;
    padding-top: 0;
}

@media screen and (max-width: 500px) {
    .home-pricing-section h2 {
        font-size: 20px;
        margin-bottom: 0.5rem;
    }
}

.home-why-choose-container,
.home-testimonial-container {
    width: 100%;
    background-color: var(--color-white);
    padding: 60px 20px;
    box-sizing: border-box;
    padding-top: 60px;
}

.home-testimonial-container {
    margin-top: 80px;
}

.home-why-choose-container h2,
.home-testimonial-container h2 {
    color: var(--color-dark);
    text-align: center;
    font-size: 1.8rem;
    font-weight: 500;
    margin: 0 0 60px 0;
}

.home-testimonial-container h2 {
    text-align: left;
    margin-bottom: 2rem;
}

.home-testimonial-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    gap: 3rem;
    color: var(--color-dark);
}

.home-testimonial-benefits,
.home-testimonial-quote {
    max-width: 600px;
    flex-basis: 50%;
}

.home-testimonial-benefits-list {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.home-testimonial-benefits-list span {
    font-size: 18px;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.home-testimonial-benefits-list span svg {
    font-size: 28px;
    color: var(--color-brand);
}

.home-testimonial-quote {
    display: flex;
    flex-direction: column;
    gap: 1rem
}

.home-testimonial-quote svg {
    font-size: 28px;
    color: var(--color-brand);
}

.home-testimonial-quote p {
    margin: 0;
    padding: 0;
    color: var(--color-grey);
    font-size: 1.1rem;
    font-style: italic;
    line-height: 1.5;
    text-align: left;
    padding-left: 1.5rem;
}

.home-why-choose-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.home-why-choose-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

@keyframes iconBackgroundAnimation {
    0% {
        border-radius: 50%;
        background-color: rgba(48, 156, 131, 0.1);
        transform: rotate(0deg);
    }
    25% {
        border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
        background-color: rgba(48, 156, 131, 0.2);
        transform: rotate(5deg);
    }
    50% {
        border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
        background-color: rgba(48, 156, 131, 0.15);
        transform: rotate(-5deg);
    }
    75% {
        border-radius: 40% 60% 30% 70% / 60% 30% 70% 40%;
        background-color: rgba(48, 156, 131, 0.2);
        transform: rotate(5deg);
    }
    100% {
        border-radius: 50%;
        background-color: rgba(48, 156, 131, 0.1);
        transform: rotate(0deg);
    }
}

.home-why-choose-icon {
    font-size: 1.8rem;
    color: var(--color-brand);
    margin-bottom: 16px;
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(48, 156, 131, 0.1);
    border-radius: 50%;
    animation: iconBackgroundAnimation 6s ease-in-out infinite;
}

.home-why-choose-item:hover .home-why-choose-icon {
    animation-play-state: paused;
    transform: translateY(-5px) scale(1.1);
}

.home-why-choose-item h3 {
    color: var(--color-dark);
    font-size: 1.2rem;
    margin: 0 0 12px 0;
    font-weight: 700;
}

.home-why-choose-item p {
    color: var(--color-dark);
    font-size: 1rem;
    line-height: 1.5;
    margin: 0;
    opacity: 0.8;
    font-weight: 400;
}

@media screen and (max-width: 1000px) {
    .home-why-choose-container h2,
    .home-testimonial-container h2 {
        font-size: 1.8rem;
        margin-bottom: 40px;
    }

    .home-why-choose-grid {
        grid-template-columns: 1fr;
        gap: 40px;
        max-width: 500px;
    }

    .home-why-choose-item {
        padding: 0 20px;
    }

    .home-testimonial-container {
        flex-direction: column;
        align-items: stretch;
        gap: 4rem;
    }
}

@media screen and (max-width: 500px) {
    .home-why-choose-container,
    .home-testimonial-container {
        padding: 60px 20px;
    }

    .home-why-choose-container h2,
    .home-testimonial-container h2 {
        font-size: 20px;
        margin-bottom: 30px;
    }

    .home-why-choose-icon {
        font-size: 1.2rem;
        height: 32px;
        width: 32px;
    }

    .home-why-choose-item h3 {
        font-size: 1.1rem;
    }

    .home-why-choose-item p {
        font-size: 0.9rem;
    }
}

.home-hero-image {
    width: 100%;
    height: auto;
    display: block;
    box-sizing: border-box;
    border-radius: 12px;
}

.home-primary-cta {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.home-primary-cta-register {
    background-color: var(--color-brand);
    text-transform: uppercase;
    padding: 1rem 3rem;
    border-radius: 8px;
    font-size: 0.9rem;
    letter-spacing: 0.1rem;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    text-align: center;
    justify-content: center;
    transition: background-color 0.2s ease;
}

.home-cta-doublewide {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 20px;
}

@media screen and (max-width: 1000px) {
    .home-primary-cta {
        justify-content: center;
        width: 100%;
    }
    
    .home-primary-cta-register {
        font-size: 1rem;
        margin-left: auto;
        margin-right: auto;
    }

    .home-cta-doublewide {
        flex-direction: column;
        align-items: stretch;
        gap: 1rem;
    }
}

@media screen and (max-width: 500px) {
    .home-primary-cta-register {
        font-size: 14px;
        padding: 0.8rem 2rem;
    }
}

.home-hero-video-wrapper {
    position: relative;
    width: 100%;
    cursor: pointer;
}

.home-hero-play-overlay {
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    opacity: 1;
    transition: all 0.3s ease;
    padding: 8px 16px;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(4px);
    border-radius: 30px;
    transform: translateY(0);
}

.home-hero-video-wrapper:hover .home-hero-play-overlay {
    transform: translateY(-5px);
    background-color: rgba(0, 0, 0, 0.85);
}

.home-hero-play-button {
    width: 32px;
    height: 32px;
    background-color: var(--color-brand);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: white;
    margin-bottom: 0;
    transition: transform 0.2s ease;
}

.home-hero-play-text {
    color: white;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-shadow: none;
}

@media screen and (max-width: 768px) {
    .home-hero-play-overlay {
        bottom: 12px;
        right: 12px;
        padding: 6px 12px;
    }

    .home-hero-play-button {
        width: 24px;
        height: 24px;
        font-size: 10px;
    }

    .home-hero-play-text {
        font-size: 12px;
    }
}

.home-video-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    padding: 20px;
}

.home-video-modal-content {
    position: relative;
    width: 100%;
    max-width: 1200px;
    aspect-ratio: 16/9;
    background-color: black;
    border-radius: 12px;
    overflow: hidden;
}

.home-video-modal-close {
    position: fixed;
    top: 40px;
    right: 40px;
    background: rgba(0, 0, 0, 0.5);
    border: none;
    color: white;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1001;
    font-size: 20px;
    transition: background-color 0.2s ease;
}

.home-video-modal-close:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

.desktop-only {
    display: flex;
}

@media screen and (max-width: 768px) {
    .desktop-only {
        display: none;
    }

    .home-video-modal-content {
        max-width: 100%;
    }
}

.home-analytics-section {
    width: 100%;
    max-width: 1600px;
    padding: 80px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
}

.home-analytics-image-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1),
                0 0 40px var(--color-brand);
    position: relative;
    margin-top: 4rem;
}

.home-analytics-image-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 12px;
    background: radial-gradient(
        circle at center,
        var(--color-brand-opacity) 0%,
        transparent 70%
    );
    opacity: 0.3;
    pointer-events: none;
}

.home-analytics-image {
    width: 100%;
    height: auto;
    display: block;
    position: relative;
    z-index: 1;
}

.home-analytics-features {
    font-size: 1.25rem;
    line-height: 1.6;
    text-align: center;
    max-width: 800px;
    margin-top: 48px;
    color: var(--color-grey-dark);
}

@media screen and (max-width: 768px) {
    .home-analytics-section {
        padding: 40px 20px;
    }

    .home-analytics-section h2 {
        font-size: 2rem;
    }

    .home-analytics-description,
    .home-analytics-features {
        font-size: 1.1rem;
    }
}

html {
    scroll-behavior: smooth;
}

.back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: var(--color-brand);
    color: var(--color-white);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 1000;
}

.back-to-top:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    background-color: var(--color-dark);
    color: var(--color-brand);
}

@media screen and (max-width: 768px) {
    .back-to-top {
        bottom: 16px;
        right: 16px;
        width: 36px;
        height: 36px;
    }
}

@keyframes slideInFromRight {
    0% {
        opacity: 0;
        transform: translateX(100px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideInFromLeft {
    0% {
        opacity: 0;
        transform: translateX(-100px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.home-section-landing-split {
    opacity: 0;
    animation: slideInFromRight 0.8s ease-out forwards;
}

.home-section-landing-split.reverse {
    animation: slideInFromLeft 0.8s ease-out forwards;
}

.home-section-landing-split.animate {
    animation-play-state: running;
}

@media screen and (max-width: 768px) {
    .home-section-landing-split,
    .home-section-landing-split.reverse {
    opacity: 1;
        animation: none;
        transform: none;
    }
}