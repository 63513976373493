.organization-forms {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.org-forms-main {
    flex-grow: 1;
    padding: 20px;
    padding-top: 0;
}

.org-forms-no-license {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin-top: 4rem;
}

.org-forms-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 24px;
    width: 100%;
    margin-top: 24px;
}

.org-forms-item {
    padding: 14px;
    background-color: var(--color-dark);
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.org-forms-item:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    border-color: rgba(255, 255, 255, 0.2);
}

.org-forms-item-header {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.org-forms-item-title-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.form-status {
    padding: 3px 12px;
    border-radius: 4px;
    color: var(--color-white);
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.3px;
    display: inline-flex;
    align-items: center;
    align-self: flex-start;
}

.form-title {
    font-size: 14px;
    font-weight: 600;
    margin: 12px 0 8px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.form-subtitle {
    font-size: 12px;
    color: var(--color-grey);
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.org-forms-item-stats {
    display: flex;
    padding: 8px 0 0;
}

.org-forms-response-badge {
    font-size: 10px;
    font-weight: 500;
    color: var(--color-white);
    background-color: rgba(255, 255, 255, 0.1);
    padding: 3px 12px;
    border-radius: 20px;
}

.org-forms-stat {
    display: flex;
    align-items: center;
    gap: 8px;
}

.org-forms-stat-icon-wrapper {
    width: 24px;
    height: 24px;
    background-color: #2A4365;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.org-forms-stat-icon {
    font-size: 14px;
    color: var(--color-white);
}

.org-forms-stat-value {
    font-size: 14px;
    font-weight: 500;
    color: var(--color-white);
}

.org-forms-live-indicator {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #4CAF50;
    box-shadow: 0 0 8px rgba(76, 175, 80, 0.5);
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.4);
    }
    70% {
        box-shadow: 0 0 0 6px rgba(76, 175, 80, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(76, 175, 80, 0);
    }
}

.badge-draft {
    background-color: #FFA726;
    color: #000;
}

.badge-live {
    background-color: #09B96D;
    color: white;
}

.badge-closed {
    background-color: #78909C;
    color: white;
}

/* Import status badges */
.badge-pending {
    background-color: #FFA726;
    color: #000;
}

.badge-analyzing {
    background-color: #FFA726;
    color: #000;
}

.badge-mapping {
    background-color: #FFA726;
    color: #000;
}

.badge-processing {
    background-color: #FFA726;
    color: var(--color-dark);
}

.badge-completed {
    background-color: #09B96D;
    color: white;
}

.badge-failed {
    background-color: #EF5350;
    color: white;
}

.org-forms-item-meta {
    margin-top: auto;
    display: flex;
    align-items: center;
    gap: 8px;
}

.org-forms-item-meta-dot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: var(--color-grey);
}

.org-forms-item-meta-text {
    font-size: 13px;
    color: var(--color-grey);
}

.org-forms-icon {
    font-size: 16px;
}

.org-forms-header {
    margin-bottom: 20px;
}

.org-forms-header-titles {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--color-grey);
    padding-bottom: 0.9rem;
}

.org-forms-header-titles-page-title {
    display: flex;
    align-items: center;
    gap: 4px;
}

.org-forms-header-titles-page-title h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 700;
    color: var(--color-white);
}

.org-forms-header-titles-page-title svg {
    font-size: 18px;
    color: var(--color-white);
}

.org-forms-filters {
    margin-top: 20px;
    display: flex;
    gap: 12px;
    align-items: center;
}

.org-forms-search {
    position: relative;
    flex: 1;
}

.org-forms-sort {
    position: relative;
}

.org-forms-sort-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 16px;
    background: none;
    border: none;
    border-radius: 4px;
    color: var(--color-white);
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease;
    width: 240px;
}

.sort-icon {
    font-size: 16px;
    color: var(--color-grey);
}

.org-forms-sort-menu {
    position: absolute;
    top: calc(100% + 4px);
    right: 0;
    background-color: var(--color-dark);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    width: 240px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}

.org-forms-sort-option {
    display: block;
    width: 100%;
    padding: 8px 16px;
    text-align: left;
    background: none;
    border: none;
    color: var(--color-grey);
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.org-forms-sort-option:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: var(--color-white);
}

.org-forms-sort-option.active {
    color: var(--color-white);
    background-color: rgba(255, 255, 255, 0.1);
}

.context-menu {
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.1);
    padding: 0;
    z-index: 1000;
    width: 180px;
}

.context-menu button {
    display: block;
    width: 100%;
    padding: 8px 15px;
    text-align: left;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 12px;
}

.context-menu button:hover {
    background-color: #f0f0f0;
}

/* Empty State Styles */
.org-forms-empty-state {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    padding: 20px;
}

.org-forms-empty-state-content {
    max-width: 800px;
    text-align: center;
    animation: fadeIn 0.5s ease-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.org-forms-empty-state-content h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    background: linear-gradient(45deg, var(--color-brand), #4a90e2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.org-forms-empty-state-content p {
    font-size: 1.1rem;
    color: var(--color-grey);
    margin-bottom: 3rem;
}

.org-forms-empty-state-steps {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin: 3rem 0;
}

.org-forms-empty-step {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    text-align: left;
    padding: 1.5rem;
    background-color: var(--color-extra-dark);
    border-radius: 12px;
    transition: transform 0.2s ease;
}

.org-forms-empty-step:hover {
    transform: translateY(-5px);
}

.org-forms-empty-step-number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    min-width: 32px;
    height: 32px;
    background-color: var(--color-brand);
    color: var(--color-white);
    border-radius: 50%;
    font-weight: bold;
    font-size: 1.1rem;
}

.org-forms-empty-step-content h3 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
    color: var(--color-white);
}

.org-forms-empty-step-content p {
    margin: 0;
    font-size: 0.9rem;
    color: var(--color-grey);
}

.org-forms-empty-state-button {
    display: inline-flex;
    align-items: center;
    gap: 0.8rem;
    padding: 1rem 2rem;
    font-size: 1.1rem;
    font-weight: 600;
    color: var(--color-white);
    background-color: var(--color-brand);
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-top: 2rem;
}

.org-forms-empty-state-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(var(--color-brand-rgb), 0.3);
}

.org-forms-empty-state-button .org-forms-icon {
    font-size: 1.2rem;
}

.org-forms-new-button {
    padding: 8px 18px;
    background-color: var(--color-brand);
    color: var(--color-white);
    border: none;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 13px;
    font-weight: 600;
    transition: all 0.2s ease;
}

.org-forms-new-button:hover {
    box-shadow: 0 4px 12px rgba(var(--color-brand-rgb), 0.3);
    background-color: #288972;
}

.org-forms-new-button .org-forms-icon {
    font-size: 14px;
}

@media screen and (max-width: 800px) {
    .org-forms-empty-state-steps {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .org-forms-empty-state-content h1 {
        font-size: 2rem;
    }

    .org-forms-empty-step {
        padding: 1rem;
    }

    .org-forms-empty-state-button {
        width: 100%;
        justify-content: center;
    }

    .org-forms-filters {
        flex-direction: column;
        align-items: stretch;
    }

    .org-forms-search {
        width: 100%;
    }

    .org-forms-item {
        aspect-ratio: auto;
        min-height: 250px;
    }

    .form-title {
        font-size: 14px;
        height: auto;
        -webkit-line-clamp: 1;
    }

    .org-forms-item-stats {
        padding: 12px 0;
        gap: 12px;
    }

    .org-forms-list {
        grid-template-columns: 1fr;
    }
}

.org-forms-updated-date {
    position: absolute;
    top: 14px;
    right: 14px;
    font-size: 12px;
    color: var(--color-grey);
}

.org-forms-item-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0 0;
    margin-top: 12px;
}

.org-forms-action-buttons-right {
    display: flex;
    gap: 8px;
}

.org-forms-action-button {
    background: none;
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: var(--color-grey);
    font-size: 12px;
    padding: 6px 12px;
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all 0.2s ease;
}

.org-forms-action-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: var(--color-white);
}

.org-forms-action-button.menu-button {
    padding: 6px;
}

.org-forms-action-button svg {
    font-size: 16px;
}

.org-forms-action-button.org-forms-action-button-edit {
    background-color: rgba(255, 255, 255, 0.1);
    color: var(--color-white)
}

.search-icon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--color-grey);
}

.org-forms-search-input {
    width: 300px;
    padding: 10px 10px 10px 35px;
    border: 1px solid var(--color-grey);
    border-radius: 4px;
    font-size: 14px;
    background-color: var(--color-dark);
    color: var(--color-white);
    box-sizing: border-box;
}