.toggle-switch {
    width: 28px;
    height: 16px;
    display: flex;
    align-items: center;
    border-radius: 9999px;
    padding: 2px;
    cursor: pointer;
    background-color: #d1d5db;
    transition: background-color 0.3s ease-in-out;
  }
  
  .toggle-switch.selected {
    background-color: var(--color-brand);
  }
  
  .toggle-switch-handle {
    width: 12px;
    height: 12px;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    transform: translateX(1px);  /* Add a small offset when unselected */
  }
  
  .toggle-switch.selected .toggle-switch-handle {
    transform: translateX(13px);  /* Adjust this value to account for the initial offset */
  }