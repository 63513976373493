.loader-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.loader {
    width: 60px;
    aspect-ratio: 1;
    display: flex;
    animation: l13-0 4s infinite linear .5s;
}
.loader::before,
.loader::after {
    content:"";
    flex:1;
    background: var(--color-brand);
    clip-path: polygon(50% 0,100% 0,100% 100%,50% 100%,0 75%,0 25%);
    animation: l13-1 1s infinite linear;
}
.loader::after {
    transform: scale(-1);
    animation: none;
}
@keyframes l13-0 {
    0%   ,12.49% {transform: rotate(0deg)}
    12.5%,37.49% {transform: rotate(90deg)}
    37.5%,62.49% {transform: rotate(180deg)}
    62.5%,87.49% {transform: rotate(270deg)}
    87.5%,100%   {transform: rotate(360deg)}
}
@keyframes l13-1 {
    0%,
    5%   {transform:translate(0px)   perspective(150px) rotateY(0deg) }
    33%  {transform:translate(-10px) perspective(150px) rotateX(0deg) }
    66%  {transform:translate(-10px) perspective(150px) rotateX(-180deg)}
    95%,
    100%{transform: translate(0px)   perspective(150px) rotateX(-180deg)}
}