.form-builder-design-options {
    padding: 20px;
    height: 100%;
    overflow-y: auto;
}

.form-builder-design-options h2,
.form-builder-notifications h2,
.form-builder-schedule h2,
.form-builder-design-options-header-row h2 {
    padding-bottom: 1rem;
    font-weight: 400;
    margin: 0;
    margin-top: 1rem;
}

.form-builder-design-options-header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid var(--color-white);
}

.form-builder-design-options-header-text-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 14px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 14px;
};

.form-builder-design-options h3 {
    color: var(--color-dark);
    margin-top: 30px;
    margin-bottom: 15px;
}

.form-builder-design-options p {
    color: var(--color-text-secondary);
    margin-bottom: 10px;
}

.form-builder-design-options-input {
    max-width: 800px;
}

.form-builder-design-option {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 120px;
    border-bottom: 1px solid var(--color-grey);
    padding-bottom: 20px;
    padding-top: 20px;
}

.form-builder-design-option-information {
    flex-basis: 20%;
    max-width: 320px;
    min-width: 320px;
}

.form-builder-design-option-setting-coming-soon {
    text-align: left;
    font-size: 0.9rem;
    font-weight: 400;
    padding: 1rem 0;
    color: var(--color-white);
}

.form-builder-design-option-information h4 {
    margin: 0;
    font-size: 1rem;
}

.form-builder-design-option-information p {
    margin: 0;
    margin-top: 0.5rem;
    color: grey;
    font-size: 14px;
}

.form-builder-design-option-setting {
    flex-grow: 1;
    width: 100%;
}

.form-builder-design-option-setting input,
.form-builder-design-option-setting textarea {
    background-color: var(--color-extra-dark);
    border-bottom: 1px solid var(--color-border);
    border: 1px solid var(--color-border);
    border-bottom-color: var(--color-brand);
    box-sizing: border-box;
    color: var(--color-white);
    font-size: 16px;
    padding: 12px 8px;
    width: 100%;
}

.form-builder-design-option-setting textarea.form-builder-design-options-textarea {
    width: 100%;
    max-width: 800px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid var(--color-border);
    border-radius: 4px;
    font-family: inherit;
    box-sizing: border-box;
    color: var(--color-white);
    resize: none;
    height: auto;
    overflow-y: auto;
}

.form-builder-color-picker-container {
    position: relative;
    display: flex;
    align-items: center;
}

.form-builder-color-preview {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid var(--color-border);
    cursor: pointer;
    margin-right: 10px;
}

.form-builder-hex-input {
    width: 100%;
    padding: 8px;
    margin-top: 10px;
    border: 1px solid var(--color-border);
    border-radius: 4px;
    box-sizing: border-box;
}

.form-builder-color-picker-popover {
    position: absolute;
    z-index: 2;
    top: 45px;
    left: 0;
    background-color: var(--color-white);
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
}

.form-builder-notifications {
    padding: 20px;
    height: 100%;
    overflow-y: auto;
}

.notification-section {
    margin-bottom: 30px;
}

.notification-section h3 {
    color: var(--color-dark);
    margin-bottom: 10px;
}

.email-input-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 1px solid var(--color-border);
    border-radius: 4px;
    margin-bottom: 5px;
}

.email-input-container input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid var(--color-border);
    font-size: 14px;
    background-color: var(--color-extra-dark);
    color: var(--color-white);
    border-bottom: 1px solid var(--color-brand);
}

.email-input-container input:disabled {
    background-color: var(--color-grey);
    cursor: not-allowed;
}

.email-pills-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 1rem;
}

.email-pill {
    background-color: var(--color-brand-opacity);
    color: var(--color-brand);
    padding: 2px 8px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
}

.email-pill button {
    background: none;
    border: none;
    color: var(--color-brand);
    margin-left: 14px;
    cursor: pointer;
    font-size: 20px;
}

.checkbox-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.checkbox-group label {
    display: flex;
    align-items: center;
    gap: 20px;
    cursor: pointer;
    font-size: 14px;
}

.checkbox-group input[type="checkbox"] {
    width: 18px;
    height: 18px;
}

.checkbox-group-row {
    flex-direction: row;
}

.form-builder-notification-block {
    padding: 14px 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    background-color: var(--color-extra-dark);
    color: var(--color-white);
    border: 1px solid var(--color-grey);
    cursor: pointer;
    position: relative;
}

.form-builder-notification-block.selected {
    border: 1px solid var(--color-brand);
    color: var(--color-brand);
}

.form-builder-notification-block-icon-selected {
    display: none;
    position: absolute;
    font-size: 16px;
    top: -8px;
    right: -8px;
    background-color: var(--color-extra-dark);
    border-radius: 20px;
}

.form-builder-notification-block.selected .form-builder-notification-block-icon-selected {
    display: block;
}

.react-datepicker-wrapper {
    margin-left: 10px;
}

.form-builder-schedule {
    padding: 20px;
    height: 100%;
    overflow-y: auto;
}

.schedule-section {
    margin-bottom: 30px;
    width: 100%;
    box-sizing: border-box;
}

.schedule-section h3 {
    color: var(--color-dark);
    margin-bottom: 10px;
}

.schedule-info {
    margin-top: 30px;
    padding: 15px;
    background-color: var(--color-background-dark);
    border-radius: 8px;
}

.schedule-info p {
    color: var(--color-text-secondary);
    margin-bottom: 10px;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__input-container input {
    width: 100%;
    box-sizing: border-box;
    padding: 8px;
    border: 1px solid var(--color-border);
    border-radius: 4px;
    font-size: 14px;
}

.schedule-warning {
    color: #ff6b6b;
    font-weight: bold;
    margin-top: 10px;
}

@media screen and (max-width: 1100px) {
    .form-builder-design-option {
        flex-direction: column;
        gap: 20px;
    }
    .form-builder-design-option-information {
        max-width: unset;
        flex-basis: unset;
        min-width: none;
    }
}

.broadcast-analytics-button {
    color: var(--color-brand);
    cursor: pointer;
    font-weight: 500;
    padding: 8px 16px;
    border: 1px solid var(--color-brand);
    border-radius: 4px;
    display: inline-block;
    transition: all 0.2s ease;
    font-size: 14px;
}

.broadcast-analytics-button:hover:not(.sent) {
    background-color: var(--color-brand);
    color: white;
}

.broadcast-analytics-button.sent {
    background-color: transparent;
    border-color: transparent;
    cursor: default;
    opacity: 0.7;
}
