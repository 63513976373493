.authenticated-signup-container {
    width: 100%;
    box-sizing: border-box;
    margin-top: 1rem;
    margin-bottom: 120px;
}

.authenticated-signup-header {
    text-align: left;
    margin-bottom: 1rem;
}

.authenticated-signup-header h2 {
    font-size: 1.5rem;
    margin: 0;
    color: var(--color-white);
}

.authenticated-signup-header p {
    font-size: 1.1rem;
    color: var(--color-grey);
    margin: 0;
}

/* Override some Pricing.css styles for this context */
.authenticated-signup-container .plans-and-pricing-cards {
    margin-bottom: 0;
}

.authenticated-signup-container .plans-and-pricing-card {
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.authenticated-signup-container .plans-and-pricing-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 12px rgba(var(--color-brand-rgb), 0.2);
}

.authenticated-signup-page-container {
    width: 100%;
    max-width: 1400px;
    padding: 0px 2rem;
    margin: 2rem auto;
    box-sizing: border-box;
}

.authenticated-signup-page-header {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1rem;
}

.authenticated-signup-page-header span {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
}

@media screen and (max-width: 800px) {
    .authenticated-signup-container {
        padding: 10px;
    }

    .authenticated-signup-header h2 {
        font-size: 1.75rem;
    }

    .authenticated-signup-header p {
        font-size: 1rem;
    }
}
