.footer {
    width: 100%;
    background-color: var(--color-dark-brand);
    margin-top: 80px;
}

.footer-content {
    max-width: 1000px;
    margin: 0 auto;
    padding: 60px 20px;
}

.footer-navigation {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 40px;
}

.footer-section {
    min-width: 160px;
}

.footer-section h3 {
    color: var(--color-white);
    font-size: 1rem;
    font-weight: 600;
    margin: 0 0 20px 0;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.footer-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer-section li {
    margin-bottom: 12px;
}

.footer-section span {
    color: var(--color-grey);
    font-size: 0.9rem;
    cursor: pointer;
    transition: color 0.2s ease;
}

.footer-section span:hover {
    color: var(--color-white);
}

.footer-section a {
    color: var(--color-grey);
    font-size: 0.9rem;
    text-decoration: none;
    transition: color 0.2s ease;
}

.footer-section a:hover {
    color: var(--color-white);
}

.footer-bottom {
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding: 20px 0;
}

.footer-bottom-content {
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-bottom p {
    font-size: 0.875rem;
    color: var(--color-grey);
    margin: 0;
}

.footer-bottom a {
    color: var(--color-brand);
    text-decoration: none;
    font-weight: 600;
}

.footer-bottom a:hover {
    text-decoration: underline;
}

@media screen and (max-width: 768px) {
    .footer-navigation {
        gap: 30px;
    }

    .footer-section {
        min-width: 140px;
    }
}

@media screen and (max-width: 500px) {
    .footer-content {
        padding: 40px 20px;
    }

    .footer-bottom-content {
        flex-direction: column;
        align-items: center;
        gap: 10px;
        text-align: center;
    }
}