.blog {
    background-color: #fbfffe;
}

.blog-post-page-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #fbfffe;
    padding-top: 40px;
    box-sizing: border-box;
}

.blog-post-left-sidebar,
.blog-post-right-sidebar {
    width: 360px;
    padding-top: 0;
    box-sizing: border-box;
}

.blog-post-right-sidebar {
    padding-right: 20px;
}

.blog-post-left-sidebar {
    padding-left: 20px;
    position: sticky;
    top: 20px;
    height: fit-content;
}

.blog-post-header-navigation {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-direction: row;
    margin-bottom: 1rem;
}

.blog-post-header-navigation span {
    color: var(--color-brand);
    cursor: pointer;
}

.blog-sidebar-about {
    color: var(--color-dark);
}

.blog-sidebar-about div {
    font-weight: 600;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.blog-sidebar-about p {
    line-height: 1.5;
    margin: 0;
    font-size: 14px;
    color: rgb(121, 121, 121);
    margin-bottom: 0.5rem;
}

.blog-sidebar-about p a {
    color: var(--color-brand);
}

@media screen and (max-width: 1520px) {
    .blog-post-left-sidebar {
        display: none;
    }
}

@media screen and (max-width: 1160px) {
    .blog-post-right-sidebar {
        display: none;
    }
}

.blog-landing-heading {
    width: 100%;
    box-sizing: border-box;
}

.blog-landing-heading-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 1rem;
    box-sizing: border-box;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 1px solid var(--color-grey);
}

.blog-landing-heading-inner h1 {
    font-size: 2rem;
    margin: 0;
    font-weight: 800;
    color: var(--color-dark);
    text-align: center;
}

.blog-landing-heading-inner p {
    font-size: 1rem;
    margin: 0;
    margin-top: 1.5rem;
    color: var(--color-grey);
    font-weight: 400;
}

.blog-landing-header-navs {
    width: 100%;
    margin-top: 3rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.7rem;
    display: none;
}

.blog-landing-header-navs span {
    font-weight: 600;
    font-size: 0.9rem;;
    color: var(--color-grey);
    cursor: pointer;
}

.blog-landing-header-navs span.active {
    color: var(--color-brand);
}

.blog-landing-list {
    max-width: 1200px;
    width: 100%;
    margin: 2rem auto;
    padding: 0 1rem;
    box-sizing: border-box;
}

.blog-landing-top-row {
    display: flex;
    gap: 2rem;
    width: 100%;
    box-sizing: border-box;
}

.blog-landing-top-row .featured-left {
    flex: 0 0 calc(50% - 1rem);
    margin: 0;
}

.blog-landing-right-column {
    flex: 0 0 calc(50% - 1rem);
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: flex-start;
}

.blog-landing-right-column .featured-right {
    width: 100%;
    margin: 0;
}

.blog-landing-grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 0;
    margin-bottom: 0;
}

.blog-landing-list-content {
    background: white;
    overflow: hidden;
    transition: transform 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
    border: none;
    position: relative;
    grid-column: span 3;
    aspect-ratio: 1;
}

/* First item takes full width */
.blog-landing-list-content:first-child {
    grid-column: 1 / -1;
    aspect-ratio: 21/9;
}

/* Second and third items take half width */
.blog-landing-list-content:nth-child(2),
.blog-landing-list-content:nth-child(3) {
    grid-column: span 6;
    aspect-ratio: 16/9;
}

/* Every 5th item (after the first 3) spans 6 columns */
.blog-landing-list-content:nth-child(5n+4) {
    grid-column: span 6;
    aspect-ratio: 16/9;
}

.blog-landing-list-content-image {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.blog-landing-list-content-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.blog-landing-list-content-text {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 2rem 1.5rem 1.5rem;
    background: linear-gradient(to top, 
        rgba(0,0,0,0.9) 0%,
        rgba(0,0,0,0.8) 40%,
        rgba(0,0,0,0.4) 80%,
        rgba(0,0,0,0) 100%
    );
    color: white;
}

.blog-landing-list-content-categories {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    margin-bottom: 0.5rem;
}

.blog-landing-list-content-categories span {
    font-size: 0.7rem;
    color: white;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 0.2rem;
    background-color: var(--color-brand);
    padding: 0.2rem 0.5rem;
    border-radius: 20px;
}

.blog-landing-list-content-title {
    text-decoration: none;
    margin-bottom: 0.5rem;
}

.blog-landing-list-content-title h2 {
    margin: 0;
    color: white;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.3;
}

.blog-landing-list-content:nth-child(2) .blog-landing-list-content-title h2,
.blog-landing-list-content:nth-child(3) .blog-landing-list-content-title h2,
.blog-landing-list-content:nth-child(5n+4) .blog-landing-list-content-title h2 {
    font-size: 1.4rem;
}

.blog-landing-list-content-date {
    font-size: 0.8rem;
    color: rgba(255,255,255,0.7);
    margin-bottom: 0.5rem;
}

.blog-landing-list-content-tagline {
    font-size: 0.9rem;
    color: rgba(255,255,255,0.9);
    line-height: 1.4;
    margin-bottom: 0.5rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.blog-landing-list-content-cta {
    display: none;
}

@media screen and (max-width: 1200px) {
    .blog-landing-grid {
        grid-template-columns: repeat(6, 1fr);
    }

    .blog-landing-list-content {
        grid-column: span 2;
    }

    .blog-landing-list-content:first-child {
        grid-column: 1 / -1;
    }

    .blog-landing-list-content:nth-child(2),
    .blog-landing-list-content:nth-child(3) {
        grid-column: span 3;
    }

    .blog-landing-list-content:nth-child(5n+4) {
        grid-column: span 4;
    }
}

@media screen and (max-width: 768px) {
    .blog-landing-grid {
        grid-template-columns: 1fr;
        gap: 0.5rem;
    }

    .blog-landing-list-content,
    .blog-landing-list-content:first-child,
    .blog-landing-list-content:nth-child(2),
    .blog-landing-list-content:nth-child(3),
    .blog-landing-list-content:nth-child(5n+4) {
        grid-column: 1 / -1;
        aspect-ratio: 16/9;
        width: 100%;
    }

    .blog-landing-list-content-title h2 {
        font-size: 1.2rem;
    }

    .blog-landing-list-content:first-child .blog-landing-list-content-title h2 {
        font-size: 1.4rem;
    }

    .blog-landing-list-content:nth-child(2) .blog-landing-list-content-title h2,
    .blog-landing-list-content:nth-child(3) .blog-landing-list-content-title h2,
    .blog-landing-list-content:nth-child(5n+4) .blog-landing-list-content-title h2 {
        font-size: 1.1rem;
    }

    .blog-landing-list {
        padding: 0 1rem;
    }

    .blog-landing-list-content-tagline {
        display: none;
    }
}

.blog-post {
    flex-grow: 1;
    padding: 20px;
    box-sizing: border-box;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.blog-post h1 {
    font-size: 32px;
    color: var(--color-dark);
    font-weight: 800;
    margin: 0;
    margin-bottom: 4px;
    line-height: 1.2;
}

.blog-post-tagline {
    font-size: 1.1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-weight: 500;
    line-height: 1.5;
    color: var(--color-grey);
}

.blog-post-image {
    display: none;
    width: 100%;
    box-sizing: border-box;
}

.blog-post-image img {
    max-width: 100%;
    box-sizing: border-box;
}

.blog-post-content {
    width: 100%;
    box-sizing: border-box;
    color: rgb(42, 53, 64);
    color: var(--color-white-pure);
    border-radius: 2px;
    margin-bottom: 40px;
}

.blog-post-content::marker {
    color: var(--color-dark);
}

.blog-post-content table {
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    margin-top: 2rem;
    font-size: 14px;
    display: block;
    overflow-x: auto;
}

.blog-post-content table p {
    font-size: 13px;
    margin-top: 0;
    line-height: 1.2;
    margin-bottom: 6px;
    max-width: 100%;
    overflow-wrap: break-word;
}

.blog-post-content table p b {
    font-weight: 900;
}

.blog-post-content td {
    border: 1px solid var(--color-grey);
    padding: 12px 8px;
    box-sizing: border-box;
    text-align: left;
    max-width: 300px;
    overflow-wrap: break-word;
}

.blog-post-content tr:first-child td {
    background-color: rgba(87, 87, 87, 0.405);
    color: var(--color-dark);
}

.blog-post-content tr:first-child td p {
    margin: 0;
    font-size: 11px;
    letter-spacing: 0.02rem;
    text-transform: uppercase;
}

@media screen and (max-width: 800px) {
    .blog-post-content table {
        font-size: 9px;
        width: 100%;
    }

    .blog-post-content tr:first-child td p {
        font-size: 9px;
    }

    .blog-post-content table p {
        font-size: 9px;
        margin-top: 0;
        line-height: 1.2;
        margin-bottom: 6px;
    }
}

.blog-post-content td {
    padding: 1rem;
}

.blog-post-content h2,
.blog-post-content h2 b {
    font-size: 24px;
    margin: 0;
    font-weight: 700;
    margin-top: 4rem;
    margin-bottom: 1rem;
    line-height: 1.2;
    letter-spacing: -0.02em;
    color: var(--color-dark);
    padding-bottom: 0.2rem;
}

.blog-post-content h2 {
    border-bottom: 1px solid grey;
}

.blog-post-content li::marker {
  color: var(--color-brand);
}

.blog-post-content h3 {
    font-size: 20px;
    margin: 0;
    font-weight: 600;
    margin-top: 2rem;
    margin-bottom: 1rem;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: var(--color-dark);
    padding-left: 0.6rem;
    border-left: 4px solid var(--color-brand);
}

.blog-post-content p:first-of-type {
    margin-top: 0;
}

.blog-post-content p {
    overflow-wrap: break-word;
    color: var(--color-dark);
    box-sizing: border-box;
    margin-left: 0px;
    margin-right: 0px;
    font-size: 16px;
    line-height: 32px;
    font-weight: 400;
}

.blog-post-content hr {
    margin-top: 2.5rem;
}

.blog-post-content p a {
    color: var(--color-brand);
}

.blog-post-content img {
    width: 100%;
    margin-top: 18px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    cursor: pointer;
    border-radius: 4px;
}

.blog-post-content li p,
.blog-post-content ul li p:first-of-type {
    margin-top: 0.5em;
    line-height: 1.5;
}

.blog-post-metas {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    gap: 1.5rem;
    box-sizing: border-box;
    color: var(--color-grey);
    padding-bottom: 1.5rem;
    border-bottom: 1px solid var(--color-grey);
}

.blog-post-meta-container {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.blog-post-meta-container label {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
}

.blog-post-meta-container p {
    text-transform: uppercase;
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    color: var(--color-brand);
}

.blog-post-footer {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    gap: 80px;
}

.blog-post-footer-cta {
    padding: 32px;
    width: 100%;
    background-color: var(--color-brand-opacity);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    box-sizing: border-box;
}

.blog-post-footer-cta p {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    color: var(--color-dark);
    text-align: center;
}

.blog-post-footer-cta div {
    text-align: center;
    border: none;
    font-size: 16px;
    font-weight: 500;
    color: var(--color-brand);
    cursor: pointer;
    transition: all 0.3;
}

.blog-post-footer-cta div:hover {
    text-decoration: underline;
}

@media screen and (min-width: 1000px) {
    .blog-landing-heading-inner h1 {
        text-align: center;
        font-size: 52px;
        line-height: 1.1;
        margin-top: 20px;
        margin-bottom: 8px;
    }

    .blog-landing-heading-inner p {
        font-size: 1.1rem;
    }

    .blog-landing-list {
        width: 100%;
        max-width: 1200px;
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
    }

    .blog-post-image {
        display: block;
        margin-top: 0px;
        margin-bottom: 2rem;
        margin-top: 2rem;
    }

    .blog-post-content p {
        font-size: 17px;
        line-height: 32px;
    }

    .blog-post-meta-container {
        flex-grow: unset;
    }

    .blog-post-content h3,
    .blog-post-content h3 b {
        font-size: 20px;
    }
}

@media screen and (max-width: 1200px) {
    .blog-landing-list-content {
        width: unset;
        min-width: unset;
        max-width: unset;
        flex-basis: 45%;
    }

    .blog-landing-list {
        padding: 0 0;
    }
}

@media screen and (max-width: 840px) {
    .blog-landing-header-navs {
        display: none;
    }

    .blog-post h1 {
        font-size: 24px;
        width: 100%;
        box-sizing: border-box;
    }

    .blog-post-tagline {
        font-size: 14px;
    }

    .blog-post-meta-container label {
        font-size: 11px;
    }
    
    .blog-post-meta-container p {
        font-size: 11px;
    }

    .blog-post-meta-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .blog-post-content h2,
    .blog-post-content h2 b {
        font-size: 22px;
    }

    .blog-post-content p {
        font-size: 15px;
        line-height: 1.6;
    }

    .blog-post-content ul li p {
        line-height: 2;
    }

    .blog-post-content h3, .blog-post-content h3 b {
        font-size: 20px;
    }

    .blog-landing-list {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: stretch;
        width: 100%;
        margin-top: 2rem;
        padding: 0 20px;
      }
}

.blog-post-content h3 + p,
.blog-post-content h2 + p {
    margin-top: 0.5rem !important;
}

.blog-landing-load-more {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 20px 0 60px;
}

.blog-landing-load-more-button {
    background-color: transparent;
    border: 2px solid var(--color-brand);
    color: var(--color-brand);
    padding: 12px 24px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    border-radius: 4px;
}

.blog-landing-load-more-button:hover {
    background-color: var(--color-brand);
    color: var(--color-dark);
}

.blog-landing-load-more-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

@media screen and (max-width: 768px) {
    .blog-landing-load-more-button {
        width: 100%;
        max-width: 300px;
    }
}

.blog-post-toc {
    padding: 20px;
    padding-right: 4px;
    border-radius: 4px;
    margin: 20px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.blog-post-toc h3 {
    margin: 0 0 0 0 !important;
    font-size: 14px !important;
    font-weight: 600;
    color: var(--color-dark);
    margin-bottom: 1rem !important;
    padding-left: 8px;
}

.blog-post-toc ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.blog-post-toc ul li {
    margin-bottom: 0.25rem;
}

.blog-post-toc a {
    text-decoration: none;
    font-size: 14px;
    line-height: 1.4;
    display: block;
    padding: 4px 8px;
    color: var(--color-dark);
}

.blog-post-toc a:hover {
    color: var(--color-brand);
}

.blog-post-toc a.active {
    color: var(--color-brand);
    position: relative;
    background-color: var(--color-brand-opacity);
    border-radius: 8px;
}

@media screen and (max-width: 768px) {
    .blog-post-toc ul {
        columns: 1;
    }
}

html {
    scroll-behavior: smooth;
}

.blog-post-content h2 {
    scroll-margin-top: 20px;
}

.back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: var(--color-brand);
    color: var(--color-dark);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 1000;
}

.back-to-top:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    background-color: var(--color-dark);
    color: var(--color-brand);
}

@media screen and (max-width: 768px) {
    .back-to-top {
        bottom: 16px;
        right: 16px;
        width: 36px;
        height: 36px;
    }
}

.blog-post-social {
    margin: 40px 0;
    padding: 20px;
    border-top: 1px solid var(--color-grey);
    border-bottom: 1px solid var(--color-grey);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.blog-post-social-label {
    font-size: 14px;
    font-weight: 600;
    color: var(--color-dark);
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

.blog-post-social-links {
    display: flex;
    gap: 16px;
    align-items: center;
}

.blog-post-social-link {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--color-dark-brand);
    color: var(--color-brand);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    border: 1px solid var(--color-brand);
}

.blog-post-social-link:hover {
    background-color: var(--color-brand);
    color: var(--color-dark);
    transform: translateY(-2px);
}

@media screen and (max-width: 768px) {
    .blog-post-social {
        margin: 32px 0;
        padding: 16px;
    }
    
    .blog-post-social-link {
        width: 36px;
        height: 36px;
    }
}

.blog-post-recent {
    margin: 40px 0;
    padding: 20px 0;
}

.blog-post-recent h3 {
    font-size: 24px !important;
    font-weight: 700 !important;
    color: var(--color-dark);
    margin: 0 0 24px 0 !important;
}

.blog-post-recent-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
}

.blog-post-recent-item {
    display: flex;
    flex-direction: column;
}

.blog-post-recent-image {
    width: 100%;
    aspect-ratio: 16/9;
    overflow: hidden;
    border-radius: 4px;
    margin-bottom: 12px;
}

.blog-post-recent-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.2s ease;
}

.blog-post-recent-image:hover img {
    transform: scale(1.05);
}

.blog-post-recent-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.blog-post-recent-categories {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.blog-post-recent-categories span {
    font-size: 12px;
    color: var(--color-brand);
    font-weight: 600;
}

.blog-post-recent-title {
    text-decoration: none;
    color: inherit;
}

.blog-post-recent-title:hover {
    text-decoration: underline;
}

.blog-post-recent-title h4 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.4;
    color: var(--color-dark);
}

@media screen and (max-width: 768px) {
    .blog-post-recent-grid {
        grid-template-columns: 1fr;
        gap: 32px;
    }

    .blog-post-recent-image {
        aspect-ratio: 2/1;
    }
}

.blog-landing-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 2rem 0;
    padding: 1rem;
}

.blog-landing-pagination-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.25rem;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: white;
    color: #333;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.blog-landing-pagination-button:hover:not(:disabled) {
    background-color: #f5f5f5;
    border-color: #d0d0d0;
}

.blog-landing-pagination-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.blog-landing-pagination-info {
    font-size: 1rem;
    color: #666;
    min-width: 150px;
    text-align: center;
}

.blog-landing-search {
    width: 100%;
    margin: 2rem 0 1rem;
    padding: 0;
    box-sizing: border-box;
}

.blog-landing-search-row {
    display: flex;
    gap: 1rem;
    align-items: stretch;
    width: 100%;
}

.blog-landing-search-input-wrapper {
    position: relative;
    flex: 1;
    box-sizing: border-box;
}

.blog-landing-search-navigation {
    display: flex;
    gap: 0.5rem;
}

.blog-landing-search-nav-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.5rem;
    border: 2px solid var(--color-grey);
    border-radius: 12px;
    background: white;
    color: var(--color-dark);
    font-size: 1.1rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.blog-landing-search-nav-button:hover:not(:disabled) {
    border-color: var(--color-brand);
    color: var(--color-brand);
}

.blog-landing-search-nav-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

@media screen and (max-width: 768px) {
    .blog-landing-search-row {
        gap: 0.5rem;
    }

    .blog-landing-search-nav-button {
        padding: 0 1rem;
    }

    .blog-landing-search {
        width: 90%;
    }
}

/* Add new styles for search results */
.blog-landing-search-results {
    margin: 2rem 0;
    padding: 0;
    background: white;
    border-radius: 12px;
    border: 1px solid var(--color-grey);
}

.blog-landing-search-results-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    border-bottom: 1px solid var(--color-grey);
    font-size: 0.9rem;
    color: var(--color-grey);
}

.blog-landing-search-results-header-left {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.blog-landing-search-clear {
    background: none;
    border: none;
    color: var(--color-brand);
    font-size: 0.9rem;
    padding: 0.4rem 0.8rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border-radius: 4px;
    transition: all 0.2s ease;
}

.blog-landing-search-clear:hover {
    background-color: var(--color-brand-opacity);
}

@media screen and (max-width: 768px) {
    .blog-landing-search-clear {
        font-size: 0.8rem;
        padding: 0.3rem 0.6rem;
    }
}

.blog-landing-search-pagination {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.blog-landing-search-pagination-button {
    background: none;
    border: none;
    color: var(--color-dark);
    cursor: pointer;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
}

.blog-landing-search-pagination-button:disabled {
    opacity: 0.3;
    cursor: not-allowed;
}

.blog-landing-search-pagination-button:not(:disabled):hover {
    color: var(--color-brand);
}

.blog-landing-search-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 0.9rem;
}

.blog-landing-search-table tr {
    border-bottom: 1px solid var(--color-grey);
}

.blog-landing-search-table tr:last-child {
    border-bottom: none;
}

.blog-landing-search-table td {
    padding: 1rem 1.5rem;
    color: var(--color-dark);
}

.blog-landing-search-table td:first-child {
    width: 50%;
}

.blog-landing-search-table td:nth-child(2) {
    width: 25%;
    color: var(--color-grey);
}

.blog-landing-search-table td:last-child {
    width: 25%;
    color: var(--color-brand);
}

.blog-landing-search-table a {
    color: var(--color-dark);
    text-decoration: none;
    transition: color 0.2s ease;
}

.blog-landing-search-table a:hover {
    color: var(--color-brand);
}

@media screen and (max-width: 768px) {
    .blog-landing-search-table {
        font-size: 0.8rem;
    }

    .blog-landing-search-table td {
        padding: 0.8rem 1rem;
    }

    .blog-landing-search-table td:nth-child(2),
    .blog-landing-search-table td:last-child {
        display: none;
    }

    .blog-landing-search-table td:first-child {
        width: 100%;
    }

    .blog-landing-search-results-header {
        padding: 0.8rem 1rem;
        font-size: 0.8rem;
    }
}

.blog-landing-search-empty {
    text-align: center;
    padding: 4rem 1rem;
    background: white;
    border-radius: 12px;
    border: 1px solid var(--color-grey);
}

.blog-landing-search-empty h3 {
    font-size: 1.5rem;
    color: var(--color-dark);
    margin-bottom: 1rem;
    font-weight: 600;
}

.blog-landing-search-empty p {
    color: var(--color-dark);
    margin-bottom: 2rem;
    font-size: 1rem;
}

.blog-landing-search-actions {
    display: flex;
    gap: 2rem;
    justify-content: center;
}

.blog-landing-search-action-link {
    color: var(--color-brand);
    text-decoration: none;
    font-size: 0.95rem;
    font-weight: 500;
    position: relative;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
}

.blog-landing-search-action-link::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -2px;
    left: 0;
    background-color: var(--color-brand);
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease;
}

.blog-landing-search-action-link:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.blog-landing-sections {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    margin-bottom: 2rem;
    width: 100%;
    box-sizing: border-box;
}

.blog-landing-section {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    box-sizing: border-box;
}

.blog-landing-top-row {
    display: flex;
    gap: 2rem;
    width: 100%;
    box-sizing: border-box;
}

.blog-landing-featured-post {
    flex: 0 0 calc(70% - 1rem);
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    aspect-ratio: 16/9;
}

.blog-landing-featured-post .blog-landing-list-content-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.blog-landing-featured-post .blog-landing-list-content-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.blog-landing-featured-post .blog-landing-list-content-text {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 2rem;
    background: linear-gradient(
        to top,
        rgba(0, 0, 0, 0.9) 0%,
        rgba(0, 0, 0, 0.8) 40%,
        rgba(0, 0, 0, 0.4) 80%,
        rgba(0, 0, 0, 0) 100%
    );
    color: white;
}

.blog-landing-side-posts {
    flex: 0 0 calc(30% - 1rem);
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.blog-landing-side-post {
    background: transparent;
    border: none;
    padding: 0;
}

.blog-landing-side-post-categories {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 0.1rem;
}

.blog-landing-side-post-categories span {
    font-size: 0.7rem;
    color: var(--color-brand);
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 0.2rem;
}

.blog-landing-side-post-title {
    text-decoration: none;
    display: block;
    margin-bottom: 0.75rem;
}

.blog-landing-side-post-title h3 {
    margin: 0;
    font-size: 1.4rem;
    font-weight: 700;
    color: var(--color-dark);
    line-height: 1.4;
}

.blog-landing-side-post-date {
    font-size: 0.8rem;
    color: var(--color-grey);
    margin-bottom: 0.75rem;
}

.blog-landing-side-post-tagline {
    font-size: 0.9rem;
    color: var(--color-grey);
    line-height: 1.4;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.blog-landing-bottom-row {
    display: flex;
    gap: 2rem;
    width: 100%;
    box-sizing: border-box;
}

.blog-landing-bottom-row .blog-landing-list-content {
    flex: 0 0 calc(50% - 1rem);
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    aspect-ratio: 16/9;
}

.blog-landing-partial-section {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    width: 100%;
    box-sizing: border-box;
}

.blog-landing-partial-section .blog-landing-list-content {
    flex: 0 0 calc(50% - 1rem);
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    aspect-ratio: 16/9;
}

@media screen and (max-width: 768px) {
    .blog-landing-top-row {
        flex-direction: column;
    }

    .blog-landing-featured-post,
    .blog-landing-side-posts {
        flex: 0 0 100%;
    }

    .blog-landing-bottom-row {
        flex-direction: column;
    }

    .blog-landing-bottom-row .blog-landing-list-content,
    .blog-landing-partial-section .blog-landing-list-content {
        flex: 0 0 100%;
    }

    .blog-landing-sections {
        gap: 2rem;
    }

    .blog-landing-section {
        gap: 1.5rem;
    }
}

.blog-landing-search-icon {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: var(--color-brand);
    font-size: 1.2rem;
    pointer-events: none;
    z-index: 1;
}

.blog-landing-search-input {
    width: 100%;
    padding: 1rem 1rem 1rem 3rem;
    border: 2px solid var(--color-grey);
    border-radius: 12px;
    font-size: 1.1rem;
    color: var(--color-dark);
    background: white;
    transition: all 0.3s ease;
    box-sizing: border-box;
}

.blog-landing-search-input:focus {
    outline: none;
    border-color: var(--color-brand);
    box-shadow: 0 0 0 3px var(--color-brand-opacity);
}