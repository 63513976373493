.form-builder-analytics-container {
    padding: 20px;
    height: 100%;
    overflow-y: auto;
}

/* Webkit browsers (Chrome, Safari, newer versions of Edge) */
.form-builder-analytics-container::-webkit-scrollbar {
    width: 8px;
}

.form-builder-analytics-container::-webkit-scrollbar-track {
    background: transparent;
}

.form-builder-analytics-container::-webkit-scrollbar-thumb {
    background-color: var(--color-grey);
    border-radius: 4px;
}

.form-builder-analytics-container::-webkit-scrollbar-thumb:hover {
    background-color: var(--color-light-grey);
}

/* Firefox */
.form-builder-analytics-container {
    scrollbar-width: thin;
    scrollbar-color: var(--color-grey) transparent;
}

.form-builder-analytics-message {
    text-align: center;
    font-size: 18px;
    color: var(--color-white);
    max-width: 800px;
    line-height: 1.5;
}

.form-builder-analytics-upgrade {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin-top: 4rem;
    gap: 1rem;
}

.form-builder-analytics-upgrade h3 {
    font-size: 1.5rem;
    margin: 0;
}

.form-builder-analytics-upgrade-button {
    color: var(--color-white);
    background-color: var(--color-brand);
    padding: 0.5rem 2rem;
    cursor: pointer;
    border-radius: 4px;
}

.form-builder-analytics-header-metrics {
    display: flex;
    gap: 2rem;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 1rem;
    margin-bottom: 1rem;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
}

.form-builder-analytics-metric {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: space-between;
    background-color: var(--color-dark-brand-opacity);
    padding: 0.8rem;
    padding-right: 3rem;
    box-sizing: border-box;
    border-radius: 8px;
    width: 15%;
    min-width: 140px;
}

.form-builder-analytics-metric-number {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--color-white-pure);
}

.form-builder-analytics-metric-label {
    font-size: 0.8rem;
    color: var(--color-grey);
    opacity: 0.7;
}

.form-builder-analytics-chart-box {
    box-sizing: border-box;
    width: 100%;
}

.form-builder-analytics-box-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid var(--color-grey);
    padding-bottom: 0.5rem;
}

.form-builder-analytics-box-subheading {
    font-size: 13px;
    color: var(--color-grey);
    margin-bottom: 0.5rem;
}

.form-builder-analytics-box h3 {
    font-size: 1.2rem;
    margin: 0;
    font-weight: 400;
    border-left: 4px solid var(--color-brand);
    padding-left: 0.4rem;
}

.form-builder-analytics-key-findings {
    margin-bottom: 20px;
    width: 100%;
}

.form-builder-analytics-findings-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    margin-top: 1rem;
    width: 100%;
}

.form-builder-analytics-finding-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-basis: 48%;
    font-size: 1rem;
    line-height: 1.6;
    background-color: var(--color-extra-dark);
    box-sizing: border-box;
    padding: 1rem;
    padding-left: 1.5rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
}

.form-builder-analytics-finding-item.form-builder-analytics-finding-item-rec {
    background-color: #1e1e1e;
}

.form-builder-analytics-finding-headline {
    font-weight: 600;
    font-size: 14px;
    color: var(--color-white);
    margin-bottom: 0px;
}

.form-builder-analytics-finding-headline::first-letter {
    text-transform: uppercase;
}

.form-builder-analytics-finding-summary {
    font-weight: 400;
    font-size: 13px;
    color: var(--color-grey);
    max-width: 800px;
}

.form-builder-analytics-download-button {
    background-color: var(--color-dark);
    color: var(--color-white);
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.form-builder-analytics-download-button:hover {
    background-color: var(--color-background-light);
}

.form-builder-analytics-padding {
    height: 80px;
}

.form-analytics-split-container {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    gap: 2rem;
    margin-top: 2rem;
}

.form-analytics-split-container-column-large {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.form-analytics-split-container-column-small {
    max-width: 420px;
    min-width: 420px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.form-builder-analytics-key-value-strings {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-bottom: 0.5rem;
    font-size: 14px;
}

.form-builder-analytics-key-value-strings div {
    font-weight: 500;
}

@media screen and (max-width: 1200px) {
    .form-analytics-split-container-column-large {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        gap: 1rem;
    }
}

@media (max-width: 1000px) {
    .form-builder-analytics-findings-list {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: stretch;
        gap: 1rem;
        margin-top: 1rem;
        width: 100%;
    }
    .form-builder-analytics-finding-item {
        min-width: unset;
        width: 100%;
    }
}

@media screen and (max-width: 940px) {
    .form-analytics-split-container {
        flex-direction: column;
        width: 100%;
    }

    .form-analytics-split-container-column-large {
        width: 100%;
    }

    .form-analytics-split-container-column-small {
        flex-direction: row;
        width: 100%;
        box-sizing: border-box;
        max-width: unset;
        min-width: unset;
    }

    .form-analytics-split-container-column-small .form-builder-analytics-box {
        flex-basis: 50%;
    }
}

@media (max-width: 768px) {
    .form-builder-analytics-header-metrics {
        justify-content: flex-start;
        gap: 1rem;
    }

    .form-builder-analytics-metric {
        min-width: unset;
        width: 100%;
        flex-direction: row-reverse;
        align-items: center;
        background-color: transparent;
        padding: 0;
    }
    .form-builder-analytics-metric-number {
        font-size: 1.2rem;
    }
    .form-builder-analytics-metric-label {
        font-size: 0.7rem;
    }
    .form-builder-analytics-chart-box {
        width: 100%;
    }

    .form-analytics-split-container-column-small {
        flex-direction: column;
    }
}
