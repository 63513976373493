.about-us-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about-us-hero {
    width: 100%;
    background-color: var(--color-dark-brand);
    padding: 120px 20px 80px;
    text-align: center;
    box-sizing: border-box;
}

.about-us-hero-content {
    max-width: 800px;
    margin: 0 auto;
}

.about-us-hero h1 {
    font-size: 3rem;
    font-weight: 800;
    margin-bottom: 1.5rem;
    line-height: 1.2;
}

.about-us-hero p {
    font-size: 1.2rem;
    color: var(--color-grey);
    line-height: 1.6;
}

.about-us-mission {
    padding: 80px 20px;
    background-color: var(--color-white);
    width: 100%;
    box-sizing: border-box;
}

.about-us-mission-content {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
}

.about-us-mission h2 {
    color: var(--color-dark);
    font-size: 2rem;
    margin-bottom: 1.5rem;
}

.about-us-mission p {
    color: var(--color-dark);
    font-size: 1.1rem;
    max-width: 800px;
    margin: 0 auto 3rem;
    line-height: 1.6;
}

.about-us-values {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin-top: 3rem;
}

.about-us-value-card {
    padding: 2rem;
    background-color: var(--color-extra-dark);
    border-radius: 12px;
    text-align: center;
}

.about-us-value-icon {
    font-size: 2rem;
    color: var(--color-brand);
    margin-bottom: 1rem;
}

.about-us-value-card h3 {
    color: var(--color-white);
    margin-bottom: 1rem;
    font-size: 1.2rem;
}

.about-us-value-card p {
    color: var(--color-grey);
    font-size: 1rem;
    margin: 0;
}

.about-us-story {
    padding: 80px 20px;
    background-color: var(--color-extra-dark);
    width: 100%;
    box-sizing: border-box;
}

.about-us-story-content {
    max-width: 800px;
    margin: 0 auto;
}

.about-us-story h2 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    text-align: center;
}

.about-us-story p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: var(--color-grey);
    margin-bottom: 1.5rem;
}

.about-us-founder {
    padding: 80px 20px;
    background-color: var(--color-white);
    width: 100%;
    box-sizing: border-box;
}

.about-us-founder-content {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 4rem;
}

.about-us-founder-image {
    flex: 0 0 300px;
}

.about-us-founder-image img {
    width: 100%;
    border-radius: 12px;
}

.about-us-founder-text {
    flex: 1;
}

.about-us-founder-text h2 {
    color: var(--color-dark);
    font-size: 2rem;
    margin-bottom: 1rem;
}

.about-us-founder-text h3 {
    color: var(--color-brand);
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

.about-us-founder-text p {
    color: var(--color-dark);
    font-size: 1.1rem;
    line-height: 1.6;
}

.about-us-cta {
    padding: 80px 20px;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
}

.about-us-cta h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.about-us-cta p {
    font-size: 1.1rem;
    color: var(--color-grey);
    margin-bottom: 2rem;
}

.about-us-cta-button {
    background-color: var(--color-brand);
    color: white;
    border: none;
    padding: 1rem 2rem;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.about-us-cta-button:hover {
    background-color: var(--color-brand-dark);
}

@media screen and (max-width: 1000px) {
    .about-us-values {
        grid-template-columns: 1fr;
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
    }

    .about-us-founder-content {
        flex-direction: column;
        text-align: center;
        gap: 2rem;
    }

    .about-us-founder-image {
        flex: 0 0 auto;
        max-width: 250px;
        margin: 0 auto;
    }
}

@media screen and (max-width: 768px) {
    .about-us-hero h1 {
        font-size: 2rem;
    }

    .about-us-hero p,
    .about-us-mission p,
    .about-us-story p,
    .about-us-founder-text p {
        font-size: 1rem;
    }

    .about-us-mission h2,
    .about-us-story h2,
    .about-us-founder-text h2,
    .about-us-cta h2 {
        font-size: 1.5rem;
    }
}
