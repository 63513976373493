.organization-users {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;  
}

.organization-users-main {
    flex-grow: 1;
    text-align: center;
    padding-top: 20vh;
}

.organization-users-main h3 {
    font-size: 1.5rem;
}

.organization-users-main p {
    font-size: 1rem;
    color: var(--color-brand);
}

@media screen and (max-width: 800px) {
    .organization-users-main h3 {
        font-size: 1.1rem;
    }
    
    .organization-users-main p {
        font-size: 0.8rem;
        color: var(--color-brand);
    }
}