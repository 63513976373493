.setup-account {
    width: 100%;
    max-width: 1600px;
    margin: 20px auto;
    padding: 20px;
    box-sizing: border-box;
}

.setup-account-form-container {
    background-color: var(--color-extra-dark);
    padding: 40px;
    border-radius: 14px;
    min-height: 200px;
    box-sizing: border-box;
}

.setup-account-form-progress {
    font-size: 0.9rem;
    margin-bottom: 1rem;
    color: var(--color-grey);
    text-align: center;
}

.setup-account-form-container h2 {
    margin: 0;
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.setup-account-form-container h3 {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 0.9rem;
}

.setup-account-form-buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    margin-top: 3rem;
}

.setup-account-form-buttons button {
    background-color: var(--color-brand);
    color: var(--color-white);
    padding: 12px 20px;
    border-radius: 6px;
    width: 100%;
    max-width: 240px;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    transition: background-color 0.3s ease;
}

.setup-account-form-buttons button:disabled {
    background-color: var(--color-grey);
    cursor: not-allowed;
}

.setup-account-form-buttons span {
    font-size: 0.9rem;
    color: var(--color-grey);
    cursor: pointer;
}

.setup-account-form-buttons span:hover {
    text-decoration: underline;
}

.setup-account-form-group {
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
}
  
.setup-account-form-group label {
    display: block;
    margin-bottom: 1rem;
    color: var(--color-white);
    font-weight: 600;
}
  
.setup-account-form-group input {
    width: 100%;
    box-sizing: border-box;
    padding: 0.75rem;
    border: 1px solid var(--color-white);
    color: var(--color-white);
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
    background-color: transparent;
}
  
.setup-account-form-group input:focus {
    outline: none;
    border-color: var(--color-brand);
}

.setup-account-form-plans {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 2rem;
    margin-bottom: 4rem;
    gap: 1rem;
}

.setup-account-form-plan {
    width: 240px;
    min-width: 240px;
    border: 1px solid var(--color-grey);
    padding: 1.5rem 1.2rem;
    box-sizing: border-box;
    border-radius: 6px;
    cursor: pointer;
}

.setup-account-form-plan-selector {
    width: 28px;
    min-width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 0.2rem;
}

.setup-account-form-plan-selector svg {
    font-size: 20px;
    color: var(--color-grey);
}

.setup-account-form-plan.selected {
    border: 1px solid var(--color-brand);
}

.setup-account-form-plan.selected svg {
    color: var(--color-brand);
}

.setup-account-form-plan h4 {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 800;
    display: flex;
    align-items: flex-end;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid var(--color-grey);
}

.setup-account-form-plan h4 span {
    font-size: 0.8rem;
    font-weight: 400;
    margin-left: 6px;
}

.setup-account-form-plan-info-costs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    margin-top: 0.8rem;
}

.setup-account-form-plan-info-costs span {
    font-size: 1rem;
}

.setup-account-form-plan-info-costs span:last-of-type {
    font-size: 0.8rem;
    color: var(--color-grey);
}

.setup-account-form-features {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.setup-account-form-features span {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.setup-account-form-features span svg {
    min-width: 20px;
    color: var(--color-brand);
}

.billing-toggle-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 4rem;
}

.billing-toggle {
    background-color: var(--color-white);
    border-radius: 4px;
    padding: 0.25rem;
    display: flex;
    align-items: center;
}
  
.toggle-button {
    padding: 0.5rem 1rem;
    border-radius: 4px;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s, color 0.3s;
}

.toggle-button span {
    color: var(--color-brand);
    margin-left: 4px;
    font-size: 12px;
}
  
.toggle-button.active {
    background-color: var(--color-dark);
    color: var(--color-white);
}
  
.toggle-button:focus {
    outline: none;
}

@media screen and (max-width: 800px) {
    .setup-account {
        margin: 40px auto;
    }

    .setup-account-form-container {
        padding: 20px;
    }
}

/* Styles for page 1 */
.setup-account-form-group {
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
}
  
.setup-account-form-group label {
    display: block;
    margin-bottom: 1rem;
    color: var(--color-white);
    font-weight: 600;
}
  
.setup-account-form-group input {
    width: 100%;
    box-sizing: border-box;
    padding: 0.75rem;
    border: 1px solid var(--color-white);
    color: var(--color-white);
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
    background-color: transparent;
}
  
.setup-account-form-group input:focus {
    outline: none;
    border-color: var(--color-brand);
}

.setup-account-form-buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    margin-top: 3rem;
}

.setup-account-form-buttons button {
    background-color: var(--color-brand);
    color: var(--color-white);
    padding: 12px 20px;
    border-radius: 6px;
    width: 100%;
    max-width: 240px;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    transition: background-color 0.3s ease;
}

.setup-account-form-buttons button:disabled {
    background-color: var(--color-grey);
    cursor: not-allowed;
}

.setup-account-form-buttons span {
    font-size: 0.9rem;
    color: var(--color-grey);
    cursor: pointer;
}

.setup-account-form-buttons span:hover {
    text-decoration: underline;
}

/* Overrides for Pricing.css integration */
.setup-account .plans-and-pricing-cards {
    margin-top: 2rem;
}

.setup-account .plans-and-pricing-card {
    cursor: pointer;
}

.setup-account .plans-and-pricing-header {
    margin-bottom: 40px;
}