.create-new-form {
    background-color: var(--color-dark);
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.create-new-form-header {
    padding: 20px 40px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    background-color: var(--color-dark);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.create-new-form-back {
    display: flex;
    align-items: center;
    gap: 12px;
    background: none;
    border: none;
    color: var(--color-white);
    font-size: 1rem;
    cursor: pointer;
    padding: 8px;
    border-radius: 8px;
    transition: background-color 0.2s;
    box-sizing: border-box;
}

.create-new-form-back:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.create-new-form-main {
    display: flex;
    flex: 1;
}

.create-new-form-content {
    flex: 1;
    padding: 32px;
}

.create-new-form-content-header {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 32px;
}

.create-new-form-title {
    font-size: 1.5rem;
    color: var(--color-white);
    margin: 0;
}

.create-new-form-scratch-button {
    background-color: var(--color-brand);
    color: var(--color-white);
    border: none;
    padding: 12px 24px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 0.95rem;
    transition: opacity 0.2s;
    min-height: 44px;
    white-space: nowrap;
    padding: 12px 32px;
}

.create-new-form-scratch-button:hover {
    opacity: 0.9;
}

.create-new-form-templates {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 24px;
}

.create-new-form-template {
    border-radius: 12px;
    padding: 24px;
    cursor: pointer;
    transition: all 0.2s;
    border: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 160px;
}

.create-new-form-template:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    background-color: var(--color-dark-brand);
}

.create-new-form-template h3 {
    margin: 0;
    margin-bottom: 12px;
    color: var(--color-white);
    font-size: 1.2rem;
    font-weight: 500;
}

.create-new-form-template p {
    margin: 0;
    color: var(--color-grey);
    font-size: 0.9rem;
    line-height: 1.5;
    flex: 1;
}

.create-new-form-search {
    position: relative;
    width: 100%;
    max-width: 400px;
}

@media screen and (max-width: 768px) {
    .create-new-form-header {
        padding: 16px;
        flex-wrap: wrap;
        gap: 16px;
    }

    .create-new-form-content {
        padding: 16px;
    }

    .create-new-form-controls {
        flex-direction: column;
        align-items: stretch;
        gap: 12px;
        width: 100%;
    }

    .create-new-form-filter,
    .create-new-form-search {
        width: 100%;
        margin: 0;
    }

    .create-new-form-search {
        max-width: unset;
    }

    .create-new-form-filter-dropdown {
        width: 100%;
    }

    .create-new-form-scratch-button {
        font-size: 0.9rem;
        padding: 8px 16px;
        min-height: 40px;
    }
}

.create-new-form-content-header-left {
    flex: 1;
    min-width: 280px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
}

.create-new-form-search input {
    width: 100%;
    padding: 10px 36px;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background-color: var(--color-extra-dark);
    color: var(--color-white);
    font-size: 0.95rem;
    height: 44px;
}

.create-new-form-search input:focus {
    outline: none;
    border-color: var(--color-brand);
}

.create-new-form-search-icon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--color-grey);
}

.create-new-form-search-clear {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: var(--color-grey);
    cursor: pointer;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 32px;
    height: 32px;
}

.create-new-form-search-clear:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: var(--color-white);
}

.create-new-form-no-results {
    text-align: center;
    padding: 40px;
    color: var(--color-grey);
}

.create-new-form-reset-search {
    background: none;
    border: none;
    color: var(--color-brand);
    cursor: pointer;
    padding: 8px 16px;
    margin-top: 12px;
    border-radius: 4px;
    transition: background-color 0.2s;
}

.create-new-form-reset-search:hover {
    background-color: rgba(255, 255, 255, 0.05);
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .create-new-form-templates {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }

    .create-new-form-content {
        padding: 24px;
    }

    .create-new-form-controls {
        gap: 12px;
    }
}

.create-new-form,
.create-new-form * {
    box-sizing: border-box;
}

.create-new-form-content-header,
.create-new-form-content-header-left,
.create-new-form-search,
.create-new-form-scratch-button {
    transition: all 0.3s ease;
}

.create-new-form-controls {
    display: flex;
    gap: 16px;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

.create-new-form-filter {
    position: relative;
}

.create-new-form-filter-button {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: var(--color-extra-dark);
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: var(--color-white);
    padding: 10px 16px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 0.95rem;
    height: 44px;
    transition: all 0.2s;
}

.create-new-form-filter-button:hover {
    background-color: rgba(255, 255, 255, 0.05);
}

.create-new-form-filter-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 8px;
    background-color: var(--color-extra-dark);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 8px;
    min-width: 240px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}

.create-new-form-category {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px 16px;
    color: var(--color-grey);
    cursor: pointer;
    border-radius: 8px;
    transition: all 0.2s;
    font-size: 0.95rem;
}

.create-new-form-filter-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
}
