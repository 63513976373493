.form-builder-outline-container {
    padding: 1rem;
    height: 100%;
    overflow-y: auto;
    /* Firefox scrollbar */
    scrollbar-width: thin;
    scrollbar-color: var(--color-grey) transparent;
    background-color: var(--color-dark);
}

.form-builder-outline-heading {
    font-size: 14px;
    font-weight: 600;
    margin-left: 8px;
    padding-bottom: 8px;
    color: var(--color-white);
}

/* Webkit scrollbar styles */
.form-builder-outline-container::-webkit-scrollbar {
    width: 8px;
}

.form-builder-outline-container::-webkit-scrollbar-track {
    background: transparent;
}

.form-builder-outline-container::-webkit-scrollbar-thumb {
    background-color: var(--color-grey);
    border-radius: 4px;
    border: 2px solid transparent;
    background-clip: content-box;
}

.form-builder-outline-container::-webkit-scrollbar-thumb:hover {
    background-color: var(--color-brand);
    border: 2px solid transparent;
    background-clip: content-box;
}

.form-builder-outline-empty {
    padding: 1rem;
    color: var(--color-grey);
    font-size: 12px;
    text-align: center;
}

.form-builder-outline-item {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 8px;
    cursor: pointer;
    border-radius: 4px;
    transition: all 0.2s ease;
}

.form-builder-outline-item:hover {
    background-color: var(--color-dark);
}

.form-builder-outline-item-icon {
    flex-shrink: 0;
    font-size: 14px;
    color: var(--color-brand-complement);
}

.form-builder-outline-item-text {
    font-size: 12px;
    color: var(--color-white);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.form-builder-outline-tooltip {
    position: fixed;
    width: 280px;
    background-color: var(--color-dark);
    border: 1px solid var(--color-border-dark);
    border-radius: 4px;
    padding: 1rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    pointer-events: none;
}

.form-builder-outline-tooltip h4 {
    margin: 0 0 8px 0;
    font-size: 14px;
    font-weight: 600;
    color: var(--color-white);
}

.form-builder-outline-tooltip-description {
    margin: 0 0 12px 0;
    font-size: 12px;
    color: var(--color-grey);
}

.form-builder-outline-tooltip-details {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-size: 12px;
    color: var(--color-grey);
}

.form-builder-outline-tooltip-options ul {
    margin: 4px 0 0 0;
    padding-left: 20px;
    list-style-type: disc;
}

.form-builder-outline-tooltip-options li {
    margin-bottom: 2px;
}