.add-question-floating-menu-item {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.add-question-floating-menu-item:hover {
    background-color: var(--color-white);
}

.add-question-floating-menu-icon {
    margin-right: 10px;
    font-size: 18px;
    color: var(--color-brand);
}

.add-question-floating-menu-label {
    color: var(--color-dark);  /* Changed from var(--color-text-primary) to var(--color-dark) */
    font-size: 14px;
}

.add-question-floating-menu-header {
    font-size: 14px;
    text-align: center;
    color: var(--color-dark);
    padding: 8px 0px;
    border-bottom: 1px solid var(--color-white);
    font-weight: 600;
}

.add-question-floating-menu {
    background-color: white;
    border: 1px solid var(--color-white);
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    min-width: 200px;
}

.add-question-floating-menu-item {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px;
    cursor: pointer;
}

.add-question-floating-menu-item:hover {
    background-color: #f0f0f0;
}

.add-question-floating-menu-icon {
    margin-right: 10px;
}

.modal-question-manage-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.modal-question-manage-content {
    background-color: var(--color-extra-dark);
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    min-height: 40vh;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    position: relative;
    color: var(--color-white);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-question-manage-header {
    padding: 20px 36px;
    padding-bottom: 0px;
    border-bottom: 1px solid var(--color-border);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-question-manage-header h3 {
    font-size: 1.5rem;
    font-weight: 400;
}

.modal-question-manage-main-information-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.modal-question-manage-type-select-container {
    position: relative;
    width: 200px;
}

.modal-question-manage-type-select {
    width: 100%;
    padding: 10px 30px 10px 35px;
    padding-left: 40px;
    padding-top: 11px;
    font-size: 16px;
    border: 1px solid var(--color-dark);
    border-radius: 4px;
    appearance: none;
    background-color: var(--color-dark);
    color: var(--color-white);
    cursor: pointer;
    outline: none; /* Add this line to remove the default focus outline */
}

/* Add this new rule to remove the focus outline */
.modal-question-manage-type-select:focus {
    outline: none;
}

.modal-question-manage-type-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--color-brand);
    font-size: 18px;
}

.modal-question-manage-type-select-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: var(--color-white);
}

.modal-question-manage-close {
    background: none;
    border: none;
    cursor: pointer;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 1rem;
    top: 1rem;
    border-radius: 8px;
    transition: all 0.2s;
}

.modal-question-manage-close-create {
    top: 2rem;
    right: 2rem;
    width: 36px;
    height: 36px;
}

.modal-question-manage-close:hover {
    background-color: rgb(62, 62, 62);
}

.modal-question-manage-close svg {
    font-size: 20px;
    color: var(--color-white);
}

.modal-question-manage-body {
    flex-grow: 1;
    overflow-y: auto;
    padding: 0px 36px;
    color: var(--color-white);
    scrollbar-width: thin;  /* For Firefox */
    scrollbar-color: var(--color-dark) transparent;
}

.modal-question-manage-body::-webkit-scrollbar {
    width: 8px;
}

.modal-question-manage-body::-webkit-scrollbar-track {
    background: transparent;
}

.modal-question-manage-body::-webkit-scrollbar-thumb {
    background-color: var(--color-dark);
    border-radius: 4px;
    border: 2px solid transparent;
    background-clip: content-box;
}

.modal-question-manage-body::-webkit-scrollbar-corner {
    background: transparent;
}

.modal-question-manage-body.modal-question-manage-body-create {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.modal-question-manage-body h2 {
    margin: 0;
    margin-bottom: 2rem;
}

.modal-question-manage-footer {
    padding: 20px;
    border-top: 1px solid var(--color-border);
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.modal-question-manage-cancel {
    background: none;
    border: none;
    color: var(--color-white);
    cursor: pointer;
    font-size: 16px;
    padding: 10px 20px;
}

.modal-question-manage-save {
    background-color: var(--color-brand);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.modal-question-manage-main-information-section {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.modal-question-manage-main-information-section label,
.modal-question-manage-option-label,
.modal-question-manage-type-section label,
.modal-question-manage-required-section label {
    color: var(--color-grey);
    font-size: 1rem;
    margin-bottom: .5rem;
    display: block;
}

.modal-question-manage-main-information-section input,
.modal-question-manage-option-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid var(--color-border);
    border-radius: 4px;
    font-family: inherit;
    box-sizing: border-box;
    color: var(--color-white);
    background-color: rgb(32, 32, 32);
}

.modal-question-manage-options-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
}

.modal-question-manage-main-information-section-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1rem;
}

.modal-question-manage-type-section {
    flex: 1;
    margin-right: 2rem;
}

.modal-question-manage-required-section {
    min-width: 100px;
    text-align: right;
}

.modal-question-manage-main-information-section textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid var(--color-border);
    border-radius: 4px;
    font-family: inherit;
    box-sizing: border-box;
    color: var(--color-white);
    background-color: rgb(32, 32, 32);
    resize: none;
    min-height: 80px;
    max-height: 80px;
}

.modal-question-manage-label {
    color: var(--color-grey);
    margin-top: 0.5rem;
}

.modal-question-manage-required {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 10px;
}

.modal-question-manage-required svg {
    font-size: 28px;
    cursor: pointer;
    color: var(--color-white);
}

.modal-question-manage-required svg.required {
    color: var(--color-brand);
}

.modal-question-manage-divider {
    height: 1px;
    background-color: var(--color-grey);
    margin: 1rem 0;
}

.modal-question-manage-preview {
    margin-top: 20px;
}

.modal-question-manage-preview h3 {
    margin: 0;
}

.rating-options {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.rating-option {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.rating-option-label {
    color: var(--color-dark);
    font-weight: bold;
}

.rating-scale-options {
    display: flex;
    gap: 10px;
}

.rating-scale-button {
    flex: 1;
    padding: 8px;
    border: 1px solid var(--color-border);
    background-color: var(--color-white);
    color: var(--color-dark);
    cursor: pointer;
    transition: all 0.2s ease;
}

.rating-scale-button.selected {
    background-color: var(--color-brand);
    color: var(--color-white);
    border-color: var(--color-brand);
}

.rating-symbol-select {
    display: flex;
    align-items: center;
    gap: 10px;
}

.rating-symbol-select select {
    flex: 1;
    padding: 8px;
    border: 1px solid var(--color-border);
    border-radius: 4px;
    color: var(--color-dark);
}

.rating-symbol-preview {
    font-size: 24px;
    color: var(--color-brand);
}

.rating-labels {
    flex-direction: row;
    justify-content: space-between;
}

.rating-label-input {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.rating-label-input input {
    padding: 8px;
    border: 1px solid var(--color-border);
    border-radius: 4px;
    color: var(--color-dark);
}

.rating-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.rating-symbols {
    display: flex;
    gap: 10px;
}

.rating-symbol {
    font-size: 28px;
    color: var(--color-grey);
    cursor: pointer;
    transition: color 0.2s ease;
}

.rating-symbol.active {
    color: var(--color-brand);
}

.rating-labels {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.rating-label {
    font-size: 14px;
    color: var(--color-dark);
    max-width: 45%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.min-label {
    text-align: left;
}

.max-label {
    text-align: right;
}

.multiple-choice-option-label {
    color: var(--color-white);
    font-weight: bold;
}

.multiple-choice-option-item {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 5px;
    border-radius: 4px;
    color: var(--color-white);
}

.multiple-choice-option-handle {
    cursor: grab;
    color: var(--color-grey);
}

.multiple-choice-option-text {
    flex-grow: 1;
}

.multiple-choice-option-remove {
    cursor: pointer;
    color: var(--color-grey);
    min-width: 1rem;
}

.multiple-choice-option-remove:hover {
    color: var(--color-brand);
}

.multiple-choice-allow-other {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.multiple-choice-preview {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.multiple-choice-options-preview {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.multiple-choice-option-preview {
    display: flex;
    align-items: center;
    gap: 10px;
}

.multiple-choice-option-preview input[type="radio"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid var(--color-brand);
    border-radius: 50%;
    outline: none;
    cursor: pointer;
}

.multiple-choice-option-preview input[type="radio"]:checked {
    background-color: var(--color-brand);
    box-shadow: inset 0 0 0 4px var(--color-white);
}

.multiple-choice-option-preview label {
    flex-grow: 1;
    cursor: pointer;
    color: var(--color-dark); /* Add this line */
}

.multiple-choice-other-input {
    padding: 5px;
    border: 1px solid var(--color-border);
    border-radius: 4px;
    margin-left: 30px;
    color: var(--color-dark); /* Add this line */
}

.modal-question-manage-preview h3,
.modal-question-manage-preview h4,
.multiple-choice-preview,
.rating-preview,
.rating-option-label,
.multiple-choice-option-label {
    color: var(--color-white);
}

.rating-symbol-select select,
.rating-label-input input {
    color: var(--color-dark);
}

.rating-scale-button {
    color: var(--color-dark);
}

.rating-scale-button.selected {
    color: var(--color-white);
}

/* Ensure placeholder text is visible */
::placeholder {
    color: var(--color-grey);
}

/* Add some responsive adjustments */
@media (max-width: 768px) {
    .modal-question-manage-content {
        width: 95%;
        height: 90vh;
    }

    .modal-question-manage-type-select-container {
        width: 150px;
    }

    .modal-question-manage-close {
        transition: unset;
    }
    
    .modal-question-manage-close:hover {
        background-color: unset;
    }
}

.select-multiple-options {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.select-multiple-option-label {
    color: var(--color-dark);
    font-weight: bold;
}

.select-multiple-option-item {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 5px;
    background-color: var(--color-white);
    border-radius: 4px;
    color: var(--color-dark);
}

.select-multiple-option-handle {
    cursor: grab;
    color: var(--color-grey);
}

.select-multiple-option-text {
    flex-grow: 1;
}

.select-multiple-option-remove {
    cursor: pointer;
    color: var(--color-grey);
}

.select-multiple-option-remove:hover {
    color: var(--color-brand);
}

.select-multiple-option-input {
    padding: 8px;
    border: 1px solid var(--color-border);
    border-radius: 4px;
    color: var(--color-dark);
}

.select-multiple-allow-other {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1rem;
}

.select-multiple-preview {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.select-multiple-options-preview {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.select-multiple-option-preview {
    display: flex;
    align-items: center;
    gap: 10px;
}

.select-multiple-option-preview input[type="checkbox"] {
    appearance: none;
    width: 18px;
    height: 18px;
    border: 2px solid var(--color-brand);
    border-radius: 3px;
    outline: none;
    cursor: pointer;
}

.select-multiple-option-preview input[type="checkbox"]:checked {
    background-color: var(--color-brand);
    position: relative;
}

.select-multiple-option-preview input[type="checkbox"]:checked::after {
    content: '\2714';
    font-size: 14px;
    color: var(--color-white);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.select-multiple-option-preview label {
    flex-grow: 1;
    cursor: pointer;
    color: var(--color-dark);
}

.select-multiple-other-input {
    padding: 5px;
    border: 1px solid var(--color-border);
    border-radius: 4px;
    margin-left: 30px;
    color: var(--color-dark);
}

.text-options {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.text-option {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.text-option-label {
    color: var(--color-white);
    font-weight: bold;
}

.text-size-options {
    display: flex;
    gap: 10px;
}

.text-size-button {
    flex: 1;
    padding: 8px;
    border: 1px solid var(--color-border);
    background-color: var(--color-white);
    color: var(--color-dark);
    cursor: pointer;
    transition: all 0.2s ease;
}

.text-size-button.selected {
    background-color: var(--color-brand);
    color: var(--color-white);
    border-color: var(--color-brand);
}

.text-max-length-select {
    padding: 8px;
    border: 1px solid var(--color-border);
    border-radius: 4px;
    color: var(--color-dark);
}

.text-preview {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.text-preview-input,
.text-preview-textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid var(--color-border);
    border-radius: 4px;
    font-family: inherit;
    font-size: 14px;
}

.text-preview-textarea {
    resize: none;
}

.text-preview-char-count {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
    font-size: 12px;
    color: var(--color-dark);
}

.text-preview-char-count-circle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
}

.modal-question-manage-description-help {
    font-size: 12px;
    color: var(--color-text-secondary);
    margin-top: 8px;
    padding: 8px;
    background-color: var(--color-dark);
    border-radius: 4px;
}

.modal-question-manage-description-help ul {
    margin: 4px 0 0 0;
    padding-left: 20px;
}

.modal-question-manage-description-help li {
    margin: 2px 0;
}

.modal-question-manage-textarea {
    font-family: monospace;
    line-height: 1.4;
}

.modal-question-manage-rich-editor {
    margin-top: 8px;
}

.modal-question-manage-rich-editor .quill {
    background-color: var(--color-dark);
    border-radius: 4px;
    border: 1px solid var(--color-border-dark);
}

.modal-question-manage-rich-editor .ql-toolbar {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border: none;
    border-bottom: 1px solid var(--color-border-dark);
    background-color: var(--color-darker);
}

.modal-question-manage-rich-editor .ql-container {
    border: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    font-family: "Open Sans", serif;
    font-size: 14px;
    min-height: 120px;
    color: var(--color-white);
    background-color: var(--color-dark);
}

.modal-question-manage-rich-editor .ql-editor {
    min-height: 120px;
}

.modal-question-manage-rich-editor .ql-editor.ql-blank::before {
    font-style: normal;
    color: var(--color-text-tertiary);
}

/* Toolbar button styles */
.modal-question-manage-rich-editor .ql-toolbar button {
    color: var(--color-white);
}

.modal-question-manage-rich-editor .ql-toolbar button:hover {
    color: var(--color-brand);
}

.modal-question-manage-rich-editor .ql-toolbar button.ql-active {
    color: var(--color-brand);
}

.modal-question-manage-rich-editor .ql-toolbar .ql-stroke {
    stroke: var(--color-white);
}

.modal-question-manage-rich-editor .ql-toolbar button:hover .ql-stroke {
    stroke: var(--color-brand);
}

.modal-question-manage-rich-editor .ql-toolbar button.ql-active .ql-stroke {
    stroke: var(--color-brand);
}

.modal-question-manage-rich-editor .ql-toolbar .ql-fill {
    fill: var(--color-white);
}

.modal-question-manage-rich-editor .ql-toolbar button:hover .ql-fill {
    fill: var(--color-brand);
}

.modal-question-manage-rich-editor .ql-toolbar button.ql-active .ql-fill {
    fill: var(--color-brand);
}

/* Link Editor Modal */
.modal-link-editor {
    background: var(--color-dark);
    border-radius: 8px;
    width: 400px;
    max-width: 90vw;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1100;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.modal-link-editor-header {
    padding: 16px;
    border-bottom: 1px solid var(--color-border-dark);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-link-editor-header h3 {
    margin: 0;
    font-size: 16px;
    color: var(--color-white);
}

.modal-link-editor-body {
    padding: 16px;
}

.modal-link-editor-body input {
    width: 100%;
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid var(--color-border-dark);
    background: var(--color-darker);
    color: var(--color-white);
    font-size: 14px;
}

.modal-link-editor-body input:focus {
    outline: none;
    border-color: var(--color-brand);
}

.modal-link-editor-footer {
    padding: 16px;
    border-top: 1px solid var(--color-border-dark);
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}

.modal-link-editor-button {
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    border: none;
}

.modal-link-editor-button.secondary {
    background: var(--color-darker);
    color: var(--color-white);
    border: 1px solid var(--color-border-dark);
}

.modal-link-editor-button.secondary:hover {
    background: var(--color-dark);
}

.modal-link-editor-button.primary {
    background: var(--color-brand);
    color: white;
}

.modal-link-editor-button.primary:hover {
    background: var(--color-brand-dark);
}

.modal-link-editor-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.modal-question-manage-label-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.modal-question-manage-label-row,
.modal-question-manage-help-tooltip,
.modal-question-manage-help-icon,
.modal-question-manage-tooltip-content,
.modal-question-manage-tooltip-header,
.modal-question-manage-tooltip-body {
    display: none;
}