.meeting-finder {
    width: 100%;
    max-width: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.meeting-finder-landing {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 20px;
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
}

.meeting-finder-landing h1 {
    font-size: 2.5rem;
    margin: 0;
    margin-bottom: 1rem;
    font-weight: 800;
}

.meeting-finder-landing > p {
    font-size: 1.2rem;
    color: var(--color-grey);
    margin: 0;
    margin-bottom: 3rem;
}

.meeting-finder-options {
    display: flex;
    gap: 2rem;
    justify-content: center;
    width: 100%;
    max-width: 800px;
}

.meeting-finder-option {
    flex: 1;
    background-color: var(--color-extra-dark);
    padding: 2rem;
    border-radius: 12px;
    cursor: default;
    transition: transform 0.2s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.meeting-finder-option:hover {
    transform: translateY(-5px);
}

.meeting-finder-option-icon {
    width: 60px;
    height: 60px;
    background-color: var(--color-brand);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5rem;
}

.meeting-finder-option-icon svg {
    font-size: 28px;
    color: white;
}

.meeting-finder-create-option p {
    margin-bottom: 0;
}

.meeting-finder-option h2 {
    font-size: 1.5rem;
    margin: 0;
    margin-bottom: 0.5rem;
}

.meeting-finder-option p {
    font-size: 1rem;
    color: var(--color-grey);
    margin: 0;
}

@media (max-width: 768px) {
    .meeting-finder-options {
        flex-direction: column;
        gap: 1rem;
    }

    .meeting-finder-landing h1 {
        font-size: 2rem;
    }

    .meeting-finder-landing > p {
        font-size: 1rem;
    }

    .meeting-finder-option {
        padding: 1.5rem;
    }

    .meeting-finder-option h2 {
        font-size: 1.2rem;
    }
}

.meeting-finder-creator {
    margin: 0 auto;
    padding: 40px 20px;
    padding-top: 0;
    padding-bottom: 0;
    box-sizing: border-box;
}

.meeting-finder-progress {
    margin-bottom: 40px;
}

.meeting-finder-progress-steps {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    margin-bottom: 10px;
}

.meeting-finder-progress-step {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: var(--color-extra-dark);
    color: var(--color-grey);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    position: relative;
}

.meeting-finder-progress-step::after {
    content: '';
    position: absolute;
    width: 40px;
    height: 2px;
    background-color: var(--color-extra-dark);
    right: -40px;
}

.meeting-finder-progress-step:last-child::after {
    display: none;
}

.meeting-finder-progress-step.active {
    background-color: var(--color-brand);
    color: var(--color-white);
}

.meeting-finder-progress-step.completed {
    background-color: var(--color-brand);
    color: var(--color-white);
}

.meeting-finder-progress-step.completed::after {
    background-color: var(--color-brand);
}

.meeting-finder-progress-labels {
    display: flex;
    justify-content: center;
    gap: 40px;
}

.meeting-finder-progress-labels span {
    color: var(--color-grey);
    font-size: 14px;
}

.meeting-finder-progress-labels span.active {
    color: var(--color-white);
    font-weight: 600;
}

@media (max-width: 768px) {
    .meeting-finder-progress-steps {
        gap: 20px;
    }

    .meeting-finder-progress-step::after {
        width: 20px;
        right: -20px;
    }

    .meeting-finder-progress-labels {
        gap: 20px;
    }
}

.meeting-finder-form {
    background-color: var(--color-extra-dark);
    padding: 2rem;
    border-radius: 12px;
    margin-top: 2rem;
    margin-bottom: 120px;
}

.meeting-finder-form-join.meeting-finder-form {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.meeting-finder-form-group {
    margin-bottom: 1.5rem;
}

.meeting-finder-form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 600;
}

.meeting-finder-form-group input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid var(--color-grey);
    border-radius: 4px;
    background-color: var(--color-dark);
    color: var(--color-white);
    font-size: 1rem;
    box-sizing: border-box;
}

.meeting-finder-form-group input.error {
    border-color: #ff4d4f;
}

.meeting-finder-error {
    color: #ff4d4f;
    font-size: 0.875rem;
    margin-top: 0.25rem;
}

.meeting-finder-form-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
    gap: 1rem;
}

.meeting-finder-button-primary,
.meeting-finder-button-secondary {
    padding: 0.75rem 2rem;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: opacity 0.2s ease;
}

.meeting-finder-button-primary {
    background-color: var(--color-brand);
    color: var(--color-white);
    border: none;
}

.meeting-finder-button-primary.meeting-finder-email-details-button-primary {
    font-size: 14px;
    font-weight: 400;
    background-color: transparent;
    color: var(--color-brand);
}

.meeting-finder-button-secondary {
    background-color: transparent;
    color: var(--color-white);
    border: 1px solid var(--color-grey);
}

.meeting-finder-button-primary:hover,
.meeting-finder-button-secondary:hover {
    opacity: 0.9;
}

@media (max-width: 768px) {
    .meeting-finder-form {
        padding: 1.5rem;
    }

    .meeting-finder-form-buttons {
        flex-direction: column-reverse;
    }

    .meeting-finder-button-primary,
    .meeting-finder-button-secondary {
        width: 100%;
    }
}

.meeting-finder-availability {
    background-color: var(--color-extra-dark);
    padding: 0;
    border-radius: 0;
    margin-top: 0;
    width: 100%;
    box-sizing: border-box;
}

.meeting-finder-duration-selector {
    margin-bottom: 2rem;
}

.meeting-finder-duration-selector h3 {
    margin: 0;
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: 600;
}

.meeting-finder-duration-options {
    display: flex;
    gap: 1rem;
}

.meeting-finder-duration-option {
    padding: 0.5rem 1rem;
    border: 1px solid var(--color-grey);
    border-radius: 4px;
    background: none;
    color: var(--color-white);
    cursor: pointer;
    transition: all 0.2s ease;
    min-width: 80px;
}

.meeting-finder-duration-option.selected {
    background-color: var(--color-brand);
    border-color: var(--color-brand);
}

.meeting-finder-calendar {
    width: 100vw;
    overflow-x: auto;
    box-sizing: border-box;
    background-color: var(--color-dark);
}

.meeting-finder-calendar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.meeting-finder-calendar-nav {
    background: none;
    border: none;
    color: var(--color-white);
    cursor: pointer;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.meeting-finder-calendar-dates {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    width: 100%;
    min-width: 100%;
    border-left: 1px solid var(--color-grey);
}

.meeting-finder-calendar-column {
    display: flex;
    flex-direction: column;
    border-right: 1px solid var(--color-grey);
}

.meeting-finder-calendar-date {
    padding: 16px;
    background-color: var(--color-extra-dark);
    border-bottom: 1px solid var(--color-grey);
    text-align: left;
}

.meeting-finder-calendar-day {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 4px;
}

.meeting-finder-calendar-date-number {
    color: var(--color-grey);
    font-size: 14px;
}

.meeting-finder-time-column {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
}

.meeting-finder-time-slots {
    display: flex;
    gap: 2rem;
}

.meeting-finder-time-slot {
    padding: 8px 12px;
    border-bottom: 1px solid var(--color-grey);
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.meeting-finder-time-slot:hover {
    background-color: rgba(var(--color-brand-rgb), 0.1);
}

.meeting-finder-time-slot.selected {
    background-color: var(--color-brand);
    color: var(--color-white);
}

.meeting-finder-time-slot.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background-color: rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
    .meeting-finder-availability {
        padding: 1rem;
    }

    .meeting-finder-calendar-dates {
        gap: 1rem;
    }

    .meeting-finder-time-slots {
        gap: 1rem;
    }

    .meeting-finder-calendar-day {
        font-size: 0.875rem;
    }

    .meeting-finder-calendar-date-number {
        font-size: 0.75rem;
    }

    .meeting-finder-time-slot {
        font-size: 0.75rem;
        padding: 0.25rem;
    }
}

.meeting-finder-confirmation {
    background-color: var(--color-extra-dark);
    padding: 2rem;
    border-radius: 12px;
    margin-top: 2rem;
}

.meeting-finder-create-confirmation.meeting-finder-confirmation {
    background-color: transparent;
    margin-top: 0;
    padding-top: 0;
}

.meeting-finder-confirmation-section {
    margin-bottom: 120px;
}

.meeting-finder-confirmation-section h3 {
    margin: 0;
    margin-bottom: 1rem;
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--color-white);
}

.meeting-finder-confirmation-details,
.meeting-finder-confirmation-settings {
    background-color: var(--color-dark);
    border-radius: 8px;
    padding: 1rem;
}

.meeting-finder-confirmation-row {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
    border-bottom: 1px solid var(--color-grey);
}

.meeting-finder-confirmation-row:last-child {
    border-bottom: none;
}

.meeting-finder-confirmation-label {
    color: var(--color-grey);
    font-size: 0.9rem;
}

.meeting-finder-confirmation-value {
    color: var(--color-white);
    font-weight: 500;
}

.meeting-finder-confirmation-times {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 16px;
    background-color: var(--color-dark);
    border-radius: 8px;
    min-height: 60px;
    align-items: flex-start;
}

.meeting-finder-confirmation-time-slot {
    background-color: var(--color-dark);
    padding: 0.75rem 1rem;
    border-radius: 4px;
    color: var(--color-white);
    font-size: 0.9rem;
}

@media (max-width: 768px) {
    .meeting-finder-confirmation {
        padding: 1rem;
    }

    .meeting-finder-confirmation-row {
        flex-direction: column;
        gap: 0.25rem;
    }

    .meeting-finder-confirmation-value {
        padding-left: 1rem;
    }
}

.meeting-finder-share {
    background-color: var(--color-extra-dark);
    padding: 2rem;
    border-radius: 12px;
    margin-top: 2rem;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.meeting-finder-share-success {
    text-align: center;
    margin-bottom: 2rem;
}

.meeting-finder-share-success-icon {
    font-size: 4rem;
    color: var(--color-brand);
    margin-bottom: 1rem;
}

.meeting-finder-share-success h2 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
}

.meeting-finder-share-success p {
    margin: 0;
    color: var(--color-grey);
}

.meeting-finder-share-link-container {
    margin-bottom: 2rem;
}

.meeting-finder-share-link {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.5rem;
}

.meeting-finder-share-link input {
    flex: 1;
    padding: 0.75rem;
    border: 1px solid var(--color-grey);
    border-radius: 4px;
    background-color: var(--color-dark);
    color: var(--color-white);
    font-size: 1rem;
}

.meeting-finder-share-copy {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem;
    background-color: var(--color-brand);
    color: var(--color-white);
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.2s ease;
}

.meeting-finder-share-copy.copied {
    background-color: var(--color-brand-opacity);
}

.meeting-finder-share-expiry {
    font-size: 0.875rem;
    color: var(--color-grey);
}

.meeting-finder-share-details {
    margin-bottom: 2rem;
}

.meeting-finder-share-details h3 {
    margin: 0;
    margin-bottom: 1rem;
    font-size: 1.2rem;
}

.meeting-finder-share-info {
    background-color: var(--color-dark);
    border-radius: 8px;
    padding: 1rem;
}

.meeting-finder-share-info-row {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
    border-bottom: 1px solid var(--color-grey);
}

.meeting-finder-share-info-row:last-child {
    border-bottom: none;
}

.meeting-finder-share-info-row span:first-child {
    color: var(--color-grey);
}

.meeting-finder-share-actions {
    display: flex;
    justify-content: center;
}

@media (max-width: 768px) {
    .meeting-finder-share {
        padding: 1.5rem;
    }

    .meeting-finder-share-link {
        flex-direction: column;
    }

    .meeting-finder-share-copy {
        width: 100%;
        justify-content: center;
    }
}

.meeting-finder-helper-text {
    font-size: 0.875rem;
    color: var(--color-grey);
    margin: 0;
    margin-top: -0.25rem;
    margin-bottom: 0.5rem;
}

.meeting-finder-time-selection {
    background-color: var(--color-extra-dark);
    padding: 2rem;
    border-radius: 12px;
    margin-top: 2rem;
    padding-bottom: 100px;
}

.meeting-finder-time-selection-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}

.meeting-finder-select-all {
    display: flex;
    align-items: center;
}

.meeting-finder-select-all label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    font-weight: 600;
}

.meeting-finder-select-all input[type="checkbox"] {
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.meeting-finder-timezone-selector select {
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid var(--color-grey);
    background-color: var(--color-dark);
    color: var(--color-white);
    font-size: 0.9rem;
    cursor: pointer;
}

.meeting-finder-dates-list {
    display: flex;
    flex-direction: column;
}

.meeting-finder-date-row {
    display: flex;
    padding: 1.5rem 0;
}

.meeting-finder-date-row.with-border {
    border-bottom: 1px solid var(--color-grey);
}

.meeting-finder-date-column {
    min-width: 200px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.meeting-finder-day-name {
    font-size: 0.9rem;
    color: var(--color-grey);
    text-transform: uppercase;
}

.meeting-finder-date-number {
    font-size: 1.2rem;
    font-weight: 600;
}

.meeting-finder-slots-column {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: flex-start;
}

.meeting-finder-time-option {
    width: 140px;
    height: 40px;
    padding: 0;
    border: 1px solid var(--color-grey);
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.meeting-finder-time-option:hover {
    border-color: var(--color-brand);
}

.meeting-finder-time-option.selected {
    background-color: var(--color-brand);
    border-color: var(--color-brand);
    color: var(--color-white);
}

@media (max-width: 768px) {
    .meeting-finder-time-option {
        width: 120px;
        height: 36px;
        font-size: 0.8rem;
    }
}

.meeting-finder-confirmation-success {
    text-align: center;
    margin-bottom: 2rem;
}

.meeting-finder-confirmation-success-icon {
    font-size: 4rem;
    color: var(--color-brand);
    margin-bottom: 1rem;
}

.meeting-finder-confirmation-success h2 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
}

.meeting-finder-confirmation-success p {
    margin: 0;
    color: var(--color-grey);
}

.meeting-finder-confirmation-actions {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

@media (max-width: 768px) {
    .meeting-finder-confirmation {
        padding: 1rem;
    }

    .meeting-finder-confirmation-success h2 {
        font-size: 1.2rem;
    }

    .meeting-finder-confirmation-success p {
        font-size: 0.9rem;
    }
}

.meeting-finder-controls-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.meeting-finder-navigation {
    display: flex;
    gap: 1rem;
}

.meeting-finder-top-buttons {
    margin-bottom: 0.5rem;
}

.meeting-finder-calendar-dates {
    display: grid;
    grid-template-columns: repeat(7, minmax(200px, 1fr));
    gap: 2rem;
    min-width: min-content;
}

.meeting-finder-calendar-column {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

/* Screen less than 1200px - show 5 columns */
@media (max-width: 1200px) {
    .meeting-finder-calendar-dates {
        grid-template-columns: repeat(5, minmax(200px, 1fr));
    }
}

/* Screen less than 768px - show 1 column */
@media (max-width: 768px) {
    .meeting-finder-calendar-dates {
        grid-template-columns: 1fr;
    }
}

/* Adjust number of visible columns based on container width */
@media (max-width: 1400px) {
    .meeting-finder-calendar-dates {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 1200px) {
    .meeting-finder-calendar-dates {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 900px) {
    .meeting-finder-calendar-dates {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 600px) {
    .meeting-finder-calendar-dates {
        grid-template-columns: repeat(1, 1fr);
    }
}

/* All other styles will be prefixed with meeting-finder- */

.meeting-finder-calendar {
    scrollbar-width: thin;
    scrollbar-color: var(--color-brand) var(--color-dark);
}

.meeting-finder-calendar::-webkit-scrollbar {
    height: 8px;
}

.meeting-finder-calendar::-webkit-scrollbar-track {
    background: var(--color-dark);
    border-radius: 4px;
}

.meeting-finder-calendar::-webkit-scrollbar-thumb {
    background-color: var(--color-brand);
    border-radius: 4px;
}

.meeting-finder-availability {
    scrollbar-width: thin;
    scrollbar-color: var(--color-brand) var(--color-dark);
}

.meeting-finder-availability::-webkit-scrollbar {
    height: 8px;
}

.meeting-finder-availability::-webkit-scrollbar-track {
    background: var(--color-dark);
    border-radius: 4px;
}

.meeting-finder-availability::-webkit-scrollbar-thumb {
    background-color: var(--color-brand);
    border-radius: 4px;
}

.meeting-finder-form-row {
    display: flex;
    gap: 2rem;
    margin-bottom: 1.5rem;
}

.meeting-finder-form-row .meeting-finder-form-group {
    flex: 1;
    margin-bottom: 0;
}

.meeting-finder-select {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid var(--color-grey);
    border-radius: 4px;
    background-color: var(--color-dark);
    color: var(--color-white);
    font-size: 1rem;
    box-sizing: border-box;
    cursor: pointer;
}

.meeting-finder-select option {
    background-color: var(--color-dark);
    color: var(--color-white);
    padding: 0.5rem;
}

@media (max-width: 768px) {
    .meeting-finder-form-row {
        flex-direction: column;
        gap: 1.5rem;
    }
}

.meeting-finder-form-buttons {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem 2rem;
    background-color: var(--color-extra-dark);
    border-top: 1px solid var(--color-grey);
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    z-index: 1000;
}

/* Update/add these styles while keeping existing grid styles */

.meeting-finder-controls-header {
    height: 24px;
    padding: 0;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-extra-dark);
}

.meeting-finder-navigation {
    display: flex;
    gap: 12px;
}

.meeting-finder-calendar-nav {
    background: none;
    border: none;
    color: var(--color-white);
    cursor: pointer;
    padding: 0;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.meeting-finder-calendar {
    width: 100vw;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 100px); /* Adjust based on header + footer height */
    background-color: var(--color-dark);
}

.meeting-finder-calendar-dates {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    width: 100%;
    min-width: 100%;
    border-left: 1px solid var(--color-grey);
    overflow: hidden; /* Prevent horizontal scroll */
}

.meeting-finder-calendar-column {
    display: flex;
    flex-direction: column;
    border-right: 1px solid var(--color-grey);
    height: 100%;
}

.meeting-finder-calendar-date {
    position: sticky;
    top: 0;
    z-index: 10;
    padding: 16px;
    background-color: var(--color-extra-dark);
    border-bottom: 1px solid var(--color-grey);
    text-align: left;
}

.meeting-finder-time-slots-container {
    overflow-y: auto;
    flex: 1;
}

.meeting-finder-form-buttons {
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 1rem 2rem;
    background-color: var(--color-extra-dark);
    border-top: 1px solid var(--color-grey);
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    z-index: 1000;
    width: auto;
}

.meeting-finder-button-primary,
.meeting-finder-button-secondary {
    min-width: 120px;
}

/* Add these new styles */
.meeting-finder-confirmation-times {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 16px;
    background-color: var(--color-dark);
    border-radius: 8px;
    min-height: 60px;
    align-items: flex-start;
}

.meeting-finder-time-pill {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 6px 12px;
    background-color: var(--color-extra-dark);
    border-radius: 20px;
    font-size: 14px;
    border: 1px solid var(--color-grey);
    white-space: nowrap;
}

.meeting-finder-time-pill-text {
    color: var(--color-white);
}

.meeting-finder-time-pill-remove {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    color: var(--color-grey);
    cursor: pointer;
    padding: 2px;
    margin-left: 4px;
    border-radius: 50%;
}

.meeting-finder-time-pill-remove:hover {
    color: #ff4d4f;
    background-color: rgba(255, 77, 79, 0.1);
}

/* Update time group styles */
.meeting-finder-time-group {
    width: 100%;
    margin-bottom: 16px;
}

.meeting-finder-time-group:last-child {
    margin-bottom: 0;
}

.meeting-finder-time-group-header {
    font-weight: 600;
    padding-bottom: 8px;
    margin-bottom: 12px;
    border-bottom: 1px solid var(--color-grey);
    color: var(--color-white);
    text-align: left;
}

.meeting-finder-time-group-slots {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.meeting-finder-confirmation-times {
    display: flex;
    flex-direction: column;
    padding: 16px;
    background-color: var(--color-dark);
    border-radius: 8px;
    min-height: 60px;
}

.meeting-finder-time-pill {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 6px 12px;
    background-color: var(--color-extra-dark);
    border-radius: 20px;
    font-size: 14px;
    border: 1px solid var(--color-grey);
    white-space: nowrap;
}

/* Add these new styles */
.meeting-finder-join-form {
    width: 100%;
}

.meeting-finder-join-input-group {
    display: flex;
    align-items: stretch;
    gap: 8px;
    margin-top: 20px;
}

.meeting-finder-join-input-group input {
    flex: 1;
    padding: 12px;
    border: 1px solid var(--color-grey);
    border-radius: 4px;
    background-color: var(--color-dark);
    color: var(--color-white);
    font-size: 16px;
}

.meeting-finder-join-input-group input.error {
    border-color: #ff4d4f;
}

button[type="submit"].meeting-finder-join-button {
    padding: 12px 24px;
    background-color: transparent;
    color: var(--color-brand-opacity);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 600;
    white-space: nowrap;
    margin: 0;
    transition: all 0.2s;
}

.meeting-finder-join-error {
    color: #ff4d4f;
    font-size: 14px;
    margin-top: 8px;
    text-align: left;
}

/* Update existing styles */
.meeting-finder-option {
    cursor: default; /* Remove pointer cursor from container */
}

.meeting-finder-option:first-child {
    cursor: pointer; /* Keep pointer only on the create new poll option */
}

/* New styles for the create option */
.meeting-finder-create-option {
    background-color: var(--color-extra-dark);
    padding: 2rem;
    border-radius: 12px;
    cursor: pointer;
    transition: transform 0.2s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 2rem;
    margin: 1rem;
}

.meeting-finder-create-option:hover {
    transform: translateY(-5px);
}

/* Join section styles */
.meeting-finder-join-section {
    width: 100%;
    max-width: 400px;
}

.meeting-finder-join-divider {
    color: var(--color-grey);
    margin: 1rem 0;
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.meeting-finder-join-form {
    width: 100%;
}

.meeting-finder-join-input-group {
    display: flex;
    gap: 8px;
}

.meeting-finder-join-input-group input {
    flex: 1;
    padding: 12px;
    border: 1px solid var(--color-grey);
    border-radius: 4px;
    background-color: var(--color-dark);
    color: var(--color-white);
    font-size: 16px;
}

.meeting-finder-join-input-group input.error {
    border-color: #ff4d4f;
}

.meeting-finder-join-error {
    color: #ff4d4f;
    font-size: 14px;
    margin-top: 8px;
    text-align: left;
}

/* Add these styles for the edit link section */
.meeting-finder-confirmation-edit {
    margin-top: 2rem;
    padding: 1rem;
    background-color: var(--color-dark);
    border-radius: 8px;
}

.meeting-finder-confirmation-edit p {
    margin: 0;
    margin-bottom: 0.5rem;
    color: var(--color-grey);
    font-size: 0.9rem;
}

.meeting-finder-confirmation-edit input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid var(--color-grey);
    border-radius: 4px;
    background-color: var(--color-extra-dark);
    color: var(--color-white);
    font-size: 0.9rem;
}

/* Add these new styles */
.meeting-finder-manager {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
}

.meeting-finder-manager-header {
    margin-bottom: 40px;
    text-align: center;
}

.meeting-finder-manager-header h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.meeting-finder-manager-meta {
    color: var(--color-grey);
}

.meeting-finder-manager-organizer {
    margin-bottom: 0.5rem;
}

.meeting-finder-manager-details {
    display: flex;
    gap: 12px;
    justify-content: center;
    align-items: center;
}

.meeting-finder-manager-responses {
    background-color: var(--color-extra-dark);
    border-radius: 12px;
    padding: 24px;
    margin-bottom: 40px;
}

.meeting-finder-manager-responses h2 {
    margin-bottom: 24px;
}

.meeting-finder-manager-grid {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.meeting-finder-time-slot {
    background-color: var(--color-dark);
    border-radius: 8px;
    padding: 16px;
    border: 1px solid var(--color-grey);
}

.meeting-finder-time-header {
    margin-bottom: 16px;
    padding-bottom: 12px;
    border-bottom: 1px solid var(--color-grey);
}

.meeting-finder-time-date {
    font-weight: 600;
    margin-bottom: 4px;
}

.meeting-finder-time-time {
    color: var(--color-grey);
}

.meeting-finder-response-count {
    color: var(--color-brand);
    font-weight: 600;
    margin-bottom: 8px;
}

.meeting-finder-respondents {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.meeting-finder-respondent {
    font-size: 14px;
    padding: 4px 8px;
    background-color: var(--color-extra-dark);
    border-radius: 4px;
}

.meeting-finder-manager-actions {
    display: flex;
    gap: 16px;
    justify-content: center;
}

.meeting-finder-manager-actions button {
    padding: 12px 24px;
    border-radius: 8px;
    font-weight: 600;
    min-width: 200px;
}

/* Mobile Styles */
@media (max-width: 768px) {
    .meeting-finder-manager {
        padding: 20px;
    }

    .meeting-finder-manager-header h1 {
        font-size: 2rem;
    }

    .meeting-finder-manager-grid {
        grid-template-columns: 1fr;
    }

    .meeting-finder-manager-actions {
        flex-direction: column;
    }

    .meeting-finder-manager-actions button {
        width: 100%;
    }

    .meeting-finder-time-slot {
        padding: 12px;
    }
}

/* Add these new styles */
.meeting-finder-time-slot-box {
    background-color: var(--color-dark);
    border-radius: 8px;
    border: 1px solid var(--color-grey);
    margin-bottom: 16px;
    overflow: hidden;
}

.meeting-finder-time-slot-header {
    padding: 16px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.2s ease;
}

.meeting-finder-time-slot-header:hover {
    background-color: rgba(var(--color-brand-rgb), 0.1);
}

.meeting-finder-time-slot-info {
    flex: 1;
}

.meeting-finder-time-slot-date {
    font-weight: 600;
    margin-bottom: 4px;
}

.meeting-finder-time-slot-time {
    color: var(--color-grey);
    font-size: 0.9rem;
}

.meeting-finder-time-slot-summary {
    display: flex;
    align-items: center;
    gap: 12px;
    color: var(--color-grey);
}

.meeting-finder-slot-info-symbol-summary {
    display: flex;
    align-items: center;
    gap: 2px;
}

.meeting-finder-slot-info-symbol-summary .response-icon {
    margin: 0;
}

.meeting-finder-time-slot-drawer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
}

.meeting-finder-time-slot-drawer.expanded {
    max-height: 800px;
}

.meeting-finder-response-lists {
    padding: 16px;
    display: flex;
    gap: 24px;
    border-top: 1px solid var(--color-grey);
}

.meeting-finder-response-column {
    flex: 1;
}

.meeting-finder-response-column h4 {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 0;
}

.meeting-finder-response-column.available h4 {
    color: var(--color-brand);
}

.meeting-finder-response-column.unavailable h4 {
    color: #ff4d4f;
}

.meeting-finder-response-column ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.meeting-finder-response-column li {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    padding-left: 0;
    border-radius: 4px;
    margin-bottom: 0px;
    margin-top: 0px;
}

.response-icon {
    font-size: 12px;
}

.response-icon.available {
    color: var(--color-brand);
}

.response-icon.unavailable {
    color: #ff4d4f;
}

.meeting-finder-no-responses {
    color: var(--color-grey);
    font-size: 0.9rem;
    padding: 8px;
    padding-left: 0;
}

@media (max-width: 768px) {
    .meeting-finder-response-lists {
        flex-direction: column;
    }

    .meeting-finder-response-column {
        width: 100%;
    }

    .meeting-finder-time-slot-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
    }

    .meeting-finder-time-slot-summary {
        width: 100%;
        justify-content: space-between;
    }
}

/* Add these new styles */
.meeting-finder-time-slot-actions {
    padding: 16px;
    display: flex;
    justify-content: flex-end;
}

.meeting-finder-time-slot-actions button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
}

.meeting-finder-confirmation {
    background-color: var(--color-extra-dark);
    border-radius: 12px;
    padding: 40px;
    text-align: center;
    margin-top: 20px;
}

.meeting-finder-confirmation-message {
    max-width: 500px;
    margin: 0 auto;
}

.meeting-finder-confirmation-message h2 {
    margin-bottom: 20px;
    color: var(--color-brand);
}

.meeting-finder-confirmation-message p {
    margin-bottom: 16px;
    color: var(--color-grey);
}

.meeting-finder-confirmation-message button {
    margin-top: 20px;
}

.meeting-finder-error-message {
    background-color: rgba(255, 77, 79, 0.1);
    border: 1px solid #ff4d4f;
    color: #ff4d4f;
    padding: 12px;
    border-radius: 8px;
    margin-bottom: 20px;
    text-align: center;
}

/* Add modal styles */
.meeting-finder-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.meeting-finder-modal {
    background-color: var(--color-extra-dark);
    padding: 24px;
    border-radius: 8px;
    max-width: 400px;
    width: 90%;
}

.meeting-finder-modal h3 {
    margin-bottom: 16px;
}

.meeting-finder-modal p {
    margin-bottom: 24px;
    color: var(--color-grey);
}

.meeting-finder-modal-actions {
    display: flex;
    gap: 12px;
    justify-content: flex-end;
}

.meeting-finder-modal-actions button {
    padding: 8px 16px;
}

/* Add these new styles */

.meeting-finder-features {
    margin-top: 120px;
    width: 100%;
    max-width: 1200px;
    padding: 0 20px;
    box-sizing: border-box;
}

.meeting-finder-features-header {
    text-align: center;
    margin-bottom: 60px;
}

.meeting-finder-features-header h2 {
    font-size: 2.4rem;
    margin: 0;
    margin-bottom: 16px;
    font-weight: 800;
}

.meeting-finder-features-header p {
    font-size: 1rem;
    color: var(--color-grey);
    margin: 0;
}

.meeting-finder-feature-grid {
    display: flex;
    justify-content: center;
    gap: 40px;
    margin: 60px 0;
    flex-wrap: wrap;
    padding: 0 20px;
}

.meeting-finder-feature-card {
    padding: 32px;
    text-align: center;
    flex: 1;
    max-width: 400px;
    width: 100%;
    box-sizing: border-box;
    transition: transform 0.2s ease;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    background: linear-gradient(145deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.02) 100%);
}

.meeting-finder-feature-card:hover {
    transform: translateY(-5px);
}

.meeting-finder-feature-icon-wrapper {
    width: 64px;
    height: 64px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 24px;
    background: linear-gradient(135deg, var(--color-brand) 0%, #4DB6A1 100%);
}

.meeting-finder-feature-icon {
    font-size: 28px;
    color: var(--color-white);
}

.meeting-finder-feature-card h3 {
    font-size: 1.4rem;
    margin: 0;
    margin-bottom: 16px;
    color: var(--color-white);
}

.meeting-finder-feature-card p {
    font-size: 1rem;
    color: var(--color-grey);
    margin: 0;
    line-height: 1.6;
}

.meeting-finder-integration {
    background-color: var(--color-extra-dark);
    padding: 80px 20px;
    margin-top: 120px;
    width: 100%;
    box-sizing: border-box;
}

.meeting-finder-integration-content {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
}

.meeting-finder-integration-content h2 {
    font-size: 2rem;
    margin: 0;
    margin-bottom: 16px;
}

.meeting-finder-integration-content > p {
    font-size: 1.2rem;
    color: var(--color-grey);
    margin: 0;
    margin-bottom: 40px;
}

.meeting-finder-integration-features {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    margin-bottom: 40px;
}

.meeting-finder-integration-feature h4 {
    font-size: 1.2rem;
    margin: 0;
    margin-bottom: 8px;
}

.meeting-finder-integration-feature p {
    font-size: 1rem;
    color: var(--color-grey);
    margin: 0;
    line-height: 1.5;
}

@media (max-width: 900px) {
    .meeting-finder-feature-grid {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .meeting-finder-feature-card {
        width: 100%;
        max-width: 100%;
    }
}

@media (max-width: 500px) {
    .meeting-finder-features-header h2,
    .meeting-finder-integration-content h2 {
        font-size: 1.4rem;
    }

    .meeting-finder-features-header p,
    .meeting-finder-integration-content > p {
        font-size: 1rem;
    }

    .meeting-finder-feature-card {
        padding: 24px;
    }

    .meeting-finder-feature-icon {
        font-size: 2rem;
    }

    .meeting-finder-feature-card h3 {
        font-size: 1.2rem;
    }

    .meeting-finder-integration {
        padding: 40px 20px;
    }
}

/* Update existing styles */
.meeting-finder-main-title {
    font-size: 3.2rem !important;
    font-weight: 800;
    margin-bottom: 1rem;
}

.meeting-finder-features-header h2 {
    font-size: 2rem;
    margin: 0;
    margin-bottom: 16px;
    font-weight: 800;
}

.meeting-finder-feature-grid {
    display: grid;
    gap: 40px;
    margin-bottom: 60px;
}

/* Add new styles */
.meeting-finder-feature-cta {
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
}

.meeting-finder-feature-cta button {
    padding: 12px 32px;
    font-size: 1.1rem;
}

@media (max-width: 900px) {
    .meeting-finder-main-title {
        font-size: 2.4rem !important;
    }

    .meeting-finder-features-header h2 {
        font-size: 1.2rem;
    }
}

@media (max-width: 500px) {
    .meeting-finder-main-title {
        font-size: 2rem !important;
    }
}