.loading {
    text-align: center;
    font-size: 1.2em;
    margin-top: 2em;
}

.error-container {
    text-align: center;
    margin-top: 2em;
}

.home-button {
    display: inline-block;
    margin-top: 1em;
    padding: 0.5em 1em;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 4px;
}

.form-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 2em;
}

.form-container h1 {
    text-align: center;
    margin-bottom: 1em;
}

.the-form-container {
    min-height: 100vh;
    background-color: var(--color-white);
    background-color: var(--color-white-pure);
    color: var(--color-dark);
    padding: 0 4rem;
    padding-bottom: 2rem;
    box-sizing: border-box;
}

.the-form-dark-mode {
    background-color: var(--color-dark);
    color: var(--color-white);
}

.the-form-content {
    width: 100%;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
    padding-bottom: 40px;
}

.the-form-container .the-form-content {
    background-color: transparent;
}

.the-form-title {
    min-height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 60px;
    padding-bottom: 20px;
}

.the-form-title-presentation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 8px;
    border-width: 3px;
    padding-bottom: 1rem;
    border-style: solid;
    width: 100%;
    max-width: 800px;
    box-sizing: border-box;
    border-left: none;
    border-right: none;
    border-top: none;
    margin-bottom: 2rem;
}

.the-form-title h1 {
    color: var(--color-dark);
    font-size: 2.5rem;
    font-weight: 700;
    margin: 0;
}
.the-form-dark-mode .the-form-title h1,
.the-form-dark-mode .the-form-introduction-message {
    color: var(--color-white);
}

.the-form-introduction-message {
    color: var(--color-dark);
    font-size: 1rem;
    line-height: 1.333;
    white-space: pre-wrap;
    max-width: 800px;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
}

.the-form-title-presentation span {
    font-size: 14px;
    font-weight: 400;
    color: grey;
}

.the-form {
    display: flex;
    flex-direction: column;
    gap: 56px;
}

.the-form-question {
    display: flex;
    flex-direction: column;
    background-color: var(--color-white-pure);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    padding: 2rem 3rem;
}

.the-form-dark-mode .the-form-question {
    background-color: var(--color-extra-dark);
}

.the-form-question-number {
    font-size: 12px;
    font-weight: 400;
    color: rgb(133, 133, 133);
}

.the-form-label-and-description-container {
    margin-top: 4px;
    margin-bottom: 36px;
}

.the-form-label {
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 1.333;
}

.the-form-required {
    color: var(--primary-color);
    margin-left: 0.25rem;
}

.the-form-description {
    font-size: 0.9rem;
    margin: 0;
    margin-top: 4px;
    color: rgb(95, 95, 95);
    line-height: 1.333;
    white-space: pre-wrap;
}

.the-form-dark-mode .the-form-description {
    color: grey;
}

.the-form-text-input {
    width: 100%;
    padding: 1rem;
    border: 1px solid var(--input-border-color, var(--primary-color));
    border-radius: 4px;
    font-size: 1rem;
    box-sizing: border-box;
    background-color: transparent;
}
.the-form-dark-mode .the-form-text-input {
    background-color: var(--color-dark);
    color: var(--color-white);
}

.the-form-text-input:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--input-focus-color, var(--primary-color));
}

.the-form-submit-button {
    width: 100%;
    padding: 0.75rem;
    background-color: var(--primary-color);
    color: var(--contrast-color);
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: opacity 0.3s ease;
}

.the-form-submit-button:hover {
    opacity: 0.9;
}

.the-form-error-container {
    text-align: center;
    margin-top: 2rem;
}

.the-form-home-button {
    display: inline-block;
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background-color: var(--primary-color);
    color: var(--color-white);
    text-decoration: none;
    border-radius: 4px;
}

.the-form-rating {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.the-form-rating-icon-container {
    position: relative;
    margin-bottom: 28px;
}

.the-form-rating-icon {
    font-size: 2rem;
    transition: color 0.2s ease;
}

.the-form-rating-icon:hover {
    transform: scale(1.1);
}

.the-form-rating-icon.filled {
    color: var(--primary-color);
}

.the-form-rating-icon.empty {
    color: var(--color-grey);
}

.the-form-rating-label {
    font-size: 1rem;
    color: var(--color-dark);
    position: absolute;
    width: 300px;
    bottom: -28px;
    right: 0;
}

.the-form-dark-mode .the-form-rating-label {
    color: grey;
}

.the-form-rating-icons {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    gap: 8px;
    margin-left: auto;
    margin-right: auto;
}

.the-form-rating-label.min-label {
    right: unset;
    left: 0;
}

.the-form-rating-label.max-label {
    margin-left: auto;
}

.the-form-checkbox-label,
.the-form-radio-label {
    display: block;
    margin-bottom: 0.5rem;
}

.the-form-checkbox-label input[type="checkbox"],
.the-form-radio-label input[type="radio"] {
    accent-color: var(--checkbox-color, var(--radio-color, var(--primary-color)));
}

.the-form-submit-status {
    margin-top: 1rem;
    padding: 1rem;
    border-radius: 4px;
    text-align: center;
}

.the-form-submit-status.success {
    background-color: #d4edda;
    color: #155724;
}

.the-form-submit-status.error {
    background-color: #f8d7da;
    color: #721c24;
}

.the-form-submit-status.preview {
    background-color: #cce5ff;
    color: #004085;
}

.the-form-thank-you-message {
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 2rem;
}

.the-form-attribution {
    position: fixed;
    bottom: 1rem;
    left: 0;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    font-size: 14px;
    color: var(--color-grey);
}

.the-form-attribution a {
    color: var(--color-grey);
    text-decoration: none;
    font-size: 14px;
}

.the-form-dark-mode .the-form-attribution a {
    color: var(--color-grey);
}

.the-form-attribution img {
    height: 16px;
}

.the-form-attribution.the-form-attribution-active {
    position: relative;
    flex-direction: row;
    margin-top: 20px;
}

.the-form-close-button {
    display: block;
    width: 200px;
    margin: 0 auto;
    padding: 0.75rem;
    background-color: var(--primary-color);
    color: var(--contrast-color);
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: opacity 0.3s ease;
}

.the-form-close-button:hover {
    opacity: 0.9;
}

.the-form-error-message {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #f8d7da;
    color: #721c24;
    padding: 1rem;
    text-align: center;
    z-index: 1000;
}

.the-form-text-input:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--input-focus-color, var(--primary-color));
}

.the-form-textarea {
    resize: none;
    overflow-y: auto;
    background-color: transparent;
}
.the-form-dark-mode .the-form-textarea {
    background-color: var(--color-dark);
}

.text-question-char-count {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
    font-size: 12px;
    color: var(--color-dark);
    margin-top: 5px;
}

.the-form-dark-mode .text-question-char-count {
    color: var(--color-grey);
}

.text-question-char-count-circle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
}

.the-form-custom-checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.the-form-checkbox-options {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
}

.the-form-checkbox-option {
    padding: 24px 20px;
    text-align: left;
    box-sizing: border-box;
    flex-basis: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 1.333;
    font-weight: 400;
    background-color: var(--color-white-pure);
    color: var(--color-dark);
    border: 1px solid var(--color-grey);
    cursor: pointer;
    position: relative;
    border-radius: 4px;
    transition: all 0.2s ease;
}

.the-form-checkbox-option.the-form-checkbox-option-one {
   border-radius: 200px; 
}

.the-form-checkbox-option.selected {
    border: 1px solid var(--color-brand);
    color: var(--color-brand);
}

.the-form-dark-mode .the-form-checkbox-option {
    background-color: var(--color-dark);
    color: var(--color-white);
}

.the-form-checkbox-icon {
    font-size: 1.2rem;
    margin-right: 0.5rem;
}

.the-form-checkbox-text {
    font-size: 1rem;
}

.the-form-checkbox-icon.selected {
    filter: brightness(90%);
}

.the-form-other-input {
    width: 100%;
    box-sizing: border-box;
    padding: 0.5rem;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    background-color: var(--color-white-pure);
    border: 1px solid var(--input-border-color, var(--primary-color));
    border-radius: 4px;
    font-size: 14px;
}

.the-form-dark-mode .the-form-other-input {
    background-color: var(--color-extra-dark);
    color: var(--color-white);
}

.the-form-other-input:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--input-focus-color, var(--primary-color));
}

.the-form-header-logo-container {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
}

.the-form-header-logo-container.mobile {
    display: none;
}

@media screen and (max-width: 768px) {
    .the-form-container {
        padding: 0 20px;
        box-sizing: border-box;
    }

    .the-form-header-logo-container {
        display: none;
    }

    .the-form-header-logo-container.mobile {
        display: block;
    }

    .the-form-title {
        min-height: 60px;
        padding: 14px 0;
        justify-content: flex-start;
        padding-top: 48px;
    }

    .the-form {
        gap: 20px;
    }
    
    .the-form-title h1 {
        font-size: 24px;
        font-weight: 600;
        gap: 4px;
        align-items: flex-start;
    }

    .the-form-introduction-message {
        font-size: 14px;
    }

    .the-form-title h1 span {
        font-size: 12px;
    }

    .the-form-question {
        padding: 20px;
        font-size: 16px;
    }

    .the-form-label {
        font-size: 18px;
        font-weight: 600;
    }

    .the-form-description {
        font-size: 12px;
    }

    .the-form-label-and-description-container {
        margin-bottom: 36px;
    }

    .the-form-checkbox-options {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: stretch;
        width: 100%;
        gap: 1rem;
    }

    .the-form-checkbox-option {
        font-size: 16px;
    }

    .the-form-other-input {
        font-size: 16px;
    }

    .the-form-rating-icon {
        font-size: 1.5rem;
        transition: color 0.2s ease;
    }

    .the-form-rating-label {
        font-size: 0.8rem;
    }
}

.the-form-watermark {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 12px;
    opacity: 0.7; 
    max-width: none; 
    white-space: nowrap;
    overflow: visible; 
    text-overflow: clip;
    z-index: 1000;
    pointer-events: none;
    color: var(--color-dark);
    padding: 8px; 
    background-color: rgba(255, 255, 255, 0.8);
}

.the-form-dark-mode .the-form-watermark {
    color: var(--color-white);
    background-color: rgba(0, 0, 0, 0.8);
}

/* Section Heading Styles */
.the-form-section-heading {
    padding: 1rem 0;
    margin: 0;
}

.the-form-section-heading h2 {
    font-size: 2rem;
    box-sizing: border-box;
    font-weight: 600;
    margin: 0;
    padding-left: 0.8rem;
    margin-bottom: 1rem;
}

.the-form-dark-mode .the-form-section-heading h2 {
    color: var(--color-white) !important;
}

/* Adjust spacing after section headings */
.the-form-section-heading + .the-form-question {
    margin-top: 2rem;
}

/* Adjust question numbering container after headings */
.the-form-question {
    position: relative;
    margin-bottom: 2rem;
}


@media (max-width: 768px) {
    .the-form-watermark {
        display: none;
    }

    .the-form-section-heading h2 {
        font-size: 20px;
    }
}