.home-features-section {
    width: 95%;
    padding: 80px 20px;
    background-color: var(--color-extra-dark);
    margin-top: 80px;
    box-sizing: border-box;
    border-radius: 60px;
}

.home-features-section h2 {
    text-align: center;
    font-size: 2.8rem;
    font-weight: 800;
    margin: 0 0 60px 0;
}

.home-features-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1px;
    max-width: 1200px;
    margin: 0 auto;
    background-color: var(--color-dark);
    border: 1px solid var(--color-dark);
    border-radius: 12px;
    overflow: hidden;
}

.home-feature-block {
    padding: 24px 20px;
    background-color: var(--color-extra-dark);
    text-align: center;
    transition: transform 0.3s ease;
}

.home-feature-block:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.home-feature-icon {
    width: 40px;
    height: 40px;
    margin: 0 auto 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: var(--color-brand);
    background-color: rgba(48, 156, 131, 0.1);
    border-radius: 8px;
}

.home-feature-block h3 {
    font-size: 1rem;
    font-weight: 700;
    margin: 0 0 8px 0;
    color: var(--color-white);
}

.home-feature-block p {
    font-size: 0.875rem;
    line-height: 1.5;
    color: var(--color-grey);
    margin: 0;
    padding: 0 12px;
}

@media screen and (max-width: 1000px) {
    .home-features-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 500px) {
    .home-feature-block {
        padding: 20px 16px;
    }

    .home-feature-icon {
        width: 32px;
        height: 32px;
        font-size: 16px;
        margin-bottom: 12px;
    }

    .home-feature-block h3 {
        font-size: 0.9rem;
        margin-bottom: 6px;
    }

    .home-feature-block p {
        font-size: 0.8rem;
        padding: 0 8px;
    }
}