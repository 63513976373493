.publish-confirmation-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.publish-confirmation-modal {
    background-color: var(--color-white);
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
}

.publish-confirmation-modal h2 {
    margin-top: 0;
    color: var(--color-dark);
}

.publish-confirmation-modal p {
    color: var(--color-dark);
}

.publish-confirmation-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.publish-confirmation-cancel,
.publish-confirmation-confirm {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
}

.publish-confirmation-cancel {
    background-color: var(--color-background-light);
    color: var(--color-dark);
    margin-right: 10px;
}

.publish-confirmation-confirm {
    background-color: var(--color-brand);
    color: var(--color-white);
}