.import-wizard-example-table-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.import-wizard-example-table-modal {
    background-color: var(--color-dark);
    border-radius: 8px;
    width: 90%;
    max-width: 1200px;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--color-dark-brand);
    overflow-y: auto;
    /* Webkit scrollbar styles */
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: var(--color-grey);
        border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background-color: var(--color-light-grey);
    }
    /* Firefox scrollbar styles */
    scrollbar-width: thin;
    scrollbar-color: var(--color-grey) transparent;
}

.import-wizard-example-table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    border-bottom: 1px solid var(--color-dark-brand);
}

.import-wizard-example-table-header h2 {
    margin: 0;
    font-size: 1.25rem;
    color: var(--color-white);
}

.import-wizard-example-table-close {
    background: transparent;
    border: none;
    color: var(--color-grey);
    cursor: pointer;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    transition: color 0.2s ease;
}

.import-wizard-example-table-close:hover {
    color: var(--color-white);
}

.import-wizard-example-table-description {
    padding: 1rem 1.5rem;
    color: var(--color-grey);
    font-size: 0.9rem;
    border-bottom: 1px solid var(--color-dark-brand);
}

.import-wizard-example-table-container {
    padding: 1.5rem;
    overflow-x: auto;
    /* Webkit scrollbar styles */
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: var(--color-grey);
        border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background-color: var(--color-light-grey);
    }
    /* Firefox scrollbar styles */
    scrollbar-width: thin;
    scrollbar-color: var(--color-grey) transparent;
}

.import-wizard-example-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 12px;
}

.import-wizard-example-table th {
    background-color: var(--color-extra-dark);
    color: var(--color-white);
    font-weight: 500;
    text-align: left;
    padding: 1rem;
    border: 1px solid var(--color-dark-brand);
}

.import-wizard-example-table td {
    padding: 0.75rem 1rem;
    color: var(--color-grey);
    border: 1px solid var(--color-dark-brand);
}

.import-wizard-example-table tr:nth-child(even) {
    background-color: var(--color-extra-dark);
}

.import-wizard-example-table tr:hover td {
    background-color: var(--color-dark-brand-opacity);
    color: var(--color-white);
} 