.import-wizard {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
}

.wizard-header {
    margin-bottom: 2rem;
}

.wizard-header p {
    margin: 0;
    font-size: 14px;
    color: var(--color-grey);
    margin-bottom: 16px;
}

.wizard-header h1 {
    font-size: 1.75rem;
    color: var(--color-white);
    margin-bottom: 8px;
}

.wizard-header-back-button-container {
    display: flex;
}

.wizard-header-back-button {
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
}

.wizard-header-back-button svg {
    font-size: 16px;
}

.wizard-steps {
    display: flex;
    gap: 1.5rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid var(--color-dark-brand);
    padding-bottom: 1rem;
}

.step {
    color: var(--color-grey);
    font-size: 0.9rem;
    position: relative;
    padding-bottom: 0.5rem;
}

.step.active {
    color: var(--color-white);
    font-weight: 500;
}

.step.active::after {
    content: '';
    position: absolute;
    bottom: -1rem;
    left: 0;
    right: 0;
    height: 2px;
    background-color: var(--color-brand);
}

/* Upload Area Styling */
.import-details-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.details-form {
    background: var(--color-dark);
    border: 1px solid var(--color-dark-brand);
    border-radius: 8px;
    padding: 1.5rem;
}

.import-wizard-form-group {
    margin-bottom: 1.25rem;
}

.import-wizard-form-group:last-child {
    margin-bottom: 0;
}

.import-wizard-form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: var(--color-white);
    font-weight: 500;
    font-size: 0.9rem;
}

.import-wizard-form-input, .import-wizard-form-textarea {
    width: 100%;
    box-sizing: border-box;
    padding: 0.75rem;
    border: 1px solid var(--color-dark-brand);
    border-radius: 6px;
    background: var(--color-extra-dark);
    color: var(--color-white);
    font-size: 1rem;
    transition: border-color 0.2s ease;
}

.import-wizard-form-input:focus, .import-wizard-form-textarea:focus {
    border-color: var(--color-brand);
    outline: none;
}

.import-wizard-form-textarea {
    resize: none;
    height: 96px; /* 4 rows at 24px per row */
    overflow-y: auto;
    scrollbar-width: thin; /* Firefox */
    scrollbar-color: var(--color-dark-brand) var(--color-extra-dark); /* Firefox */
}

/* Webkit browsers (Chrome, Safari, Edge) */
.import-wizard-form-textarea::-webkit-scrollbar {
    width: 6px;
}

.import-wizard-form-textarea::-webkit-scrollbar-track {
    background: var(--color-extra-dark);
    border-radius: 3px;
}

.import-wizard-form-textarea::-webkit-scrollbar-thumb {
    background-color: var(--color-dark-brand);
    border-radius: 3px;
}

.import-wizard-form-textarea::-webkit-scrollbar-thumb:hover {
    background-color: var(--color-grey);
}

.upload-area {
    background: var(--color-dark);
    border: 1px dashed var(--color-dark-brand);
    border-radius: 8px;
    padding: 2rem;
    text-align: center;
    transition: all 0.2s ease;
    min-height: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.upload-area.dragging {
    border-color: var(--color-brand);
    background: var(--color-dark-brand-opacity);
}

.upload-icon {
    font-size: 2.5rem;
    color: var(--color-brand);
    margin-bottom: 1rem;
}

.upload-button {
    background: var(--color-brand);
    color: var(--color-white-pure);
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    transition: background 0.2s ease;
    margin-top: 1rem;
}

.upload-button:hover {
    background: var(--color-brand-complement);
}

.selected-file {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    color: var(--color-white);
    font-size: 0.95rem;
    overflow: hidden;
    max-width: 100%;
}

.selected-file span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.selected-file .file-icon {
    color: var(--color-brand);
    font-size: 1.5rem;
}

.change-file-button {
    background: transparent;
    color: var(--color-white);
    border: 1px solid var(--color-dark-brand);
    padding: 0.5rem 1rem;
    border-radius: 4px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: all 0.2s ease;
}

.change-file-button:hover {
    background: var(--color-dark-brand-opacity);
}

.supported-formats {
    color: var(--color-grey);
    font-size: 0.8rem;
    margin-top: 0.75rem;
}

.supported-formats span {
    color: var(--color-brand);
    cursor: pointer;
}

.supported-formats span:hover {
    text-decoration: underline;
}

/* Column Mapping Styling */
.mapping-container {
    background: var(--color-dark);
    border: 1px solid var(--color-dark-brand);
    border-radius: 8px;
    overflow: hidden;
}

.mapping-header {
    display: grid;
    grid-template-columns: 1fr 1.5fr auto;
    gap: 1rem;
    padding: 0.75rem 1rem;
    border-bottom: 1px solid var(--color-dark-brand);
}

.mapping-header-label {
    color: var(--color-grey);
    font-size: 0.8rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.mapping-row {
    display: grid;
    grid-template-columns: 1fr 1.5fr auto;
    gap: 1rem;
    padding: 1rem;
    align-items: center;
    border-bottom: 1px solid var(--color-dark-brand);
}

.mapping-row:last-child {
    border-bottom: none;
}

.column-name {
    font-weight: 500;
    color: var(--color-white);
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.column-sample {
    color: var(--color-grey);
    font-size: 0.9rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.mapping-controls {
    display: flex;
    align-items: flex-start;
}

.mapping-selects {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 160px; /* Match the min-width of mapping-select */
}

.mapping-select {
    padding: 0.5rem;
    border-radius: 6px;
    border: 1px solid var(--color-dark-brand);
    background: var(--color-extra-dark);
    color: var(--color-white);
    min-width: 160px;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
}

.mapping-select:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.rating-scale-select {
    margin-top: 4px;
}

.ignored-badge {
    background: var(--color-dark-brand);
    color: var(--color-grey);
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-size: 0.75rem;
    font-weight: normal;
}

/* Confirmation Screen Styling */
.confirmation-details {
    background: var(--color-dark);
    border: 1px solid var(--color-dark-brand);
    border-radius: 8px;
    padding: 1.5rem;
}

.confirmation-section {
    margin-bottom: 2rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid var(--color-dark-brand);
}

.confirmation-section:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
}

.confirmation-section h3 {
    color: var(--color-white);
    font-size: 1.1rem;
    margin-bottom: 1.25rem;
}

.detail-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    padding: 0.5rem 0;
}

.detail-label {
    color: var(--color-grey);
    font-size: 0.9rem;
}

.detail-value {
    color: var(--color-white);
    font-weight: 500;
}

.capacity-stats {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
    margin-top: 1rem;
}

.stat-box {
    background: var(--color-extra-dark);
    border: 1px solid var(--color-dark-brand);
    border-radius: 6px;
    padding: 1.25rem;
    text-align: center;
}

.stat-number {
    font-size: 1.5rem;
    color: var(--color-white);
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.stat-label {
    color: var(--color-grey);
    font-size: 0.9rem;
}

.capacity-warning {
    background: var(--color-dark-brand-opacity);
    border: 1px solid var(--color-red);
    border-radius: 6px;
    padding: 1.25rem;
    margin-top: 1.5rem;
}

.capacity-warning p {
    color: var(--color-red);
    margin: 0.5rem 0;
}

/* Action Buttons */
.wizard-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px solid var(--color-dark-brand);
}

.primary-button, .secondary-button {
    padding: 0.75rem 1.5rem;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
}

.primary-button {
    background: var(--color-brand);
    color: var(--color-white-pure);
    border: none;
}

.primary-button:hover:not(:disabled) {
    background: var(--color-brand-complement);
}

.secondary-button {
    background: transparent;
    color: var(--color-white);
    border: 1px solid var(--color-dark-brand);
}

.secondary-button:hover:not(:disabled) {
    background: var(--color-dark-brand-opacity);
}

.primary-button:disabled, .secondary-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

/* Error Message Styling */
.error-message {
    background: var(--color-dark-brand-opacity);
    border: 1px solid var(--color-red);
    border-radius: 6px;
    padding: 1rem;
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.error-content {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.error-icon {
    color: var(--color-red);
    font-size: 1.25rem;
}

.error-close {
    margin-left: auto;
    background: none;
    border: none;
    color: var(--color-grey);
    cursor: pointer;
    padding: 0.25rem;
}

.retry-button {
    align-self: flex-start;
    background: var(--color-dark);
    border: 1px solid var(--color-red);
    color: var(--color-red);
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.retry-button:hover {
    background: var(--color-red);
    color: var(--color-white);
} 