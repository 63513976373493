.modals-base-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(1px);
    background: rgba(0, 0, 0, 0.213);
    z-index: 1000;
}

.modals-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Optional: dim background further */
}

.modals-content {
    position: relative;
    background-color: white;
    box-sizing: border-box;
    border-radius: 24px;
    padding: 40px 60px;
    width: 100%;
    max-width: 800px;
    margin: 0 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1001;
    color: var(--color-dark);
}

.modals-content-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.modals-content-header-close {
    position: absolute;
    top: 32px;
    right: 32px;
    width: 36px;
    min-width: 36px;
    height: 36px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.modals-content-header-close:hover {
    background-color: rgb(232, 232, 232);
}

.modals-content-header-close svg {
    font-size: 28px;
    color: var(--color-grey);
}

.modals-content-header h2 {
    margin: 0;
    font-size: 1.5rem;
}

.modals-content-subtitle {
    font-size: 1rem;
    color: var(--color-grey);
    margin-top: 0.5rem;
}

.modals-content-spacer {
    height: 1.5rem;
}

.image-upload-preview {
    text-align: center;
    margin: 20px;
}

.error-message {
    color: red;
    margin: 10px 0;
}

.preview-container {
    position: relative;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}
  
.preview-image {
    display: block;
    object-position: center;
    object-fit: cover;
    background-color: transparent;
}

.modals-org-settings-interactive-form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 2rem;
}

.modals-org-settings-button {
    color: var(--color-brand);
    text-decoration: underline;
    padding: 0.5rem 2rem;
    cursor: pointer;
}

.modals-org-settings-save-cancel {
    display: flex;
    align-items: stretch;
    gap: 1rem;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;
}

.modals-org-settings-save-cancel span {
    background-color: var(--color-brand);
    color: var(--color-white-pure);
    padding: 0.5rem;
    width: 120px;
    text-align: center;
    cursor: pointer;
    border-radius: 0.5rem;
}

.modals-org-settings-save-cancel span:last-of-type {
    background-color: var(--color-grey);
}