.dashboard-splash-container {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 40vh;
}

.dashboard {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.dashboard-main {
    flex-grow: 1;
}