.stacked-bar-chart {
    width: 100%;
    padding: 0.5rem 0.5rem 1rem;
    position: relative;
    box-sizing: border-box;
}

.stacked-bar-container {
    width: 100%;
    height: 2rem;
    position: relative;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 0.1);
    margin-bottom: 1rem;
}

.stacked-bar-segment {
    height: 100%;
    position: absolute;
    transition: all 0.3s ease;
    min-width: 3rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.segment-label {
    color: rgba(255, 255, 255, 0.9);
    font-size: 0.75rem;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
    padding: 0 0.5rem;
}

.stacked-bar-segment:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.stacked-bar-segment:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.stacked-bar-legend {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0 0.5rem;
}

.legend-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.875rem;
    color: rgba(255, 255, 255, 0.9);
}

.legend-left {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.legend-color-box {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 2px;
}

.legend-key {
    text-transform: capitalize;
}

.legend-right {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.legend-percentage {
    font-weight: 600;
}

.legend-value {
    color: rgba(255, 255, 255, 0.7);
}

/* Hover effects */
.stacked-bar-segment:hover {
    filter: brightness(1.1);
}
