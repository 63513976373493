.auth-sidebar {
    width: 240px;
    height: 100vh;
    background-color: var(--color-dark);
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    flex-direction: column;
    transition: width 0.3s ease;
    position: sticky;
    top: 0;
    left: 0;
    overflow-x: hidden; /* Prevent horizontal scroll during collapse animation */
}

.auth-sidebar.collapsed {
    width: 76px;
}

.auth-sidebar-header {
    flex-shrink: 0;
    padding: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    background-color: var(--color-dark); /* Ensure consistent background */
    position: relative; /* For z-index stacking */
    z-index: 2;
}

.auth-sidebar-logo {
    font-family: 'Lato', sans-serif !important;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.9rem;
    letter-spacing: 0.3rem;
    color: rgb(189, 189, 189);
    cursor: pointer;
}

.auth-sidebar-logo span {
    color: var(--color-brand);
}

.auth-sidebar-logo-image {
    width: 24px;
    height: 24px;
    object-fit: contain;
}

.auth-sidebar-org-name {
    font-size: 16px;
    font-weight: 600;
    color: var(--color-white);
}

.auth-sidebar-org-initial {
    width: 36px;
    height: 36px;
    min-width: 36px;
    background-color: var(--color-brand);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-white);
    font-weight: 600;
    font-size: 16px;
}

.auth-sidebar-nav {
    flex: 1;
    padding: 20px 0;
    overflow-y: auto;
    /* Custom scrollbar styling */
    scrollbar-width: thin;
    scrollbar-color: var(--color-dark-brand) transparent;
}

.auth-sidebar-new-item {
    font-size: 10px !important;
    background-color: var(--color-brand-opacity);
    color: var(--color-brand);
    padding: 4px 6px;
    border-radius: 4px;
    font-weight: 600;
    text-transform: uppercase;
}

/* Webkit scrollbar styling */
.auth-sidebar-nav::-webkit-scrollbar {
    width: 4px;
}

.auth-sidebar-nav::-webkit-scrollbar-track {
    background: transparent;
}

.auth-sidebar-nav::-webkit-scrollbar-thumb {
    background-color: var(--color-dark-brand);
    border-radius: 2px;
}

.auth-sidebar-nav::-webkit-scrollbar-thumb:hover {
    background-color: var(--color-grey);
}

.auth-sidebar-nav-item {
    display: flex;
    align-items: center;
    padding: 12px 20px;
    color: var(--color-grey);
    cursor: pointer;
    transition: all 0.2s ease;
    position: relative;
}

.auth-sidebar-nav-item:hover {
    color: var(--color-white);
    background-color: rgba(255, 255, 255, 0.1);
}

.auth-sidebar-nav-item.active {
    color: var(--color-white);
    background-color: #309c8320;;
}

.auth-sidebar-nav-icon {
    font-size: 20px;
    min-width: 20px;
}

.auth-sidebar-nav-item span {
    margin-left: 12px;
    font-size: 14px;
}

.auth-sidebar-tooltip {
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    background-color: var(--color-extra-dark);
    color: var(--color-white);
    padding: 8px 12px;
    border-radius: 4px;
    font-size: 14px;
    white-space: nowrap;
    margin-left: 8px;
    z-index: 1000;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.auth-sidebar-tooltip::before {
    content: '';
    position: absolute;
    left: -4px;
    top: 50%;
    transform: translateY(-50%);
    border-style: solid;
    border-width: 4px 4px 4px 0;
    border-color: transparent var(--color-extra-dark) transparent transparent;
}

.auth-sidebar-footer {
    flex-shrink: 0;
    padding: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: var(--color-dark); /* Ensure consistent background */
    position: relative; /* For z-index stacking */
    z-index: 2;
}

.auth-sidebar-collapse-button {
    position: absolute;
    right: -17.5px;
    top: 64px;
    width: 16px;
    height: 32px;
    background-color: var(--color-dark);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 0 4px 4px 0;
    border-left: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: var(--color-grey);
    transition: all 0.2s ease;
    z-index: 10;
}

.auth-sidebar-collapse-button:hover {
    color: var(--color-white);
    background-color: rgba(255, 255, 255, 0.1);
}

.auth-sidebar-collapse-icon {
    font-size: 12px;
    transition: transform 0.3s ease;
}

.auth-sidebar-collapse-icon.reversed {
    transform: rotate(180deg);
}

.auth-sidebar-error {
    background-color: var(--color-dark-brand);
}

.auth-sidebar-error-message {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-red);
    text-align: center;
    padding: 24px;
}

/* Mobile styles - to be implemented later */
/*
@media (max-width: 768px) {
    .auth-sidebar {
        transform: translateX(-100%);
        transition: transform 0.3s ease;
    }

    .auth-sidebar.mobile-visible {
        transform: translateX(0);
    }

    .auth-sidebar-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 999;
        display: none;
    }

    .auth-sidebar-overlay.visible {
        display: block;
    }

    .auth-sidebar-mobile-toggle {
        position: fixed;
        top: 16px;
        left: 16px;
        width: 40px;
        height: 40px;
        border-radius: 8px;
        background-color: var(--color-dark-brand);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 998;
    }
}
*/ 

.auth-sidebar.collapsed .auth-sidebar-nav-item,
.auth-sidebar.collapsed .auth-sidebar-logout {
    justify-content: center;
    padding: 12px 0;
} 

.auth-sidebar-org {
    display: flex;
    align-items: center;
    gap: 12px;
}

.auth-sidebar-logout {
    font-size: 12px;
    color: var(--color-grey);
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
}

.auth-sidebar-logout .auth-sidebar-nav-icon {
    font-size: 20px;
    min-width: 20px;
}

.auth-sidebar-logout:hover {
    color: var(--color-white);
    text-decoration: underline;
} 