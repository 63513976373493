.header {
    width: 100%;
    box-sizing: border-box;
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: var(--header-height);
    position: relative;
}

.header-logo {
    font-family: 'Lato', sans-serif !important;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.9rem;
    letter-spacing: 0.3rem;
    color: rgb(189, 189, 189);
    cursor: pointer;
}

.header-space-control {
    flex-basis: 33%;
}

.header-logo span {
    color: var(--color-brand);
}

.header-logo span:hover {
    text-decoration: none !important;
}

.header-auth-options {
    display: flex;
    align-items: center;
    gap: 20px;
}

.header-align-right {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.header-desktop {
    display: flex;
}

.header-align-center {
    justify-content: center;
    position: relative;
}

.header-align-left {
    justify-content: flex-start;
    display: flex;
}

.header-auth-options span {
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
}

.header-option-unauthed-names {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
}

.header-option-name {
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;
    padding: 8px;
    display: flex;
    align-items: center;
    gap: 6px;
}

.header-option-name:hover {
    color: var(--color-brand);
}

.header-option-name svg {
    padding-top: 2px;
}

.header-auth-options span:hover {
    text-decoration: underline;
}

.header-auth-options span.header-auth-option-login {
    background-color: var(--color-brand);
    color: var(--color-white);
    padding: 0.6rem 1.5rem;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.1s;
    font-weight: 600;
    min-width: 48px;
}

.header-auth-options span.header-auth-option-login:hover {
    text-decoration: none;
    box-shadow: rgba(154, 154, 154, 0.25) 0px 6px 12px -2px, rgba(249, 249, 249, 0.3) 0px 3px 7px -3px;
}

.header-org-pill {
    background-color: #4e4e4e;
    color: var(--color-white);
    padding: 4px 16px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 400;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.header-org-pill-clickable {
    cursor: pointer;
}

.header-org-caret {
    font-size: 14px;
}

.header-org-dropdown {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--color-white);
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    z-index: 1000;
    min-width: 200px;
    margin-top: 8px;
}

.header-org-dropdown-item {
    padding: 12px 16px;
    color: var(--color-dark);
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    transition: background-color ease 0.2s;
}

.header-org-dropdown-item:hover {
    background-color: rgb(202, 202, 202);
}

.header-mobile-outer {
    display: none;
    position: relative;
    width: 100%;
}

.header-mobile {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.header-mobile-menu-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.header-mobile-menu-icon svg {
    font-size: 1.5rem;
}

.header-mobile-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 100;
    width: 100%;
    box-sizing: border-box;
    flex-direction: column;
    background-color: var(--color-extra-dark);
}

.header-mobile-menu-item {
    padding: 12px;
    font-size: 1rem;
}

@media screen and (max-width: 1000px) {
    .header-desktop {
        display: none;
    }

    .header-mobile-outer {
        display: block;
    }

    .header-mobile-menu {
        display: flex;
    }
}

@media screen and (max-width: 768px) {
    .header-auth-options span {
        font-size: 14px;
    }    
}

.resources-dropdown {
    position: relative;
    padding-bottom: 20px;
    margin-bottom: -20px;
}

.resources-menu {
    position: absolute;
    top: calc(100% - 10px);
    left: 0;
    background-color: var(--color-white);
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    min-width: 300px;
    padding: 12px;
    margin-top: 8px;
    z-index: 1000;
    opacity: 1;
    transition: opacity 0.15s ease;
    transition-delay: 0.1s;
}

.resources-menu.hiding {
    opacity: 0;
    pointer-events: none;
}

.resources-section {
    margin-bottom: 8px;
}

.resources-section-header {
    color: var(--color-grey);
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 8px 12px;
    letter-spacing: 0.5px;
}

.resources-item {
    display: flex;
    align-items: center;
    padding: 12px;
    cursor: pointer;
    margin: 2px 0;
    border-radius: 6px;
}

.resources-item:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.resources-item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    min-width: 32px;
    height: 32px;
    background-color: var(--color-brand);
    border-radius: 6px;
    margin-right: 12px;
}

.resources-item-icon svg {
    color: white;
    font-size: 16px;
}

.resources-item-content {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.resources-item-name {
    font-weight: 600;
    font-size: 14px;
    color: var(--color-dark);
}

.resources-item-description {
    font-size: 12px;
    color: var(--color-grey);
}