/* Base Styles */
:root {
    /* Brand Color Variations (based on App.css --color-brand: #309C83) */
    --color-brand-rgb: 48, 156, 131; /* RGB values of the teal brand color */
    --color-brand-dark: #267A67; /* Darker shade of teal */
    
    /* Dark Theme Colors */
    --landing-background: var(--color-extra-dark, #0A0A0A);
    --landing-card-background: var(--color-dark, #191919);
    --landing-border-color: rgba(255, 255, 255, 0.1);
    --landing-text: var(--color-white, #D6D6D6); /* Using App.css text color */
    --landing-text-secondary: var(--color-light-grey, #bfbfbf);
    
    /* Effects */
    --landing-gradient: linear-gradient(135deg, #309C83 0%, #267A67 100%); /* Using actual color values instead of var() for gradient */
    --landing-glow: 0 0 20px rgba(var(--color-brand-rgb), 0.2);
}

.landing-container {
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    font-size: 16px;
    line-height: 1.6;
    color: var(--landing-text);
    background: var(--landing-background);
}

/* Hero Section */
.landing-hero {
    padding: 120px 20px 80px;
    padding-top: 60px;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 60px;
    position: relative;
}

.landing-hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    height: 100%;
    background: radial-gradient(circle at top, rgba(var(--color-brand-rgb), 0.15) 0%, transparent 70%);
    pointer-events: none;
    z-index: 0;
}

.landing-hero-content {
    max-width: 1000px;
    width: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.landing-hero h1 {
    font-size: 3.5rem;
    line-height: 1.2;
    margin-bottom: 24px;
    margin-top: 0;
    font-weight: 700;
    max-width: 600px;
    text-align: center;
}

.landing-hero h2 {
    font-size: 1.25rem;
    line-height: 1.6;
    color: var(--landing-text-secondary);
    font-weight: 400;
    margin-bottom: 32px;
    margin-top: 0;
    max-width: 700px;
    text-align: center;
}

.landing-hero-cta {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.landing-primary-button {
    background: var(--landing-gradient);
    color: white;
    border: none;
    padding: 18px 36px;
    border-radius: 40px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    box-shadow: var(--landing-glow);
    display: inline-flex;
    align-items: center;
    gap: 12px;
}

.landing-primary-button:hover {
    box-shadow: 0 8px 30px rgba(var(--color-brand-rgb), 0.3);
}

.landing-platform-support {
    margin-top: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
}

.landing-platform-support span {
    display: block;
    font-size: 0.875rem;
    color: var(--landing-text-secondary);
    margin-bottom: 42px;
    text-align: center;
}

.landing-platform-icons {
    display: flex;
    gap: 60px;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
}

.landing-platform-icons img {
    height: 40px;
    width: auto;
}

.landing-tactical-explanation {
    padding-top: 80px;
    padding-bottom: 80px;
}

.landing-tactical-explanation-inner {
    width: 100%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 60px;
}

.landing-tactical-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 24px;
    text-align: left;
}

.landing-tactical-left span {
    display: flex;
    align-items: center;
    gap: 16px;
    font-size: 16px;
    color: var(--landing-text);
}

.landing-tactical-left .icon-container {
    width: 32px;
    height: 32px;
    min-width: 32px;
    background-color: var(--color-brand-opacity);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.landing-tactical-left .icon-container svg {
    color: var(--color-brand);
}

.landing-tactical-left svg {
    flex-shrink: 0;
}

.landing-tactical-right {
    flex: 1;
    background: linear-gradient(145deg, rgba(25, 25, 25, 0.9) 0%, rgba(35, 35, 35, 0.6) 100%);
    border-radius: 16px;
    padding: 32px;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.landing-tactical-right div:first-child {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 16px;
    color: var(--landing-text);
}

.landing-tactical-right div:last-child {
    font-size: 16px;
    line-height: 1.6;
    color: var(--landing-text-secondary);
}

/* Specific style for the Typeform logo which needs inversion */
.landing-platform-icons img[alt="Typeform"] {
    filter: brightness(0) invert(1); /* Makes black logo white */
}

.landing-hero-visual {
    max-width: 1400px;
    width: 100%;
    position: relative;
    z-index: 1;
    aspect-ratio: 1400 / 785;
}

.landing-hero-image {
    width: 100%;
    height: 100%;
    max-height: 785px;
    border-radius: 0;
    object-fit: contain;
}

/* Features Grid */
.landing-features-highlight {
    padding: 80px 20px;
    background: var(--landing-card-background);
    border-top: 1px solid var(--landing-border-color);
    border-bottom: 1px solid var(--landing-border-color);
}

.landing-features-grid {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
}

.landing-feature-card {
    padding: 32px;
    background: var(--landing-background);
    border: 1px solid var(--landing-border-color);
    border-radius: 12px;
    text-align: center;
    transition: all 0.2s ease;
}

.landing-feature-card:hover {
    transform: translateY(-4px);
    border-color: var(--color-brand);
    box-shadow: var(--landing-glow);
}

.landing-feature-icon {
    font-size: 2rem;
    color: var(--color-brand);
    margin-bottom: 16px;
}

.landing-feature-card h3 {
    font-size: 1.25rem;
    margin-bottom: 8px;
    color: var(--landing-text);
}

.landing-feature-card p {
    font-size: 1rem;
    line-height: 1.5;
    color: var(--landing-text-secondary);
}

/* Modern Teams Section */
.landing-modern-teams {
    padding: 120px 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.landing-modern-teams h2 {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 60px;
}

.landing-teams-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
}

.landing-team-feature {
    text-align: center;
}

.landing-team-feature img {
    width: 100%;
    height: auto;
    margin-bottom: 24px;
    border-radius: 12px;
    border: 1px solid var(--landing-border-color);
}

.landing-team-feature h3 {
    font-size: 1.25rem;
    margin-bottom: 12px;
    color: var(--landing-text);
}

.landing-team-feature p {
    color: var(--landing-text-secondary);
    font-size: 1rem;
    line-height: 1.5;
}

/* Analysis Section */
.landing-analysis {
    padding: 120px 20px;
    background: var(--landing-card-background);
    border-top: 1px solid var(--landing-border-color);
    border-bottom: 1px solid var(--landing-border-color);
}

.landing-analysis-header {
    text-align: center;
    max-width: 800px;
    margin: 0 auto 60px;
}

.landing-analysis-header h2 {
    font-size: 2.5rem;
    margin-bottom: 16px;
}

.landing-analysis-header p {
    color: var(--landing-text-secondary);
    font-size: 1.25rem;
}

.landing-analysis-demo {
    max-width: 1000px;
    margin: 0 auto 80px;
}

.landing-analysis-image {
    width: 100%;
    height: auto;
    border-radius: 12px;
    box-shadow: var(--landing-glow);
}

.landing-analysis-features {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    max-width: 1200px;
    margin: 0 auto;
}

.landing-analysis-feature {
    text-align: center;
    padding: 32px;
    background: var(--landing-background);
    border-radius: 12px;
    border: 1px solid var(--landing-border-color);
}

.landing-analysis-feature svg {
    color: var(--color-brand);
    font-size: 2rem;
    margin-bottom: 16px;
}

.landing-analysis-feature h3 {
    font-size: 1.25rem;
    margin-bottom: 12px;
    color: var(--landing-text);
}

.landing-analysis-feature p {
    color: var(--landing-text-secondary);
    font-size: 1rem;
    line-height: 1.5;
}

/* Collaboration Section */
.landing-collaboration {
    padding: 120px 20px;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
}

.landing-collaboration h2 {
    font-size: 2.5rem;
    margin-bottom: 60px;
}

.landing-collaboration-features {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    margin-bottom: 60px;
}

.landing-collab-feature {
    padding: 32px;
    background: var(--landing-card-background);
    border-radius: 12px;
    border: 1px solid var(--landing-border-color);
}

.landing-collab-feature h3 {
    font-size: 1.25rem;
    margin-bottom: 12px;
    color: var(--landing-text);
}

.landing-collab-feature p {
    color: var(--landing-text-secondary);
    font-size: 1rem;
    line-height: 1.5;
}

.landing-collab-image {
    width: 100%;
    max-width: 800px;
    height: auto;
    border-radius: 12px;
    box-shadow: var(--landing-glow);
}

/* Success Stories Section */
.landing-success {
    padding: 120px 20px;
    background: var(--landing-card-background);
    border-top: 1px solid var(--landing-border-color);
    border-bottom: 1px solid var(--landing-border-color);
}

.landing-success h2 {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 60px;
}

.landing-success-grid {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
}

.landing-success-story {
    text-align: center;
    padding: 32px;
    background: var(--landing-background);
    border-radius: 12px;
    border: 1px solid var(--landing-border-color);
}

.landing-success-story img {
    height: 40px;
    width: auto;
    margin-bottom: 24px;
    opacity: 0.8;
}

.landing-success-story p {
    color: var(--landing-text);
    font-size: 1.125rem;
    line-height: 1.5;
    font-style: italic;
}

/* Final CTA Section */
.landing-final-cta {
    padding: 120px 20px;
    text-align: center;
    background: var(--landing-background);
    position: relative;
}

.landing-final-cta::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    height: 100%;
    background: radial-gradient(circle at center, rgba(var(--color-brand-rgb), 0.15) 0%, transparent 70%);
    pointer-events: none;
}

.landing-final-cta h2 {
    font-size: 2.5rem;
    max-width: 700px;
    margin: 0 auto 32px;
}

.landing-final-cta .landing-primary-button {
    margin: 0 auto;
}

/* Video Modal */
.landing-video-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.landing-video-modal-content {
    width: 90%;
    max-width: 1000px;
    height: 80vh;
    position: relative;
    background: black;
    border-radius: 12px;
    overflow: hidden;
}

.landing-video-modal-close {
    position: absolute;
    top: 20px;
    right: 20px;
    background: rgba(0, 0, 0, 0.5);
    border: none;
    color: white;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
}

/* Back to Top Button */
.landing-back-to-top {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 50px;
    height: 50px;
    background: var(--landing-gradient);
    color: white;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
    z-index: 100;
    box-shadow: var(--landing-glow);
}

.landing-back-to-top:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 30px rgba(var(--color-brand-rgb), 0.3);
}

/* How it Works Section */
.landing-how-it-works {
    padding: 80px 20px;
    text-align: center;
    max-width: 1200px;
    margin: 0 auto;
}

.landing-how-it-works h2 {
    font-size: 2.2rem;
    margin-bottom: 0px;
    font-weight: 600;
}

.landing-steps {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    width: 100%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

.landing-step-image {
    width: 100%;
    max-width: 400px;
    overflow: hidden;
    border-radius: 24px;
    box-sizing: border-box;
    aspect-ratio: 1/1;
}

.landing-step-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.landing-step {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.landing-step h3 {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0px;
    color: var(--landing-text);
    text-align: left;
    width: 100%;
    max-width: 400px;
}

.landing-step p {
    color: var(--landing-text-secondary);
    font-size: 14px;
    line-height: 1.5;
    text-align: left;
    margin-top: 0;
    width: 100%;
    max-width: 400px;
}

.landing-how-it-works-cta-button {
    margin-top: 60px;
    width: 180px;
    background-color: var(--color-white);
    color: var(--color-dark);
    border-radius: 12px;
    padding: 8px 16px;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
}

.landing-how-it-works-screenshot {
    max-width: 800px;
    max-height: 400px;
    border-radius: 16px;
    overflow: hidden;
    border: 2px solid var(--color-grey);
}

.landing-how-it-works-screenshot img {
    height: 100%;
    width: 100%;
}

.landing-section-plate {
    background-color: rgba(66, 66, 66, 0.261);
    border-radius: 20px;
    margin-top: 120px;
    position: relative;
    overflow: hidden;
}

.landing-section-plate::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.15;
    filter: saturate(0);
    z-index: 0;
}

.landing-import-providers.landing-section-plate::before {
    background-image: url('https://storage.googleapis.com/tetheros-public/tetherform_landing/kevinafischer_a_highly_complex_pattern_of_lines_and_diagrammati_90d02447-ebbc-43e1-80b4-afaee4c47fa4.webp');
}

.landing-design-your-own.landing-section-plate::before {
    background-image: url('https://storage.googleapis.com/tetheros-public/tetherform_landing/tetherform-background-2.webp');
    transform: rotate(180deg);
}

.landing-section-insights-and-recommendations.landing-section-plate{
    background-color: transparent;
}

.landing-section-plate > * {
    position: relative;
    z-index: 1;
}

.landing-insights-and-recommendations {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px;
    padding-bottom: 0;
    box-sizing: border-box;
    background-color: rgba(110, 110, 110, 0.152);
    flex-wrap: wrap;
    width: 100%;
    max-width: 1200px;
    text-align: left;
    border-radius: 20px;
    border: 1px solid rgba(128, 128, 128, 0.168);
}

.landing-insights-and-recommendations.form-builder-analytics-finding-item {
    flex-basis: 40%;
    margin-bottom: 20px;
}

/* Survey Data Section */
.landing-survey-data {
    padding: 80px 20px;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    gap: 80px;
}

.landing-survey-data-content {
    flex: 1;
    max-width: 600px;
}

.landing-survey-data h2 {
    font-size: 2.5rem;
    margin-bottom: 48px;
    color: var(--landing-text);
    line-height: 1.2;
}

.landing-benefits {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.landing-benefit {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 24px;
    background: var(--landing-card-background);
    border-radius: 12px;
    border: 1px solid var(--landing-border-color);
}

.landing-benefit h3 {
    font-size: 1.25rem;
    color: var(--landing-text);
    font-weight: 600;
}

.landing-benefit p {
    color: var(--landing-text-secondary);
    font-size: 1rem;
    line-height: 1.5;
}

.landing-survey-data-visual {
    flex: 1;
    max-width: 600px;
    display: flex;
    align-items: flex-start;
}

.landing-survey-data-visual img {
    width: 100%;
    height: auto;
    border-radius: 12px;
    border: 1px solid var(--landing-border-color);
}

/* Efficiency Section */
.landing-efficiency {
    padding: 80px 20px;
    background: var(--landing-card-background);
    border-top: 1px solid var(--landing-border-color);
    border-bottom: 1px solid var(--landing-border-color);
    text-align: center;
}

.landing-efficiency h2 {
    font-size: 2.5rem;
    margin-bottom: 60px;
    background: var(--landing-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.landing-efficiency-features {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
}

.landing-efficiency-feature {
    padding: 32px;
    background: var(--landing-background);
    border-radius: 12px;
    border: 1px solid var(--landing-border-color);
}

.landing-efficiency-feature h3 {
    font-size: 1.25rem;
    margin-bottom: 12px;
    color: var(--landing-text);
}

.landing-efficiency-feature p {
    color: var(--landing-text-secondary);
    font-size: 1rem;
    line-height: 1.5;
}

/* Survey Types Section */
.landing-survey-types {
    padding: 80px 20px;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
}

.landing-survey-types h2 {
    font-size: 2.5rem;
    margin-bottom: 60px;
    background: var(--landing-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.landing-survey-types-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    margin-bottom: 80px;
}

.landing-survey-type {
    padding: 32px;
    background: var(--landing-card-background);
    border-radius: 12px;
    border: 1px solid var(--landing-border-color);
    text-align: left;
}

.landing-survey-type h3 {
    font-size: 1.25rem;
    margin-bottom: 12px;
    color: var(--landing-text);
}

.landing-survey-type p {
    color: var(--landing-text-secondary);
    font-size: 1rem;
    line-height: 1.5;
}

/* Responsive Design */
@media screen and (max-width: 1024px) {
    .landing-hero {
        padding: 80px 20px;
        align-items: center;
        text-align: center;
    }

    .landing-section-plate {
        margin-top: 60px;
    }

    .landing-hero h1 {
        font-size: 2.75rem;
    }

    .landing-platform-support span {
        margin-bottom: 36px;
    }

    .landing-platform-support {
        justify-content: center;
        align-items: center;
    }

    .landing-steps {
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
    }

    .landing-insights-and-recommendations {
        flex-direction: column;
    }
}

@media screen and (max-width: 768px) {
    .landing-hero h1 {
        font-size: 2.25rem;
    }

    .landing-hero h2 {
        font-size: 1.125rem;
    }

    .landing-platform-support span {
        margin-bottom: 20px;
    }

    .landing-platform-icons {
        gap: 24px;
        padding: 0 20px;
    }

    .landing-platform-icons img {
        height: 24px;
    }

    .landing-steps {
        grid-template-columns: 1fr;
        max-width: 400px;
        margin: 0 auto;
    }

    .landing-how-it-works h2 {
        font-size: 2rem;
    }

    .landing-tactical-explanation-inner {
        flex-direction: column-reverse;
        gap: 40px;
    }
    
    .landing-tactical-left,
    .landing-tactical-right {
        width: 100%;
        box-sizing: border-box;
    }
}

@media screen and (max-width: 480px) {
    .landing-hero h1 {
        font-size: 1.75rem;
    }

    .landing-hero-visual {
        max-width: 100%;
    }

    .landing-primary-button {
        width: 100%;
        font-size: 14px;
    }

    .landing-how-it-works h2 {
        font-size: 1.75rem;
    }
}

.landing-hero h1,
.landing-how-it-works h2 {
    color: var(--color-white);
}

/* Video Modal */
.landing-video-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.landing-video-modal-content {
    width: 90%;
    max-width: 1000px;
    height: 80vh;
    position: relative;
    background: black;
    border-radius: 12px;
    overflow: hidden;
}

.landing-video-modal-close {
    position: absolute;
    top: 20px;
    right: 20px;
    background: rgba(0, 0, 0, 0.5);
    border: none;
    color: white;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
}
