.organization-imports {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.org-imports-main {
    flex-grow: 1;
    padding: 20px;
    padding-top: 0;
}

.org-imports-no-license {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin-top: 4rem;
}

.org-imports-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 24px;
    width: 100%;
    margin-top: 24px;
}

.new-import-button {
    padding: 8px 18px;
    background-color: var(--color-brand);
    color: var(--color-white);
    border: none;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 13px;
    font-weight: 600;
    transition: all 0.2s ease;
}

.new-import-button:hover {
    box-shadow: 0 4px 12px rgba(var(--color-brand-rgb), 0.3);
    background-color: #288972;
}

.new-import-button .org-imports-icon {
    font-size: 14px;
}

.org-imports-item {
    padding: 14px;
    background-color: var(--color-dark);
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.org-imports-item:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    border-color: rgba(255, 255, 255, 0.2);
}

.org-imports-item-header {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.org-imports-item-title-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.import-status {
    padding: 3px 12px;
    border-radius: 4px;
    color: var(--color-white);
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.3px;
    display: inline-flex;
    align-items: center;
    align-self: flex-start;
}

.import-title {
    font-size: 14px;
    font-weight: 600;
    margin: 12px 0 8px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.import-subtitle {
    font-size: 12px;
    color: var(--color-grey);
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.org-imports-item-stats {
    display: flex;
    padding: 8px 0 0;
}

.org-imports-response-badge {
    font-size: 10px;
    font-weight: 500;
    color: var(--color-white);
    background-color: rgba(255, 255, 255, 0.1);
    padding: 3px 12px;
    border-radius: 20px;
}

.org-imports-icon {
    font-size: 16px;
}

.org-imports-header {
    margin-bottom: 20px;
}

.org-imports-header-titles {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--color-grey);
    padding-bottom: 0.9rem;
}

.org-imports-header-titles-page-title {
    display: flex;
    align-items: center;
    gap: 4px;
}

.org-imports-header-subtitle{
    font-size: 13px;
    color: var(--color-grey);
    margin-top: 8px;
}

.org-imports-header-titles-page-title h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 700;
    color: var(--color-white);
}

.org-imports-header-titles-page-title svg {
    font-size: 18px;
    color: var(--color-white);
}

.org-imports-filters {
    margin-top: 20px;
    display: flex;
    gap: 12px;
    align-items: center;
}

.org-imports-search {
    position: relative;
    flex: 1;
}

.org-imports-sort {
    position: relative;
}

.org-imports-sort-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 16px;
    background: none;
    border: none;
    border-radius: 4px;
    color: var(--color-white);
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease;
    width: 240px;
}

.sort-icon {
    font-size: 16px;
    color: var(--color-grey);
}

.org-imports-sort-menu {
    position: absolute;
    top: calc(100% + 4px);
    right: 0;
    background-color: var(--color-dark);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    width: 240px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}

.org-imports-sort-option {
    display: block;
    width: 100%;
    padding: 8px 16px;
    text-align: left;
    background: none;
    border: none;
    color: var(--color-grey);
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.org-imports-sort-option:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: var(--color-white);
}

.org-imports-sort-option.active {
    color: var(--color-white);
    background-color: rgba(255, 255, 255, 0.1);
}

.org-imports-empty-state {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    padding: 20px;
}

.org-imports-empty-state-content {
    max-width: 800px;
    text-align: center;
    animation: fadeIn 0.5s ease-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.org-imports-empty-state-content h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    background: linear-gradient(45deg, var(--color-brand), #4a90e2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.org-imports-empty-state-content p {
    font-size: 1.1rem;
    color: var(--color-grey);
    margin-bottom: 3rem;
}

.org-imports-empty-state-steps {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin: 3rem 0;
}

.org-imports-empty-step {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    text-align: left;
    padding: 1.5rem;
    background-color: var(--color-extra-dark);
    border-radius: 12px;
    transition: transform 0.2s ease;
}

.org-imports-empty-step:hover {
    transform: translateY(-5px);
}

.org-imports-empty-step-number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    min-width: 32px;
    height: 32px;
    background-color: var(--color-brand);
    color: var(--color-white);
    border-radius: 50%;
    font-weight: bold;
    font-size: 1.1rem;
}

.org-imports-empty-step-content h3 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
    color: var(--color-white);
}

.org-imports-empty-step-content p {
    margin: 0;
    font-size: 0.9rem;
    color: var(--color-grey);
}

.org-imports-empty-state-button {
    display: inline-flex;
    align-items: center;
    gap: 0.8rem;
    padding: 1rem 2rem;
    font-size: 1.1rem;
    font-weight: 600;
    color: var(--color-white);
    background-color: var(--color-brand);
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-top: 2rem;
}

.org-imports-empty-state-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(var(--color-brand-rgb), 0.3);
}

.org-imports-empty-state-button .org-imports-icon {
    font-size: 1.2rem;
}

.org-imports-new-button {
    padding: 8px 18px;
    background-color: var(--color-brand);
    color: var(--color-white);
    border: none;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 13px;
    font-weight: 600;
    transition: all 0.2s ease;
}

.org-imports-new-button:hover {
    box-shadow: 0 4px 12px rgba(var(--color-brand-rgb), 0.3);
    background-color: #288972;
}

.org-imports-new-button .org-imports-icon {
    font-size: 14px;
}

.org-imports-updated-date {
    position: absolute;
    top: 14px;
    right: 14px;
    font-size: 12px;
    color: var(--color-grey);
}

.import-error-message {
    margin-top: 12px;
    padding: 8px 12px;
    background-color: var(--color-error-light);
    color: var(--color-error);
    border-radius: 4px;
    font-size: 12px;
}
.search-icon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--color-grey);
}

.org-imports-search-input {
    width: 300px;
    padding: 10px 10px 10px 35px;
    border: 1px solid var(--color-grey);
    border-radius: 4px;
    font-size: 14px;
    background-color: var(--color-dark);
    color: var(--color-white);
    box-sizing: border-box;
}

@media screen and (max-width: 800px) {
    .org-imports-empty-state-steps {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .org-imports-empty-state-content h1 {
        font-size: 2rem;
    }

    .org-imports-empty-step {
        padding: 1rem;
    }

    .org-imports-empty-state-button {
        width: 100%;
        justify-content: center;
    }

    .org-imports-filters {
        flex-direction: column;
        align-items: stretch;
    }

    .org-imports-search {
        width: 100%;
    }

    .org-imports-item {
        aspect-ratio: auto;
        min-height: 250px;
    }

    .import-title {
        font-size: 14px;
        height: auto;
        -webkit-line-clamp: 1;
    }

    .org-imports-item-stats {
        padding: 12px 0;
        gap: 12px;
    }

    .org-imports-list {
        grid-template-columns: 1fr;
    }
}

.header-buttons {
    display: flex;
    gap: 12px;
    align-items: center;
}

.sample-import-button {
    padding: 8px 16px;
    background-color: var(--color-secondary);
    color: var(--color-text-light);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    transition: background-color 0.2s ease;
    display: flex;
    align-items: center;
    gap: 8px;
}

.sample-import-button:hover {
    background-color: var(--color-secondary-dark);
}
