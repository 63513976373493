.split-button {
    position: relative;
    display: inline-flex;
}

.split-button-primary,
.split-button-arrow {
    background-color: var(--color-brand);
    color: var(--color-white);
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
}

.split-button-primary {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.split-button-primary-disabled {
    background-color: grey;
    cursor: default;
}

.split-button-arrow {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    padding: 10px 8px;
}

.split-button-arrow.split-button-primary-disabled {
    cursor: pointer;
}

.split-button-dropdown {
    position: absolute;
    top: calc(100% + 4px);
    right: 0;
    left: 0;
    background-color: var(--color-white);
    border: 1px solid var(--color-border);
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 10;
}

.split-button-dropdown button {
    display: block;
    width: 100%;
    padding: 10px 15px;
    text-align: left;
    background: none;
    border: none;
    cursor: pointer;
    color: var(--color-dark);
}

.split-button-dropdown button:hover {
    background-color: rgb(209, 209, 209);
}