.org-logo {
    /* Make sure the parent container controls maximum width and height */
    max-width: 100%; /* Limit the child container's width to its parent's width */
    max-height: 100%; /* Limit the child container's height to its parent's height */
    display: flex;
    justify-content: center; /* Center child containers if they are smaller */
    align-items: center;
    overflow: hidden;
    position: relative;
}

.org-logo-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 12px;
    box-sizing: border-box;
    width: auto; /* Use auto for dynamic scaling */
    height: auto;
    max-width: 100%;
    max-height: 100%;
    position: relative;
}

.org-logo-holder img {
    width: auto; 
    height: auto; 
    max-width: 100%; /* Fit within the container's max width */
    max-height: 100%; /* Fit within the container's max height */
    object-fit: contain; /* Ensure the image maintains its aspect ratio */
    object-position: center;
}

.org-logo-holder span {
    font-weight: 700;
    text-transform: uppercase;
}
  