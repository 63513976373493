.form-response-view-container {
  padding: 20px;
  height: 100%;
  overflow-y: auto;
}

/* Webkit browsers (Chrome, Safari, newer versions of Edge) */
.form-response-view-container::-webkit-scrollbar {
  width: 8px;
}

.form-response-view-container::-webkit-scrollbar-track {
  background: transparent;
}

.form-response-view-container::-webkit-scrollbar-thumb {
  background-color: var(--color-grey);
  border-radius: 4px;
}

.form-response-view-container::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-light-grey);
}

/* Firefox */
.form-response-view-container {
  scrollbar-width: thin;
  scrollbar-color: var(--color-grey) transparent;
}

.form-response-view-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.form-response-view-download-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: var(--color-brand);
  color: var(--color-white);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s ease;
}

.form-response-view-download-button:hover {
  background-color: var(--color-brand-dark);
}

.form-response-view-grid {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: stretch;
  gap: 20px;
  margin-top: 20px;
}

.form-response-view-chart-average-score {
  background-color: var(--color-brand);
  color: var(--color-white);
  border-radius: 22px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  min-width: 36px;
  height: 36px;
  cursor: default;
}

.form-response-view-column {
  flex: 1;
  min-width: 400px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-response-view-chart-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2rem;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 2rem;
}

.form-response-view-chart-header h3 {
  font-size: 1.2rem;
  margin: 0;
  font-weight: 400;
  margin-bottom: 20px;
  flex-grow: 1;
}

.form-response-view-chart,
.form-response-view-text-answers {
  background-color: var(--color-extra-dark);
  border-radius: 8px;
  padding: 32px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-response-view-text-answers.form-response-view-text-answers-others {
  padding: 0;
  min-height: unset;
}

.form-response-view-text-answers-others .form-response-view-chart-header {
  margin-top: 12px;
  margin-bottom: 0;
}

.form-response-view-text-answers-others .form-response-view-chart-header h3 {
  font-size: 14px;
  font-weight: 500;
  color: var(--color-grey);
}

.form-response-view-text-answers {
  min-height: 240px;
}

.form-response-view-chart {
  display: flex;
  flex-direction: column;
}

.chart-container {
  flex-grow: 1;
  position: relative;
  margin-top: 2rem;
}

.form-response-view-text-answers h3,
.form-response-view-chart h3 {
    font-size: 1.2rem;
    margin: 0;
    font-weight: 400;
}

.form-response-view-text-answers-container {
  display: flex;
  flex-direction: column;
}

.form-response-view-text-answers-container p {
  margin: 0;
  font-size: 14px;
  line-height: 1.4;
  color: var(--color-light-grey);
  border-bottom: 1px solid var(--color-grey);
  padding: 0.5rem 0;
}

.form-response-view-text-answers-container p:last-of-type {
  border-bottom: none;
}

.form-response-view-message {
  text-align: center;
  font-size: 18px;
  color: var(--color-text-secondary);
  margin-top: 50px;
}

@media (max-width: 768px) {
  .form-response-view-grid {
    flex-direction: column;
  }

  .form-response-view-column {
    width: 100%;
    --cooo: #309c83cd;
    --ssss: rgba(255, 255, 255, 0.441);
  }
}

.form-response-view-controls {
    display: flex;
    align-items: center;
    gap: 1rem;
    min-width: 200px;
    justify-content: flex-end;
}

.form-response-view-controls svg {
    color: var(--color-grey);
    cursor: pointer;
    font-size: 1.1rem;
}

.form-response-view-controls svg.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.form-response-view-controls span {
    color: var(--color-grey);
    font-size: 0.9rem;
    min-width: 36px;
    text-align: center;
}
