.form-builder-sharing {
    padding: 20px;
    height: 100%;
    overflow-y: auto;
}

.qr-code-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}

.download-button {
    background-color: var(--color-links);
    color: white;
    font-weight: bold;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.form-builder-sharing-link-text-container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 0.5rem;
}

.form-builder-sharing-link-text {
    padding: 0.8rem 1rem;
    padding-right: 3rem;
    background-color: var(--color-extra-dark);
    border: 1px solid var(--color-grey);
    border-radius: 12px;
    font-size: 0.9rem;
    font-weight: 600;
    letter-spacing: 0.01rem;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.form-builder-sharing-link-copy {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding-right: 1rem;
    cursor: pointer;
}

.form-builder-sharing-link-copy svg {
    font-size: 1.2rem;
}

.qr-code-actions {
    display: flex;
    gap: 8px;
}

.qr-download-button {
    color: var(--color-white);
    border: 1px solid var(--color-grey);
    padding: 4px 12px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    transition: opacity 0.2s ease;
}

.qr-download-button:hover {
    opacity: 0.8;
}